import _ from 'lodash';
import i18n from 'i18next';
import { CampaignLimitation, Limitation } from 'core/limitation/Limitation';
import { rtbInventories } from 'containers/Limitations/LimitationSetting/rtbLimitationInventory';

const transformLimitationData = (op, data) => {
  if (!Array.isArray(data.value)) {
    return [
      {
        op: op,
        limits: data.value,
        isGroup: false,
        type: data.type
      }
    ];
  }
  const limits = [...data.value];
  const groupData = _.remove(limits, (value: any) => value.isGroup === true);
  let result = limits.length > 0 ? [{
    op: op,
    limits,
    isGroup: false,
    type: data.type
  }] : [];
  if (groupData.length > 0) {
    result.push({
      op: op,
      limits: groupData,
      isGroup: true,
      type: data.type
    });
  }
  return result;
};

export function toServerStructure (limitations, filterTA: {[key: string]: string[]} = {
  other: ['dealId'],
  include: ['age_min', 'age_max']
}) {

  const otherFilter = _.defaultTo(filterTA.other, []);
  const otherLimitations = _.filter(
    _.defaultTo(limitations.other, []),
    (limitation: Limitation) => !otherFilter.includes(limitation.type)
  );

  const includeFilter = _.defaultTo(filterTA.include, []);
  const includeLimitations = _.filter(
    _.defaultTo(limitations.include, []),
    (limitation: Limitation) => !includeFilter.includes(limitation.type)
  );

  let result = _.compact(_.flatten(_.concat(
    includeLimitations.map(value => {
      return transformLimitationData('inc', value);
    }),
    _.defaultTo(limitations.exclude, []).map(value => {
      return transformLimitationData('exc', value);
    }),
    _.defaultTo(limitations.preferred, []).map(value => {
      return transformLimitationData('Preferred', value);
    }),
    _.defaultTo(limitations.nonPreferred, []).map(value => {
      return transformLimitationData('NonPreferred', value);
    }),
    // dealId
    otherLimitations.map(value => {
      if (value.type === 'dealId') {
        return transformLimitationData('inc', value);
      } else {
        return transformLimitationData(value.op, value);
      }
    }))
  ));
  return result;
}

export function getLimitationContentLabel (type: string, limitation: { value: string | number, label: string }) {
  const i18nValue = limitation.value.toString().toLowerCase().replace(/-|\s/g, '_');
  const inventoryData: any = rtbInventories[type] ? rtbInventories[type] : {};
  const i18nPrefix = inventoryData.i18nPrefix;
  return i18nPrefix ? i18n.t<string>(`${i18nPrefix}.${i18nValue}`) : limitation.label;
}

export function wrapLimitationsFromServer (limitations, dealIds?: string[], ageMin?: number, ageMax?: number): CampaignLimitation {
  const limitationMap = {
    inc: {},
    exc: {},
    Preferred: {},
    NonPreferred: {},
    other: {}
  };
  let ageOptValue = 'preferred';
  limitations.forEach(limitation => {
    if (limitation.type === 'age') {
      ageOptValue = limitation.op === 'inc' ? 'include' : 'preferred';
      return;
    }
    const limits = _.get(limitation, 'limits', []);
    const isGroup = _.get(limitation, 'isGroup');
    const wrappedLimitation = {
      op: _.get(limitation, 'op'),
      value: Array.isArray(limits) ? limits.map(limits => ({
        label: _.get(limits, 'label'),
        value: _.get(limits, 'value'),
        isGroup
      })) : limits,
      type: _.get(limitation, 'type')
    };

    if (!(limitation.op in limitationMap)) {
      return;
    }

    if (limitation.type === 'dealId') {
      limitationMap['other'][limitation.type] = wrappedLimitation;
    } else if (limitation.type in limitationMap[limitation.op]) {
      limitationMap[limitation.op][limitation.type].value = _.concat(limitationMap[limitation.op][limitation.type].value, wrappedLimitation.value);
    } else {
      limitationMap[limitation.op][limitation.type] = wrappedLimitation;
    }
  });

  const wrappedLimitations: CampaignLimitation = {
    include: Object.values(limitationMap['inc']),
    exclude: Object.values(limitationMap['exc']),
    preferred: Object.values(limitationMap['Preferred']),
    nonPreferred: Object.values(limitationMap['NonPreferred']),
    other: Object.values(limitationMap['other'])
  };

  ageMin && wrappedLimitations[ageOptValue].push({
    op: ageOptValue === 'include' ? 'inc' : 'Preferred',
    value: ageMin,
    type: 'age_min'
  });

  ageMax && wrappedLimitations[ageOptValue].push({
    op: ageOptValue === 'include' ? 'inc' : 'Preferred',
    value: ageMax,
    type: 'age_max'
  });

  if (dealIds && dealIds.length > 0) {
    const dealIdTA = wrappedLimitations.other!.find(ta => ta.type === 'dealId');
    !dealIdTA && wrappedLimitations.other!.push({
      op: 'inc',
      value: dealIds ? dealIds.map(dealId => {
        return {
          label: dealId,
          value: dealId
        };
      }) : [],
      type: 'dealId'
    });
  }

  return wrappedLimitations;
}
