import _ from 'lodash';
import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

export interface InvalidTokenHandler {

  tokenInvalidated (): void;
}

export interface AuthInfoSource {

  invalidTokenHandler: InvalidTokenHandler | undefined;

  /**
   * Clear the local saved auth information.
   */
  clear (): void;

  /**
   * Get the local saved token.
   */
  getToken (): string;

  /**
   * Get the last active actor.
   */
  getActor (): number | null;

  /**
   * Update the local token.
   *
   * @param token the new token
   * @param expires the expires date in timestamp string
   */
  setToken (token: string, expires: number | string | null): void;

  /**
   * Update the active actor.
   *
   * @param actor the active actor
   */
  setActor (actor: number): void;
}

export class CookieAuthInfoSource implements AuthInfoSource {
  invalidTokenHandler: InvalidTokenHandler | undefined;

  writeOptions (expires: number | string | null = null): CookieSetOptions {
    const defaultOptions: CookieSetOptions = {
      path: '/'
    };
    if (expires) {
      return {
        ...defaultOptions,
        expires: new Date(expires)
      };
    } else {
      return defaultOptions;
    }
  }

  getToken (): string {
    return _.defaultTo(cookies.get('token'), '');
  }

  getActor (): number | null {
    return _.defaultTo(parseInt(cookies.get('actor'), 10), null);
  }

  setToken (token: string, expires: number | string | null) {
    cookies.set('token', token, this.writeOptions(expires));
  }

  setActor (actor: number) {
    cookies.set('actor', `${actor}`, this.writeOptions());
  }

  clear () {
    cookies.remove('token', this.writeOptions());
    cookies.remove('actor', this.writeOptions());
  }
}

const authInfoSource = new CookieAuthInfoSource();

export default authInfoSource;
