import React, { useState } from 'react';
import i18n from 'i18n';
import './IntelligentWarningTableWrapper.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { IntelligentWarningTable } from './IntelligentWarningTable';
import { SmartReminder } from 'core/intelligentWarning/IntelligentWarning';

export const IntelligentWarningTableWrapper: React.FC<{ data: SmartReminder }> = ({
  data
}) => {
  const [key, setKey] = useState('almostDue');
  const onSelectTab = (e: any) => {
    setKey(e);
  };

  const tabData = [
    {
      key: 'almostDue',
      type: 'almostDue',
      label: 'intelligentWarning.labels.almostDue'
    },
    {
      key: 'budgetNotEnough',
      type: 'budgetNotEnough',
      label: 'intelligentWarning.labels.budgetNotEnough'
    },
    {
      key: 'bidPriceNotEnough',
      type: 'bidPriceNotEnough',
      label: 'intelligentWarning.labels.bidPriceNotEnough'
    },
    {
      key: 'productCtrTooLow',
      type: 'productCtrTooLow',
      label: 'intelligentWarning.labels.productCtrTooLow'
    }
  ];

  return (
    <div className='intelligentWarningTableWrapper'>
      <Tabs
        id='intelligentWarningTable'
        activeKey={key}
        onSelect={onSelectTab}
        className='tab'
      >
        {tabData.map(({ key: eventKey, type, label }) => (
          <Tab
            key={eventKey}
            eventKey={eventKey}
            title={`${i18n.t<string>(label)} (${
              data[type] ? data[type].length : '0'
            })`}
          >
            <IntelligentWarningTable type={type} data={data[type]} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
