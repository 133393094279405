import {
    CategoryItem,
    FormatItems
} from 'core/intelligentWarning/IntelligentWarning';

export const formatData = (data: any): CategoryItem[] => {
  return Object.entries(data).map(([category, campaigns]) => ({
    category,
    items: (campaigns as FormatItems[]).slice(0, 2).map(({ campaignId, groupId, orderNumber, campName }) => ({
      name: campName,
      link: `/orders/${orderNumber}/campaign-groups/${groupId}/campaigns/${campaignId}/edit`
    }))
  }));
};

export function formatCTR (ctrValue) {
  return `${(ctrValue * 100).toFixed(2)}%`;
}
