import styles from './sudo.module.scss';

export function sudoNameFormatter (handleOnChange, cell: any, row: any, rowIndex, state): any {
  return (
    <div className={styles.sudoNameCell}>
      <div className={styles.selectCheckBox}>
        <input
          type='checkbox'
          checked={state.accountId === row.id}
          onChange={handleOnChange}
          value={row.id}
          id={`input${row.id}`}
        />
        <label htmlFor={`input${row.id}`} />
      </div>
      <div>
        <span>{cell}</span>
        <div className={styles.id}>
          {'ID: ' + row.id}
        </div>
      </div>
    </div>
  );
}
