import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';
import styles from './announcement.module.scss';
import classnames from 'classnames/bind';
import { Modal } from 'components/Modal/Modal';
import loginLogo from 'assets/logo.png';
import loginLogo2x from 'assets/logo@2x.png';
const cx = classnames.bind(styles);

export const Announcement: React.FC<{
  className?: string
}> = ({
  className
}) => {

  const [showAnnouncement, setShowAnnouncement] = useState(false);

  const openAnnouncement = useCallback(() => {
    setShowAnnouncement(true);
  }, []);

  const closeAnnouncement = useCallback(() => {
    setShowAnnouncement(false);
  }, []);

  return (
    <>
      {
        showAnnouncement && (
          <Modal
            animation={false}
            title=' '
            enableCloseBtn={true}
            dismiss={closeAnnouncement}
            className={styles.announcementModal}
          >
            <div className={styles.container}>
              <div className={styles.icon}>
                <img
                  src={loginLogo}
                  srcSet={loginLogo2x}
                  alt='tenmax icon'
                />
              </div>
              <div className={styles.title}>
                <div>
                  {i18n.t<string>('loginPage.titles.announcement')}
                </div>
                <div className={styles.divider}/>
              </div>
              <ol className={styles.content}>
                <li>您如購買本付費廣告，並完成購買程序及相關設定後，您所購買的付費廣告將會被啟用，直到期滿或儲值金額經廣告活動執行完畢為止。</li>
                <li>您如購買本付費廣告，您認知您的廣告將依系統的綜合指標於廣告版位中排序， 本公司將盡商業上合理的努力來完成您購買之內容，但不保證您要求的日期，或您設定的預算相關的曝光次數將完全獲得執行，本公司並保留變更廣告版位內商品則數的權利。</li>
                <li>您對於您於momoRMN廣告平台所取得的帳號和所自行設定的密碼，應負妥善保管和保密的義務，任何經由輸入正確帳號及密碼所發生的交易，均視為該帳號持有人之交易行為；經由該帳號和密碼所進行的所有行為，並應由帳號持有人負責。如果您發現或懷疑其帳號和密碼被其他人冒用或不當使用，應立即通知本公司，以便於本公司及時採取適當的因應措施；但上述因應措施不得解釋為本公司因此而明示或默示對您負有任何形式之賠償或補償義務。</li>
                <li>本公司定期維護更新廣告之相關數據，您可隨時於成效報表功能內查看相關付費廣告之成效數據報表，以及相關指標資料，包括您使用本付費廣告之費用(實際收取費用將依您規劃之預算為準)、使用者點擊次數及曝光次數等相關廣告成效指標。</li>
                <li>本公司得依促進產品銷售和廣告促銷的合理關聯，保有事前合理通知變更本條款及本付費廣告功能收費標準（包括有項目、用途、目的、金額或計算等標準）及收費方式之權利，本公司將於變更前事先透過 momoRMN廣告平台公告，嗣經momoRMN廣告平台公告後，如您仍繼續使用廣告功能，視為您同意接受並遵守公告（含更新）所載之一切約定。</li>
                <li>您所提供的廣告內容不得內含惡意軟體、間諜軟體、強制軟體或其他惡意程式碼，亦不得故意違反或規避計劃的任何安全措施。</li>
                <li>本公司為求提供最佳廣告媒合機制，將不定期進行測試，包括廣告格式、目標、目的地、品質、排名、成效、價格和競價期間的出價調整等，這些測試可能會對您使用廣告造成影響。為確保測試結果的即時和有效性，您同意本公司無需通知或補償即得進行上述測試。</li>
                <li>於法律允許的最大範圍內，本公司不提供且免除所有條件、聲明、保證和擔保，包括未侵權、品質滿意度、適售性和做為特殊用途的合適性，本公司以「現狀」、「現有」和「不保證沒有瑕疵」的情況提供，您應自行承擔。針對本付費廣告，本公司不做任何擔保，不保證告知瑕疵或錯誤。</li>
                <li>為維持刊登品質及曝光機會，本公司將視實際情況限制廣告版位之商品總則數、版位之提供。</li>
                <li>本付費廣告服務之相關廣告版位，自您購買完成並經本公司啟用廣告時起算至廣告期滿或儲值金額經廣告活動執行完畢為止，廣告期間內若發生商品無庫存下架或由您自行下架商品者，下架期間廣告將自動停止播送，本公司並依照實際廣告活動執行金額自儲值金額內扣款。</li>
                <li>您申請使用本付費廣告，一經完成廣告內容設定並經本公司刊播，已實際執行之廣告活動計價即不得以任何理由請求返還廣告費用。</li>
                <li>本公司針對本廣告服務提供兩種儲值方式：(1)自供應商貨款扣除，及(2)匯款，廣告服務費用經儲值後立即開立發票（ATM匯款於系統確認收款後開立發票），廣告執行皆以含稅價格計算，如須查詢執行明細，可於廣告相關報表查找執行細項清單。</li>
              </ol>
            </div>
          </Modal>
        )
      }
      <div className={cx(styles.announcement, className)} onClick={openAnnouncement}>
        <FontAwesomeIcon icon={faShieldAlt}/>
        {i18n.t<string>('loginPage.labels.announcement')}
      </div>
    </>
  );
};
