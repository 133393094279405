import { Product } from 'core/product/Product';

export type CreativeOfCampaign = {
  rtbCreativeId: any,
  id: number,
  name: string,
  status: number,
  ctr: number,
  imps: number,
  clicks: number,
  size: string,
  creativeType: CreativeType,
  approvalStatus: any,
  bannerImageUrl: string,
  creativeValue: any,
  createTime: string,
  landingPageUrl: string,
  isActiveBinding: boolean,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  bindingState: CampaignBannerMapState,
  effectiveStatus?: string,
  issuesInfo?: string,
  l3ChannelId: string,
  report?: any,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  previewLink?: string,
  retail?: string,
  product?: Product;
};

export type Creative = {
  basic: CreativeBasic
};

export type CreativeBasic = {
  advertiserId: number,
  agencyId: number,
  bannerUrl: string,
  creativeId: number,
  creativeType: CreativeType,
  creativeValues: any,
  landingPageUrl: string,
  name: string,
  tenmaxCategory: string,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  bannerImageUrl?: string,
  duration?: number,
  retail?: string,
  product?: Product;
};

export type BindingCampaign = {
  id: number,
  name: string,
  orderNumber: string,
  active: boolean,
  isActive: boolean
};

export enum CampaignBannerMapState {
  DEFAULT,
  ENABLE,
  DISABLE,
  BEFORE_START_TIME,
  AFTER_END_TIME,
  IN_PROGRESS
}

export type CreativeListRecord = {
  advertiserId: number,
  approvalStatus: { [adxName: string]: { state: number, udt: string } },
  bannerImageUrl: string | null,
  bannerUrl: string,
  bindingCampaign: BindingCampaign[],
  bindingCount: { enable: number, disable: number },
  createTime: string,
  creativeId: number,
  creativeState: CreativeStatus,
  creativeType: CreativeType,
  creativeValues: { [key: string]: string | number | boolean | object },
  name: string,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  duration?: number,
  retail?: string,
  product?: {
    productId: number | string;
    retailId: string;
    name: string;
    imgLink: string;
    multipack: number;
  };
  updateTime?: string;
};

export enum CreativeType {
  IMAGE = 1,
  PRODUCT_NATIVE = 9,
  RICH_PRODUCT_NATIVE = 12,
  SHOP = 14
}

export type CreativeValue = {
  w?: number;
  h?: number;
  imageUrl?: number;
  title?: string;
  desc?: string;
  img?: ImageData;
  img_l?: ImageData;
  img_s?: ImageData;
  icon?: ImageData;
  icon_l?: ImageData;
  icon_s?: ImageData;
};

export type ImageData = {
  url: string;
  w: number;
  h: number;
};

export enum CreativeStatus {
  'STATUS',
  'ACTIVE',
  'DEACTIVE'
}

export enum ApprovalStatus {
  'NEW',
  'PENDING',
  'APPROVED',
  'DENIED',
  'FIXED',
  'INCOMPLETED',
  'DELETED'
}

export enum ADX {
  asiamax = 'momo'
}
