import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { ProductFilter } from './ProductFilter';
import { Product, ProductTag } from 'core/product/Product';
import { ColumnDefinition, getStatusDesColumn, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import formatters from './listFormatters';
import _ from 'lodash';
import styles from './productList.module.scss';
import { ProductListDataContext, ProductListDataContextType } from './ProductListDataContext';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n';

export enum ProductListColumns {
  ID = 'productId',
  NAME = 'name',
  IMAGE = 'imgLink',
  TAG = 'tag',
  CATEGORY = 'category',
  SALEPRICE = 'salePrice'
}

export enum ProductListType {
  BASIC = 'basic'
}

export type ProductListState = {
  readonly selectedProductListType: ProductListType;
};

export const useProductListModel = (
  filterSet: ProductFilter,
  productList: Product[],
  allProducts: Product[],
  singleSelect?: boolean,
  limitSelect?: boolean
) => {

  const productListDataContext: ProductListDataContextType = useContext(ProductListDataContext);
  const { selectedProducts: initSelectedProducts, handleSelectedProducts, handleFilterChanged } = productListDataContext;
  const availbleProducts = _.uniqBy(allProducts.concat(initSelectedProducts), 'productId');
  const location = useLocation();
  const pathname = _.get(location, 'pathname');
  const [once, setOnce] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>(initSelectedProducts.map(product => product.productId));
  const [state, setState] = useState({
    selectedProductListType: ProductListType.BASIC
  } as ProductListState);

  const columnDefinition = (columnName: string, sortable: boolean = false, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `productList.labels.${columnName}`, sortable),
    classes: () => styles[columnName],
    headerClasses : () => styles[columnName]
  });

  const onSelect = (productId: string) => {
    const product = availbleProducts.find(product => product.productId === productId);
    if (!product) {
      return;
    }

    if (selectedProducts.indexOf(productId) > -1) {
      if (singleSelect) {
        setSelectedProducts([]);
        handleSelectedProducts(() => []);
        return;
      }
      setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(id => id !== productId));
      handleSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(product => product.productId !== productId));
    } else {
      if (singleSelect) {
        setSelectedProducts([productId]);
        handleSelectedProducts(() => [product]);
        return;
      }
      if (limitSelect && selectedProducts.length >= 25) {
        toast.warn(i18n.t<string>('productList.messages.upperLimit'));
        return;
      }
      setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, productId]);
      handleSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
    }
  };

  const selectProductsAfterFilter = selectedProducts.filter(id => productList.find(product => product.productId === id));
  const onSelectAll = () => {
    if (singleSelect) {
      return;
    }
    if (selectProductsAfterFilter.length === productList.length) {
      const newSelectedProducts = selectedProducts.filter(id => !productList.find(product => product.productId === id));
      setSelectedProducts(newSelectedProducts);
      handleSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => !productList.find(product => product.productId === selectedProduct.productId)));
    } else {
      const newSelectedProducts = _.uniq([...selectedProducts, ...productList.map(product => product.productId)]);
      setSelectedProducts(newSelectedProducts);
      handleSelectedProducts(selectedProducts => _.uniqBy([...selectedProducts, ...productList], 'productId'));
    }
  };

  const handleRemoveSelect = useCallback(() => {
    setSelectedProducts([]);
    handleSelectedProducts(() => []);
  }, [setSelectedProducts, handleSelectedProducts]);

  const onProductListTypeChange = (selectedProductListType: any) => {
    setState(prevState => ({ ...prevState, selectedProductListType }));
  };

  const selectConfig = {
    singleSelect: singleSelect || false,
    limitSelect: limitSelect || false,
    productsOfCurrentConditionLength: productList.length,
    selectedProductsOfCurrentCondition: selectProductsAfterFilter,
    hasSelectedProductsOfOtherCondition: selectedProducts.length > selectProductsAfterFilter.length
  };
  const idColumn = renderColumn({
    ...columnDefinition(ProductListColumns.ID),
    formatExtraData: {
      // currentUrl: match.url,
      selectedProducts,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.curry(formatters.nameHeaderFormatter)(selectConfig)(onSelectAll)
  );

  const getTagData = useCallback((tag: ProductTag) => {
    const des = tag;
    switch (tag) {
      case ProductTag.HOT:
        return {
          des,
          style: {
            backgroundColor: '#9ECE11'
          }
        };
      case ProductTag.TRENDING:
        return {
          des,
          style: {
            backgroundColor: '#EEC300'
          }
        };
      case ProductTag.ROAS:
        return {
          des,
          style: {
            backgroundColor: '#ce555c'
          }
        };
      case ProductTag.HCP:
        return {
          des,
          style: {
            backgroundColor: '#00ABF0'
          }
        };
      default:
        return;
    }
  }, []);

  const tagColumn = useMemo(() => {
    return getStatusDesColumn(
      renderColumn(columnDefinition(ProductListColumns.TAG)),
      (product: Product) => {
        return _.compact(product.tags.map(tag => getTagData(tag)));
      }
    );
  }, [getTagData]);

  const basicColumns = _.compact([
    idColumn,
    renderColumn(columnDefinition(ProductListColumns.IMAGE), formatters.imageFormatter),
    tagColumn,
    renderColumn(columnDefinition(ProductListColumns.CATEGORY)),
    renderColumn(columnDefinition(ProductListColumns.SALEPRICE))
  ]);

  const typeColumnsMap = {
    [ProductListType.BASIC]: basicColumns
  };

  useEffect(() => {
    handleFilterChanged(filterSet);
  },[filterSet, handleFilterChanged]);
  useEffect(() => {
    const defaultSelect = () => {
      if (singleSelect) {
        return;
      }
      if (selectProductsAfterFilter.length === productList.length) {
        const newSelectedProducts = selectedProducts.filter(id => !productList.find((product,index) => product.productId === id && index <= 24));
        setSelectedProducts(newSelectedProducts);
        handleSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => !productList.find(product => product.productId === selectedProduct.productId)));
      } else {
        const newSelectedProducts = _.uniq([...selectedProducts, ...productList.map(product => product.productId).filter((id,index) => index <= 24)]);
        setSelectedProducts(newSelectedProducts);
        handleSelectedProducts(selectedProducts => _.uniqBy([...selectedProducts, ...productList], 'productId'));
      }
    };
    if (pathname.includes('campaigns/new') && availbleProducts.length > 0) {
      if (once) {
        return;
      }
      if (selectedProducts.length === 0 && availbleProducts.every(product => product.tags.includes(ProductTag.HOT))) {
        setOnce(true);
        defaultSelect();
        toast.success(i18n.t<string>('productList.messages.defaultSelectHCP'));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availbleProducts]);
  return {
    state,
    selectedProducts,
    availbleProducts,
    columns: typeColumnsMap[state.selectedProductListType],
    onProductListTypeChange,
    handleRemoveSelect,
    handleRemoveProduct: onSelect
  };
};
