import client from './RestClient';
import fileDownload from 'js-file-download';
import { ReportDimension, ReportGran, ReportData, ReportType } from 'core/report/ReportData';
import _ from 'lodash';
import { AxiosInstance } from 'axios';

export const defaultFormat = 'YYYY-MM-DD';

export interface ReportWebService {
  downloadL1ObjectReport (order: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string): Promise<ReportData>;
  downloadTenmaxReconciliationReport (from: string, to: string): Promise<void>;
}

export class RestfulReportWebService implements ReportWebService {
  restClient: AxiosInstance;
  defualtFileDownload: any;

  constructor (restClient: AxiosInstance = client, defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
    this.defualtFileDownload = defualtFileDownload;
  }

  async downloadL1ObjectReport (l1Object: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    const url = `/v2/report/export/l1-object?l1ObjectId=${l1Object.l1ObjectId}&groupBy=${groupBy}&metrics=${selectFields.join(',')}&from=${startDate}&to=${endDate}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${l1Object.name}.xlsx`);
  }

  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string) {
    const filterQueryParams = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
    const reportType = type === ReportType.PERFORMANCE ? 'channel-performance' : type.toLowerCase();
    let url = `report/${reportType}?dimension=${dimension}&gran=${gran}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&${filterQueryParams}`;
    const response = await this.restClient.get(url);
    return wrapReportData(response.data);
  }

  async downloadTenmaxReconciliationReport (from: string, to: string): Promise<void> {
    const response = await this.restClient.get(`/v2/report/export/tenmax-reconciliation?from=${from}&to=${to}`, { responseType: 'blob' });
    this.defualtFileDownload(response.data, 'TenmaxReconciliationReport.csv');
  }
}

const wrapReportData = (json: any) => {
  return {
    allowMetrics: _.get(json, 'allowMetrics', []),
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []),
    currency: _.get(json, 'currency')
  };
};
