import { AdType } from 'core/rtbCampaign/RtbCampaign';

export const defaultCampaignBidPrice: {
  type: string;
  autoBidCap: {[key: string]: string};
  bidFloor: {[key: string]: string};
}[] = [{
  type: AdType.DISPLAY,
  autoBidCap: {
    cpc: '300',
    cpm: '500'
  },
  bidFloor: {
    cpc: '100',
    cpm: '100'
  }
}, {
  type: AdType.KEYWORD,
  autoBidCap: {
    cpc: '100',
    cpm: '25'
  },
  bidFloor: {
    cpc: '5',
    cpm: '5'
  }
}, {
  type: AdType.SMART_KEYWORD,
  autoBidCap: {
    cpc: '100',
    cpm: '25'
  },
  bidFloor: {
    cpc: '5',
    cpm: '5'
  }
}];
