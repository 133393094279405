import React from 'react';
import LimitationSetting from './LimitationSetting/LimitationSetting';
import styles from './editLimitation.module.scss';
import { ChangeOperateAlertDialog } from './ChangeOperateAlertDialog/ChangeOperateAlertDialog';
import i18n from 'i18n';
import _ from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { LIMITATION_TYPE } from './LimitationSetting/LimitationSettingData';
import { EditLimitationModel } from './EditLimitationModel';
import { Order } from 'core/order/Order';
import { OPERATE } from 'enum/Operate';

export class EditLimitation extends React.Component<{
  model: EditLimitationModel,
  limitationType?: LIMITATION_TYPE,
  renderGeneralFields?: (
    limitationValue: any,
    handleChange: (operate: string, type: string, value: any) => void
  ) => React.ReactNode,
  order?: Order
}, any> {
  handler?: number;
  constructor (props) {
    super(props);
    this.state = {
      modalShown: false,
      type: '',
      newOperate: '',
      orgOperate: '',
      limitationValue: {
        INST: true
      }
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  hideModal = () => {
    this.setState({
      modalShown: false
    });
  }

  changeOperate = (
    orgOperate = this.state.orgOperate,
    type = this.state.type,
    newOperate = this.state.newOperate
  ) => {
    let limitationValue = this.props.model.limitationValue;
    if (!limitationValue[newOperate]) {
      limitationValue[newOperate] = [];
    }
    let limitationdelete = limitationValue[orgOperate].findIndex(function (
      element
    ) {
      return element.type === type;
    });
    let IsInNewLimitationOperateGroup = limitationValue[newOperate].findIndex(
      function (element) {
        return element.type === type;
      }
    );
    if (IsInNewLimitationOperateGroup > -1) {
      limitationValue[newOperate].splice(IsInNewLimitationOperateGroup, 1);
    }
    limitationValue[newOperate].push(
      limitationValue[orgOperate][limitationdelete]
    );
    limitationValue[orgOperate].splice(limitationdelete, 1);
    this.hideModal();
    this.props.model.onLimitationChange();
  }

  handleChangeSetting = (inventory, operate) => {
    this.props.model.showInventory(inventory, operate);
  }

  handleDeleteLimitation = (operate, type) => {
    let limitationValue = this.props.model.limitationValue;
    let limitationdelete = limitationValue[operate].findIndex(function (
      element
    ) {
      return element.type === type;
    });
    if (limitationdelete !== -1) {
      limitationValue[operate].splice(limitationdelete, 1);
    }
    this.props.model.onLimitationChange();
  }

  handleChangeOperate = (orgOperate, type, newOperate) => {
    let IsInNewLimitationOperateGroup = this.props.model.limitationValue[
      newOperate
    ] ? this.props.model.limitationValue[
      newOperate
    ].findIndex(function (element) {
      return element.type === type;
    }) : -1;
    if (IsInNewLimitationOperateGroup > -1) {
      this.setState({
        modalShown: true,
        orgOperate: orgOperate,
        type: type,
        newOperate: newOperate
      });
    } else {
      this.changeOperate(orgOperate, type, newOperate);
    }
  }

  handleChange = (operate, type, value) => {
    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== undefined)) {
      let limitationValue = this.props.model.limitationValue;
      if (!limitationValue[operate]) {
        limitationValue[operate] = [];
      }
      let indexOFLimitationChange = limitationValue[operate].findIndex(
        function (element) {
          return element.type === type;
        }
      );
      if (indexOFLimitationChange > -1) {
        limitationValue[operate][indexOFLimitationChange].value = value;
      } else {
        limitationValue[operate].push({ value: value, type: type });
      }
      this.props.model.onLimitationChange();
    } else {
      this.handleDeleteLimitation(operate, type);
    }
  }

  handleChangeDealId = (values) => {
    const lastValue = _.last(values);
    if (typeof lastValue === 'string') {
      values[values.length - 1] = { label: lastValue, value: lastValue };
    }

    let limitationValue = this.props.model.limitationValue;
    let indexOFLimitationChange = limitationValue['other'].findIndex(
      (element) => element.type === 'dealId');
    if (indexOFLimitationChange > -1) {
      limitationValue['other'][indexOFLimitationChange].value = values;
    } else {
      limitationValue['other'].push({ value: values, type: 'dealId' });
    }
    this.forceUpdate();
  }

  renderLimitationPage = () => {
    const { show, inventory } = this.props.model.state;
    const { limitationValue, addonFeature, limitationSetting, taOptionsCache } = this.props.model;
    const { type, newOperate } = this.state;
    const handleChangeOperateForAlert = this.changeOperate;

    const renderLimitationSetting = (operationSet) => {
      return (
        <LimitationSetting
          taOptionsCache={taOptionsCache}
          limitationSetting={limitationSetting}
          permission={addonFeature}
          showInventory={show === operationSet}
          value={limitationValue[operationSet]}
          onChange={this.handleChange.bind(this, operationSet)}
          onChangeInventory={this.handleChangeSetting}
          operate={operationSet}
          inventorySelected={inventory}
          onDeleteLimitation={this.handleDeleteLimitation}
          onChangeOperate={this.handleChangeOperate}
          errors={_.get(this.state.errors, operationSet)}
        />
      );
    };
    return (
      <div>
        {this.props.model.operationSet.need &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t<string>('limitation.title.you.need')}</span>
            </legend>
            {this.props.renderGeneralFields &&
              <div className={styles.limitationSettingContainer}>
                <div className={styles.limitationSetting}>
                  <div className={styles.limitationSet}>
                    <div className={styles.generalTitle}>
                      <span>{i18n.t<string>('limitation.labels.general')}</span>
                    </div>
                    {this.props.renderGeneralFields(this.props.model.limitationValue, this.handleChange)}
                  </div>
                </div>
              </div>
            }
            {this.props.model.operationSet.need
              .filter(operationSet => operationSet !== OPERATE.PREFERRED)
              .map((operationSet) => (
                <div
                  key={operationSet}
                  className={styles.limitationSettingContainer}
                >
                  {renderLimitationSetting(operationSet)}
                </div>
              ))
            }
          </fieldset>
        }
        {this.props.model.operationSet.notNeed &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t<string>('limitation.title.you.dont.need')}</span>
            </legend>
            {this.props.model.operationSet.notNeed.map((operationSet) => (
              <div
                key={operationSet}
                className={styles.limitationSettingContainer}
              >
                {renderLimitationSetting(operationSet)}
              </div>
            ))}
          </fieldset>
        }
        {this.state.modalShown && (
          <ChangeOperateAlertDialog
            changeOperate={handleChangeOperateForAlert}
            dismiss={this.hideModal}
            type={type}
            newOperate={newOperate}
          />
        )}
      </div>
    );
  }

  render () {
    return (
      <div>
        {this.props.model.state.loading && <LoadingIndicator/>}
        {this.renderLimitationPage()}
      </div>
    );
  }
}
