import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useMemo, useState } from 'react';
import formatters, { getRtbCampaignGroupDeliveryDesDatas, getRtbCampaignGroupStatusDesData } from './listFormatters';
import styles from './l1ObjectList.module.scss';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import { useRouteMatch } from 'react-router-dom';
import i18n from 'i18n';
import _ from 'lodash';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { numberWithCommas } from 'utils/StringUtil';
import { AddonFeatureManager } from 'core';
import { Order, State } from 'core/order/Order';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import moment from 'moment';
import { useCallAPI } from 'hooks/useCallAPI';
import {
  getCpc,
  getCtr,
  getDivideValue,
  metricsAdder,
  metricsFormatter,
  getPercentValue
} from 'helper/MetricsHelper';
import { FilterMenuTabConfig } from 'components/FilterMenuTab/FilterMenuTab';
import { L1Object, L1ObjectState, L2ObjectRunningStatus } from 'core/l1Object/L1Object';
import { notSettleOrder } from 'core/permission/PermissionDSL';

export enum L1ObjectListColumns {
  ID = 'l1ObjectId',
  BUDGET = 'budget',
  CHANNEL = 'channel',
  STATE = 'state',
  DELIVERY = 'delivery',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  SPENT = 'spent',
  BASIC_SPENT = 'basicSpent',
  EDITBTNS = 'editBtns'
}

export enum ListChannel {
  ALL = 'ALL',
  RTB = 'RTB'
}

export enum L1ObjectListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance'
}

export type L1ObjectListState = {
  readonly searchString: string;
  readonly filteredList: any[];
  readonly deleteL1Object?: () => void;
  readonly selectedMetricsGroup: L1ObjectListType;
  readonly summaryData: any;
  readonly selectedChannel: ListChannel;
  readonly selectedStatusFilter: string[];
  readonly selectedDeliveryFilter: string[];
  readonly l1ObjectStatusList: string[];
  readonly l1ObjectDeliveryList: string[];
};

const defaultL1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager();

const channelMetricsGroupMap = {
  [ListChannel.ALL]: [
    L1ObjectListType.BASIC,
    L1ObjectListType.PERFORMANCE
  ],
  [ListChannel.RTB]: [
    L1ObjectListType.BASIC,
    L1ObjectListType.PERFORMANCE
  ]
};

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export const useL1ObjectListModel = (
  order: Order,
  l1ObjectList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager,
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
) => {

  const [state, setState] = useState({
    searchString: '',
    filteredList: [],
    selectedMetricsGroup: L1ObjectListType.BASIC,
    selectedChannel: ListChannel.ALL,
    summaryData: undefined,
    selectedStatusFilter: [],
    selectedDeliveryFilter: [],
    l1ObjectStatusList: [],
    l1ObjectDeliveryList: []
  } as L1ObjectListState);

  const { loading, callAPIs } = useCallAPI();

  const l1ObjectWithPerformanceList = useMemo(() => {
    return l1ObjectList.map((l1Object, index) => {
      const report = l1Object.report;
      const budget = {
        lifetime: _.defaultTo(l1Object.budget, -1),
        daily: _.defaultTo(l1Object.dailyBudget, -1)
      };
      const conversionList = _.defaultTo(l1Object.conversion, []);
      const conversion = _.mapValues(
        _.keyBy(conversionList.map(data => ({ ...data, action_type: data.action_type.replace('.', '_') })), 'action_type'),
        'actions'
      );
      const impres = _.get(report, 'impres', _.get(report, 'impressions'));
      const spent = _.get(report, 'spent', _.get(report, 'spend', 0));
      setState(state => ({
        ...state,
        l1ObjectStatusList: _.uniq(_.concat(state.l1ObjectStatusList, getFilterTypeDes(L1ObjectListColumns.STATE, l1Object, order))),
        l1ObjectDeliveryList: _.uniq(_.concat(state.l1ObjectDeliveryList, getFilterTypeDes(L1ObjectListColumns.DELIVERY, l1Object, order)))
      }));
      return report ? {
        ..._.omit(l1Object, ['report', 'conversion']),
        ...report,
        ...conversion,
        budget,
        [L1ObjectListColumns.IMPRES]: impres,
        [L1ObjectListColumns.CPC]: getPriceValue(order.currency, getCpc(spent, report.clicks)),
        [L1ObjectListColumns.CTR]: getCtr(impres, report.clicks),
        [L1ObjectListColumns.SPENT]: spent
      } : {
        ...l1Object,
        ...conversion,
        budget
      };
    });
  }, [order, l1ObjectList]);

  const allowedChannels = useMemo(() => {
    return l1ObjectManager.getAllowedChannels(addonFeatureManager);
  }, [l1ObjectManager, addonFeatureManager]);

  const channels = useMemo(() => {
    const channelsOfCurrentList = l1ObjectList.reduce((acc, l1Object) => {
      return _.uniq([...acc, l1Object.channel]);
    }, []);

    return Object.values(ListChannel)
      .filter((channel: string) => {
        return channelsOfCurrentList.includes(channel) ||
          allowedChannels.includes(channel);
      });
  }, [allowedChannels, l1ObjectList]);

  const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => {
    const handleOnFilterApply = (filterType: string, filterList: string[]) => {
      setState({
        ...state,
        [filterType]: [...filterList]
      });
    };

    return [
      {
        filterType: i18n.t<string>('l1ObjectList.labels.statusFilter'),
        menuTitle: i18n.t<string>('l1ObjectList.labels.statusFilterMenuTitle'),
        tag: i18n.t<string>('l1ObjectList.labels.statusTag'),
        selectedValues: state.selectedStatusFilter,
        options: state.l1ObjectStatusList,
        applyMethod: _.partial(handleOnFilterApply, 'selectedStatusFilter')
      },
      {
        filterType: i18n.t<string>('l1ObjectList.labels.deliveryFilter'),
        menuTitle: i18n.t<string>('l1ObjectList.labels.deliveryFilterMenuTitle'),
        tag: i18n.t<string>('l1ObjectList.labels.deliveryTag'),
        selectedValues: state.selectedDeliveryFilter,
        options: state.l1ObjectDeliveryList,
        applyMethod: _.partial(handleOnFilterApply, 'selectedDeliveryFilter')
      }
    ];
  }, [state]);

  const match = useRouteMatch();
  const [selectedL1Objects, setSelectedL1Objects] = useState<(number | string)[]>([]);
  const [advertiserName, setAdvertiserName] = useState('');

  const getAdvertiserOptions = useCallback(async () => {
    callAPIs([
      advertiserManager.getAdvertiserOptions.bind(advertiserManager, order.agencyId)
    ], options => {
      const advOption = options.find(option => option.value === order.advertiserId);
      setAdvertiserName(_.get(advOption, 'label', ''));
    });
  }, [order.agencyId, order.advertiserId, advertiserManager, callAPIs]);

  useEffect(() => {
    if (allowedChannels.includes(ListChannel.RTB)) {
      setState(prevState => ({
        ...prevState,
        selectedChannel: ListChannel.RTB
      }));
    }
  }, [allowedChannels]);

  useEffect(() => {
    getAdvertiserOptions();
  }, [getAdvertiserOptions]);

  useEffect(() => {
    const filteredList = l1ObjectWithPerformanceList.filter(l1Object => {
      return l1ObjectListFilter(
        l1Object,
        state.searchString,
        state.selectedChannel,
        order,
        state.selectedStatusFilter,
        state.selectedDeliveryFilter
      );
    });

    const clickSum = filteredList.reduce<number>((partial, l1Object) => metricsAdder(partial, l1Object.clicks), 0);
    const spentSum = filteredList.reduce<number>((partial, l1Object) => metricsAdder(partial, getPriceValue(order.currency, l1Object.spent)), 0);
    const impresSum = filteredList.reduce<number>((partial, l1Object) => metricsAdder(partial, l1Object.impres), 0);
    const summaryData = {
      [L1ObjectListColumns.ID]: i18n.t<string>('l1ObjectList.labels.l1ObjectCount', { count: filteredList.length }),
      [L1ObjectListColumns.IMPRES]: numberWithCommas(impresSum),
      [L1ObjectListColumns.CLICKS]: numberWithCommas(clickSum),
      [L1ObjectListColumns.CPC]: formatPrice(order.currency, getDivideValue(spentSum, clickSum)),
      [L1ObjectListColumns.CTR]: getPercentValue(clickSum, impresSum),
      [L1ObjectListColumns.SPENT]: numberWithCommas(getPriceValue(order.currency, spentSum))
    };
    setState(prevState => ({ ...prevState, filteredList, summaryData }));
  }, [state.selectedChannel, state.searchString, state.selectedStatusFilter, state.selectedDeliveryFilter, l1ObjectWithPerformanceList, order]);

  const onDeleteBtnClick = (l1ObjectToDelete: number) => setState({ ...state, deleteL1Object: _.partial(deleteL1Object, l1ObjectToDelete) });

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setState(prevState => ({ ...prevState, deleteL1Object: undefined }));
    refresh && refreshList();
  }, [refreshList]);

  const deleteL1Object = useCallback(async (l1ObjectId: number) => {
    callAPIs([
      l1ObjectManager.deleteL1Object.bind(l1ObjectManager, l1ObjectId)
    ], () => {
      onDeleteModalClose(true);
    });
  }, [l1ObjectManager, onDeleteModalClose, callAPIs]);

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const columnDefinition = (columnName, customLabel?: string, sortFunc?: any): ColumnDefinition => ({
    ...sortableColumn(columnName, _.defaultTo(customLabel, `l1Object.labels.${columnName}`), true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName],
    sortFunc
  });

  const onSelect = (l1ObjectId: number | string) => {
    setSelectedL1Objects(handleOnSelect(selectedL1Objects, l1ObjectId));
  };

  const editableL1Objects = useMemo(() => {
    return state.filteredList.filter(l1Object =>
      l1ObjectManager.isChannelAllowed(l1Object.channel, addonFeatureManager) &&
      l1Object.state !== L1ObjectState.DELETE
    );
  }, [state.filteredList, l1ObjectManager, addonFeatureManager]);

  const onSelectAll = () => {
    setSelectedL1Objects(handleOnSelectAll(selectedL1Objects, editableL1Objects));
  };

  const handleRemoveSelect = () => {
    setSelectedL1Objects([]);
  };

  const onMetricsGroupChange = (selectedMetricsGroup) => {
    setState(prevState => ({ ...prevState, selectedMetricsGroup }));
  };

  const onListChannelChange = (listChannel) => {
    if (listChannel === null) {
      return;
    }
    setSelectedL1Objects([]);
    setState(prevState => ({ ...prevState, selectedChannel: listChannel }));
  };

  const idColumn = renderColumn({
    ...columnDefinition(L1ObjectListColumns.ID),
    formatExtraData: {
      permissionAwareGetter: (l1Object) => l1ObjectManager.getModifyPermissionItem(l1Object).and(notSettleOrder(order)),
      currentUrl: match.url,
      selectedL1Objects,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    editableL1Objects.length,
    selectedL1Objects,
    order,
    onSelectAll
  ));

  const budgetColumn = renderColumn(
    columnDefinition(
      L1ObjectListColumns.BUDGET,
      i18n.t<string>(`l1Object.labels.${L1ObjectListColumns.BUDGET}`) + ` (${order.currency})`,
      budgetSorter
    ),
    _.partial(formatters.budgetFormatter, getBudgetValue, order.currency)
  );

  const basicSpentColumn = renderColumn(
    columnDefinition(
      L1ObjectListColumns.BASIC_SPENT,
      i18n.t<string>(`l1Object.labels.${L1ObjectListColumns.SPENT}`) + ` (${order.currency})`),
      (_1, l1Object: L1Object) => formatPrice(order.currency, _.get(l1Object, 'spent', 0))
  );

  const editBtns = renderColumn({
    ...columnDefinition(L1ObjectListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      permissionAwareGetter: l1ObjectManager.getModifyPermissionItem,
      currentUrl: match.url,
      onDeleteBtnClick,
      order
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = _.compact([
    idColumn,
    renderColumn({
      ...columnDefinition(L1ObjectListColumns.STATE),
      formatExtraData: {
        order
      }
    }, formatters.stateFormatter),
    renderColumn({
      ...columnDefinition(L1ObjectListColumns.DELIVERY),
      formatExtraData: (l1Object: L1Object) => getL1ObjectProgress(l1Object)
    }, formatters.deliveryFormatter),
    budgetColumn,
    basicSpentColumn,
    state.selectedChannel === ListChannel.ALL ? renderColumn(columnDefinition(L1ObjectListColumns.CHANNEL), formatters.channelFormatter) : undefined,
    editBtns
  ]);

  const metricsWithCommasFormatter = _.partial(metricsFormatter, numberWithCommas);

  const spentColumn = renderColumn(
    columnDefinition(
      L1ObjectListColumns.SPENT,
      i18n.t<string>(`l1Object.labels.${L1ObjectListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partialRight(formatPrice, order.currency, _)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(L1ObjectListColumns.IMPRES),
    metricsWithCommasFormatter
  );

  const performanceColumns = _.compact([
    idColumn,
    state.selectedChannel === ListChannel.ALL ?
      renderColumn(columnDefinition(L1ObjectListColumns.CHANNEL), formatters.channelFormatter) :
      undefined,
    budgetColumn,
    impresColumn,
    renderColumn(columnDefinition(L1ObjectListColumns.CLICKS), metricsWithCommasFormatter),
    renderColumn(
      columnDefinition(L1ObjectListColumns.CPC, i18n.t<string>(`l1Object.labels.${L1ObjectListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partialRight(formatPrice, order.currency, _))
    ),
    renderColumn(columnDefinition(L1ObjectListColumns.CTR), _.partial(metricsFormatter, value => `${_.floor(value, 2).toFixed(2)}%`)),
    spentColumn,
    editBtns
  ]);

  const typeColumnsMap = {
    [L1ObjectListType.BASIC]: basicColumns,
    [L1ObjectListType.PERFORMANCE]: performanceColumns
  };

  const metricsGroupOptions = useMemo(() => {
    return channelMetricsGroupMap[state.selectedChannel].map(metricsGroup => ({
      label: i18n.t<string>(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [state.selectedChannel]);

  useEffect(() => {
    if (metricsGroupOptions.find((option) => {
      return option.value === state.selectedMetricsGroup;
    }) === undefined) {
      setState(prevState => ({ ...prevState, selectedMetricsGroup: metricsGroupOptions[0].value }));
    }
  }, [state.selectedMetricsGroup, metricsGroupOptions]);

  const activeL1Object = async () => {
    callAPIs([
      l1ObjectManager.updateL1ObjectState.bind(l1ObjectManager, selectedL1Objects, 'activate')
    ], () => {
      setSelectedL1Objects([]);
      refreshList();
    });
  };

  const deactiveL1Object = async () => {
    callAPIs([
      l1ObjectManager.updateL1ObjectState.bind(l1ObjectManager, selectedL1Objects, 'deactivate')
    ], () => {
      setSelectedL1Objects([]);
      refreshList();
    });
  };

  return {
    state,
    columns: typeColumnsMap[state.selectedMetricsGroup],
    loading,
    currentUrl: match.url,
    selectedL1Objects,
    metricsGroupOptions,
    canNotCreateMessage: getCanNotCreateMessage(order, allowedChannels),
    filterMenuTabConfigs,
    advertiserName,
    channels,
    onHandleSearch,
    onMetricsGroupChange,
    onListChannelChange,
    onDeleteModalClose: _.partial(onDeleteModalClose, false),
    handleRemoveSelect,
    activeL1Object,
    deactiveL1Object
  };
};

const getBudgetValue = (budget: any, currency: string) => {
  const hasBudget = budget.lifetime !== -1;
  const hasDailyBudget = budget.daily !== -1;
  if (hasBudget) {
    return formatPrice(currency, +budget.lifetime);
  }
  if (hasDailyBudget) {
    return i18n.t<string>('common.valuePerDay', { value: formatPrice(currency, +budget.daily) });
  }
  return i18n.t<string>('common.labels.noData');
};

const getFilterTypeDes = (type: L1ObjectListColumns, l1Object, order) => {
  let data: any;
  switch (type) {
    case L1ObjectListColumns.DELIVERY:
      data = getRtbCampaignGroupDeliveryDesDatas(l1Object);
      return _.map(data, 'des');
    case L1ObjectListColumns.STATE:
    default:
      data = getRtbCampaignGroupStatusDesData(l1Object, order);
      return data.des;
  }
};

const basicSorter = (dataA, dataB, order) => {
  if (order === 'desc') {
    return dataA > dataB ? -1 : 1;
  } else {
    return dataA < dataB ? -1 : 1;
  }
};

const budgetSorter = (dataA, dataB, order) => {
  const dataADaily = _.get(dataA, 'daily');
  const dataBDaily = _.get(dataB, 'daily');
  const dataALifetime = _.get(dataA, 'lifetime');
  const dataBLifetime = _.get(dataB, 'lifetime');
  const allDailyType = dataADaily !== -1 && dataBDaily !== -1;
  const allLifetimeType = dataALifetime !== -1 && dataBLifetime !== -1;
  const allNoBudget = dataADaily === -1 && dataBDaily === -1 && dataALifetime === -1 && dataBLifetime === -1;
  if (allNoBudget) {
    return 0;
  }
  if (allDailyType) {
    return basicSorter(dataADaily, dataBDaily, order);
  }
  if (allLifetimeType) {
    basicSorter(dataALifetime, dataBLifetime, order);
  }

  return dataALifetime !== -1 ? -1 : 1;
};

const getL1ObjectProgress = (l1Object: L1Object) => {
  const l2ObjectRunningStatus = l1Object.l2ObjectRunningStatus;

  const budget = _.get(l2ObjectRunningStatus, L2ObjectRunningStatus.BUDGET, 0) as number;
  const spent = _.get(l2ObjectRunningStatus, L2ObjectRunningStatus.SPENT, 0) as number;
  const executeRate = _.get(l2ObjectRunningStatus, L2ObjectRunningStatus.ACTUAL_PROGRESS, 0) as number;
  const predictRate = _.get(l2ObjectRunningStatus, L2ObjectRunningStatus.EXPECTED_PROGRESS, 0) as number;
  const discrepancy = predictRate - executeRate;

  return {
    executeRate,
    predictRate,
    danger: spent > budget * 1.1,
    deepWarning: discrepancy > 0.03,
    warning: discrepancy > 0.01 && discrepancy <= 0.03
  };
};

const getCanNotCreateMessage = (order, allowedChannels) => {
  const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
  const state = order.state;
  if (canNotCreateState.includes(state)) {
    return i18n.t<string>('l1ObjectList.labels.orderStateCannotCreate');
  }

  const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
  if (isEnd) {
    return i18n.t<string>('l1ObjectList.labels.isEndCannotCreate');
  }

  if (_.isEmpty(allowedChannels)) {
    return i18n.t<string>('l1ObjectList.labels.noChannelAllowed');
  }

  return '';
};

const l1ObjectListFilter = (
  l1Object,
  searchString: string,
  selectedChannel: ListChannel,
  order: Order,
  selectedStatusFilter: string[],
  selectedDeliveryFilter: string[]
) => {
  const nameInclude = l1Object.name.toLowerCase().includes(searchString.toLowerCase());
  const idInclude = l1Object.l1ObjectId.toString().includes(searchString);
  const channelCorrect = selectedChannel === ListChannel.ALL || l1Object.channel === selectedChannel;
  const filterInclude = (selectedStatusFilter.length === 0 && selectedDeliveryFilter.length === 0 && l1Object.state !== L1ObjectState.DELETE)
   || selectedStatusFilter.includes(getFilterTypeDes(L1ObjectListColumns.STATE, l1Object, order))
   || !_.isEmpty(_.intersection(selectedDeliveryFilter, getFilterTypeDes(L1ObjectListColumns.DELIVERY, l1Object, order)));
  return channelCorrect && (nameInclude || idInclude) && filterInclude;
};

const handleOnSelect = (selectedArray, objectId) => {
  if (selectedArray.indexOf(objectId.toString()) > -1) {
    return selectedArray.filter(id => id.toString() !== objectId.toString());
  }
  return [...selectedArray, objectId.toString()];
};

const handleOnSelectAll = (selectedArrays, editalbeArrays) => {
  if (selectedArrays.length === editalbeArrays.length) {
    return [];
  }

  return editalbeArrays.map(l1Object => l1Object.l1ObjectId.toString());
};
