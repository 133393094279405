import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './productListOverview.module.scss';
import { Product } from 'core/product/Product';
import { partial } from 'lodash';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames/bind';
import { Slider } from 'components/Slider/Slider';
import { DefaultSliderModel } from 'components/Slider/SliderModel';
const cx = classnames.bind(styles);

export const ProductListOverview: React.FC<React.PropsWithChildren<{
  products: Product[],
  handleRemoveProduct: (productId: string) => void
  handleRemoveAll: () => void
}>> = ({
  products,
  handleRemoveProduct,
  handleRemoveAll,
  children
}) => {

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const toggleShowDetail = useCallback(() => {
    setShowDetail(detail => !detail);
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      setShowDetail(() => true);
    }
  }, [products.length]);

  const overview = products.map(product => {
    return (
      <div key={product.productId} className={styles.overview}>
        <div className={styles.overviewLeft}>
          <div className={styles.checkbox}>
            <input
              type='checkbox'
              checked={true}
              onChange={partial(handleRemoveProduct, product.productId)}
              id={product.productId}
            />
            <label htmlFor={product.productId} />
          </div>
          <img src={product.imgLink} alt={product.name} />
        </div>
        <div className={styles.overviewRight}>
          <div className={styles.productName}>{product.name}</div>
          <div className={styles.productId}>{`ID: ${product.productId}`}</div>
        </div>
      </div>
    );
  });

  const seperationLineClass = cx(styles.seperationLine, {
    show: showDetail
  });
  const overviewAreaClass = cx(styles.overviewArea, {
    show: showDetail
  });

  const sliderModel = useMemo(() => new DefaultSliderModel(195, 92, false, false, 0, false), []);

  return (
    <div className={styles.productListOverview}>
      <div className={styles.operateArea}>
        <div className={styles.selectedInfo}>
          {i18n.t<string>('productListOverview.labels.selectCount', { count: products.length })}
        </div>
        <div
          className={styles.toggleButton}
          onClick={toggleShowDetail}
        >
          <FontAwesomeIcon
            icon={showDetail ? faAngleDown : faAngleUp}
          />
        </div>
        {children}
        <div className={styles.removeAll} onClick={handleRemoveAll}>
          {i18n.t<string>('productListOverview.labels.removeAll')}
        </div>
      </div>
      <div className={seperationLineClass}/>
      <div className={overviewAreaClass}>
        <Slider
          prevIcon={<div className={styles.arrowLeft}/>}
          nextIcon={<div className={styles.arrowRight}/>}
          prevIconClassName={styles.arrowLeftContainer}
          nextIconClassName={styles.arrowRightContainer}
          fill={true}
          model={sliderModel}
        >
          {overview}
        </Slider>
      </div>
    </div>
  );
};
