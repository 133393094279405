export const rtbInventories = {
  os: {
    name: 'os',
    title: 'limitation.labels.os'
  },
  device: {
    name: 'device',
    title: 'limitation.labels.device',
    i18nPrefix: 'limitationOptions.labels.device'
  },
  spaceType: {
    name: 'spaceType',
    title: 'limitation.labels.spaceType',
    i18nPrefix: 'limitationOptions.labels.spaceType'
  },
  momoSegment: {
    name: 'momoSegment',
    title: 'limitation.labels.momoSegment'
  }
};
