import { FormConfig } from 'components/form/FormConfig';
import React, { useCallback, useEffect } from 'react';
import i18n from 'i18n';
import { Form } from 'components/form/Form';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PmpFormType, PmpType, TargetType } from 'core/pmp/Pmp';
import { SelectOptions } from 'components/commonType';
import { Dayparts } from 'components/Dayparts/Dayparts';
import moment from 'moment';
import { camelCase, get, isUndefined, omitBy } from 'lodash';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { useCoreContext } from 'contexts/coreContext';
import { usePmpDurationValidator } from './PmpDurationValidator';
import { Trans } from 'react-i18next';
// import { LabelValueColumn } from 'components/TableColumn/LabelValueColumn';

export type PmpFormProps = {
  pmpFormData: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  onSubmit: (pmp: PmpFormType) => void;
};

const pmpTypeOptions = createSelectOptionsFromEnum(PmpType, 'pmp.pmpTypes.', [], camelCase);
// const targetTypeOptions = createSelectOptionsFromEnum(TargetType, 'pmp.targetTypes.', [], camelCase);
const pmpAgency = process.env.REACT_APP_PMP_AGENCY;
const agencyOptions = pmpAgency ? [{ value: +pmpAgency, label: pmpAgency }] : [];

export const PmpForm: React.FC<PmpFormProps> = ({
  pmpFormData,
  spaceOptions,
  onSubmit
}) => {

  const core = useCoreContext();
  const currency = get(core, 'accountManager.localeMeta.currency', 'NTD');

  const renderDayPart = useCallback((props) => <Dayparts {...props} />, []);

  const {
    loading,
    fetchPmpWithinDuration,
    validatePmpDuration
  } = usePmpDurationValidator();

  const initPmpStartTime = pmpFormData?.startTime;
  const initPmpEndTime = pmpFormData?.endTime;
  useEffect(() => {
    if (!initPmpStartTime || !initPmpEndTime) return;
    fetchPmpWithinDuration(initPmpStartTime, initPmpEndTime);
  }, [initPmpStartTime, initPmpEndTime, fetchPmpWithinDuration]);

  const validateForm = useCallback((values: PmpFormType) => {

    const validateDuration = (startTime: string, endTime: string) => {
      let start = moment(startTime);
      let end = moment(endTime);
      const diffDays = end.diff(start, 'days') + 1;
      if (diffDays > 92) {
        return i18n.t<string>('pmpForm.errors.moreThanDays', { days: 92 });
      }

      const now = moment();
      if (start.add(1, 'hours').isBefore(now)) {
        return i18n.t<string>('pmpForm.errors.createBeforeNow');
      }

      if (end.isBefore(now)) {
        return i18n.t<string>('pmpForm.errors.endDayBeforeNow');
      }
      return undefined;
    };

    const sponsorshipConflictData = validatePmpDuration(values);
    const spaceError = sponsorshipConflictData ? sponsorshipConflictData.spaces.map(space => {
      const target = spaceOptions.find(option => option.value === space);
      return target ? target.label : '';
    }) : undefined;
    const daypartError = sponsorshipConflictData ? sponsorshipConflictData.daypart : undefined;
    const durationError = sponsorshipConflictData ? sponsorshipConflictData.duration : undefined;

    return omitBy({
      name: validateEmpty(values.name),
      duration: validateDuration(values.startTime, values.endTime) ||
        (durationError ?
          `${i18n.t<string>('pmpForm.errors.sponsorshipConflict')}: ${durationError.join(', ')}` :
          undefined
        ),
      pmpType: validateEmpty(values.pmpType),
      targetType: values.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? validateEmpty(values.targetType) : undefined,
      targetQuantity: values.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? validateMinimum(values.targetQuantity, 0) : undefined,
      budget: validateMinimum(values.budget, 1),
      adAgencyId: validateEmpty(values.adAgencyId),
      space: validateEmpty(values.space) ||
        (spaceError ? `${i18n.t<string>('pmpForm.errors.sponsorshipConflict')}: ${spaceError.join(', ')}` : undefined),
      dayPart: daypartError ? `${i18n.t<string>('pmpForm.errors.sponsorshipConflict')}: ${daypartError.join('、 ')}` : undefined
    }, isUndefined);
  }, [spaceOptions, validatePmpDuration]);

  const renderForm = useCallback((formikProps: FormikProps<PmpFormType>) => {
    const { values, setValues, setFieldValue, setFieldTouched } = formikProps;
    const pmpType = values.pmpType;
    const onPmpTypeChange = (type: PmpType) => {
      setValues({
        ...values,
        pmpType: type,
        targetType: type === PmpType.PROGRAMMATIC_GUARANTEED ? TargetType.IMPRESSION : undefined,
        targetQuantity: type === PmpType.PROGRAMMATIC_GUARANTEED ? 0 : undefined
      });
    };

    const onDurationChanged = () => {
      setFieldTouched('duration', true);
      setFieldTouched('dayPart', true);
    };

    const onDurationPopOverClosed = async () => {
      await fetchPmpWithinDuration(values.startTime, values.endTime);
      onDurationChanged();
    };

    const onDaypartSwitchChanged = (checked: boolean) => {
      !checked && setFieldValue('dayPart', undefined);
    };

    const daypartEnabled = values.dayPart?.enabled;

    let start = moment(values.startTime);
    let end = moment(values.endTime);
    const days = end.diff(start, 'days') + 1;

    const formConfig = new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikInput({
              label: i18n.t<string>('pmp.labels.name'),
              name: 'name'
            })
            .addFormikDateRangePicker({
              label: i18n.t<string>('pmp.labels.duration'),
              name: 'duration',
              startDateFormikName: 'startTime',
              endDateFormikName: 'endTime',
              showTimePicker: true,
              validateOnPopOverClose: true,
              onPopOverClosed: onDurationPopOverClosed,
              hint: (
                <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
                  Total <span className='text-dark'><>{{ days }} days</></span>
                </Trans>
              )
            })
            .addFormikSwitch({
              label: i18n.t<string>('pmp.labels.dayPart'),
              name: 'dayPart.enabled',
              onChange: onDaypartSwitchChanged,
              hoverHint: daypartEnabled ? undefined : i18n.t<string>('pmpForm.hints.dayPart')
            })
            .addFormikCustom({
              label: '',
              name: 'dayPart',
              onChange: onDurationChanged,
              render: renderDayPart
            }, !(values.dayPart?.enabled))
            .addFormikSelect({
              label: i18n.t<string>('pmp.labels.pmpType'),
              name: 'pmpType',
              simpleValue: true,
              options: pmpTypeOptions,
              onChange: onPmpTypeChange
            })
            // .addFormikSelect({
            //   label: i18n.t<string>('pmp.labels.targetType'),
            //   name: 'targetType',
            //   simpleValue: true,
            //   options: targetTypeOptions
            // }, pmpType === PmpType.SPONSORSHIP)
            .addFormikInput({
              label: i18n.t<string>('pmp.labels.targetQuantity'),
              name: 'targetQuantity',
              type: 'number'
            }, pmpType === PmpType.SPONSORSHIP)
            .addFormikInputGroup({
              label: i18n.t<string>('pmp.labels.budget'),
              name: 'budget',
              type: 'number',
              prefix: currency
            })
            .addFormikSelect({
              label: i18n.t<string>('pmp.labels.adAgencyId'),
              name: 'adAgencyId',
              isMulti: true,
              simpleValue: true,
              menuPlacement: 'auto',
              options: agencyOptions
            })
            .addFormikSelect({
              label: i18n.t<string>('pmp.labels.space'),
              name: 'space',
              isMulti: true,
              simpleValue: true,
              menuPlacement: 'auto',
              options: spaceOptions
            })
            .build()
        )
        .withTitle(i18n.t<string>('pmpSetupFlowPage.titles.formSection'))
        .build()
      )
      .build();

    const renderFormBtns = () => (
      <>
        <Form.SubmitButton>{i18n.t<string>('common.buttons.completeAndCheck')}</Form.SubmitButton>
        <Form.CancelButton />
      </>
    );
    return (
      <Form
        formikProps={formikProps}
        formConfig={formConfig}
        renderFormBtns={renderFormBtns}
      />
    );
  }, [currency, spaceOptions, renderDayPart, fetchPmpWithinDuration]);

  if (!pmpFormData) {
    return <LoadingIndicator/>;
  }

  return (
    <>
      {loading && <LoadingIndicator/>}
      <Formik
        initialValues={pmpFormData}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderForm}
      </Formik>
    </>
  );
};
