import React from 'react';
import styles from './status.module.scss';
import cx from 'classnames/bind';
import { CopyableOverlayTrigger } from 'components/CopyableOverlayTrigger/CopyableOverlayTrigger';

const classNames = cx.bind(styles);

export const Status: React.FC<{
  color?: string,
  label: string,
  renderExtraInfo?: Function,
  style?: React.CSSProperties
}> = ({
  color,
  label,
  style,
  renderExtraInfo
}) => {

  return (
    <>
      <CopyableOverlayTrigger
        placement='bottom'
        overlay={(
          renderExtraInfo ?
            renderExtraInfo() :
            undefined
        )}
      >
        <div
          className={classNames('status', color ? styles[color] : undefined, {
            pointer: !!renderExtraInfo
          })}
          style={style}
        >
          {label}
        </div>
      </CopyableOverlayTrigger>
    </>
  );
};
