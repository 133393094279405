import _ from 'lodash';
import client, { clientWithoutErrorHandler } from './RestClient';

import { Account, Actor, AccessToken, LocaleMeta, CreateAccountRequest, UpdateAccountRequest, AccountWithPagination } from 'core';
import { AxiosInstance } from 'axios';

export interface AccountWebService {
  getAccount (): Promise<Account>;
  getAccounts (): Promise<Array<Account>>;
  getAccountsWithPagination (page: number, size: number, sortField: string, sortOrder: string, search: string): Promise<AccountWithPagination>;
  fetchAccount (id: number): Promise<Account>;
  createAccount (request: CreateAccountRequest): Promise<number>;
  createAgencyAccount (agencyId: number | string, request: CreateAccountRequest): Promise<number>;
  createAdvertiserAccount (advertiserId: number | string, request: CreateAccountRequest): Promise<number>;
  updateAccount (request: UpdateAccountRequest): Promise<void>;
  login (username: string, password: string): Promise<Account>;
  locale (): Promise<LocaleMeta>;
  changePassword (currentPassword: string, password: string): Promise<void>;
  sudo (accountId: number): Promise<Account>;
  validateGuestToken (token: string): Promise<{
    userName: string,
    registerStatus: number
  }>;
  setUpPasswordFirstTime (token: string, password: string): Promise<void>;
  resendActivationEmail (accountId: number): Promise<void>;
  sendResetPasswordMail (email: string): Promise<void>;
}

function wrapAccessToken (json: any): AccessToken {
  return {
    token: _.get(json, 'token', ''),
    expires: _.get(json, 'expires', 0)
  };
}

function wrapActor (json: any): Actor {
  return {
    id: _.get(json, 'actorId'),
    role: _.get(json, 'role'),
    roleName: _.get(json, 'roleName'),
    actorType: _.get(json, 'companyActorType'),
    companyName: _.get(json, 'comName'),
    displayOrder: _.get(json, 'listingOrdered', 0),
    accessToken: wrapAccessToken(_.get(json, 'accessToken')),
    permissions: _.get(json, 'permissions'),
    agencyId: _.get(json, 'agencyId', null),
    advertiserId: _.get(json, 'advertiserId', null)
  };
}

function wrapAccount (json: any): Account {
  const actorList: Array<any> = _.get(json, 'actorList', []);
  return {
    id: _.get(json, 'accountId', _.get(json, 'account_id')),
    name: _.get(json, 'name'),
    email: _.get(json, 'email'),
    agencyId: _.get(json, 'agencyId', 0),
    companyName: _.get(json, 'companyName', ''),
    language: _.get(json, 'language', 'zh_TW'),
    isAdmin: _.get(json, 'isAdmin', _.get(json, 'admin', false)),
    activated: _.get(json, 'activated', true),
    status: _.get(json, 'status'),
    actors: actorList.map(json => wrapActor(json)).sort((a, b) => a.displayOrder - b.displayOrder)
  };
}

export class RestfulAccountWebService implements AccountWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAccount (): Promise<Account> {
    const response = await this.restClient.get('/v2/accounts/me');
    return wrapAccount(response.data);
  }

  async getAccounts (): Promise<Array<Account>> {
    const response = await this.restClient.get('/v2/accounts');
    return _.defaultTo(response.data, []).flatMap((json: any) => wrapAccount(json));
  }

  async getAccountsWithPagination (page: number, size: number, sortField: string, sortOrder: string, search: string): Promise<AccountWithPagination> {
    let url = `/v2/accounts-with-pagination?page=${page}&size=${size}&sort=${sortField},${sortOrder}`;
    if (search !== '') {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    const response = await this.restClient.get(url);
    const records = _.defaultTo(response.data.records, []).flatMap((json: any) => wrapAccount(json));
    return {
      pagination: response.data.pagination,
      accounts: records
    };
  }

  async fetchAccount (id: number): Promise<Account> {
    const response = await this.restClient.get(`/v2/accounts/${id}`);
    return wrapAccount(response.data);
  }

  async createAccount (request: CreateAccountRequest): Promise<number> {
    const response = await this.restClient.post('/v2/accounts', {
      ...request,
      agencyId: request.agencyId ? request.agencyId : 0
    });
    return response.data.accountId;
  }

  async createAgencyAccount (agencyId: number | string, request: CreateAccountRequest): Promise<number> {
    const response = await this.restClient.post(`/v2/agencies/${agencyId}/accounts`, request);
    return response.data.accountId;
  }
  async createAdvertiserAccount (advertiserId: number | string, request: CreateAccountRequest): Promise<number> {
    const response = await this.restClient.post(`/v2/advertisers/${advertiserId}/accounts`, request);
    return response.data.accountId;
  }

  async sudo (accountId: number): Promise<Account> {
    const response = await this.restClient.post(`/v2/accounts/${accountId}/sudo`);
    return wrapAccount(response.data);
  }

  updateAccount (request: UpdateAccountRequest): Promise<void> {
    return this.restClient.put('/v2/account', {
      name: request.name,
      accountId: request.id,
      isAdmin: request.isAdmin,
      state: request.activated ? 1 : 0
    });
  }

  async locale (): Promise<LocaleMeta> {
    const response = await this.restClient.get('/v2/locale/me');
    return {
      ...response.data,
      timezone: _.get(response.data, 'timezone'),
      currency: _.get(response.data, 'currency'),
      language: _.get(response.data, 'language'),
      maxOrderBudget: _.get(response.data, 'maxOrderBudget'),
      addonFeatures: _.get(response.data, 'addonFeatures'),
      selfServe: _.get(response.data, 'selfServe')
    };
  }

  async login (username: string, password: string): Promise<Account> {
    const response = await this.restClient.post('/v2/login', {
      username,
      password
    });
    return wrapAccount(response.data);
  }

  async changePassword (currentPassword: string, password: string): Promise<void> {
    await this.restClient.post('/account/change_password', {
      currentPassword,
      password
    });
  }

  async validateGuestToken (token: string): Promise<{
    userName: string,
    registerStatus: number
  }> {
    const response = await clientWithoutErrorHandler.get(`/v2/accounts/token/${token}`);
    return response.data;
  }

  async setUpPasswordFirstTime (token: string, password: string): Promise<void> {
    return this.restClient.post('/reset-password', {
      token,
      password
    });
  }

  async resendActivationEmail (accountId: number): Promise<void> {
    return this.restClient.get(`/v2/accounts/${accountId}/resend-activation-email`);
  }

  async sendResetPasswordMail (email: string): Promise<void> {
    return this.restClient.get(`/password/forget?email=${email}`);
  }
}
