import { Pagination } from 'core/pagination/Pagination';

export type Retail = {
  id: string;
  name: string;
};

export type ProductWithPagination = {
  pagination: Pagination,
  products: Product[]
};

export type Product = {
  productId: string;
  retailId: string;
  name: string;
  category: string;
  salePrice: number;
  imgLink: string;
  multipack: number;
  vendorNumber?: string;
  adServingUrl?: string;
  tags: ProductTag[];
};

export type SmartGoodsHotKeywrods = {
  suggestKeywords: SuggestKeyword[],
  bidCap: number,
  bidFloor: number,
  bidPrice: number,
  bidMedian: number
};

export type SuggestKeyword = {
  suggestKeyword: string,
  pr: number,
  cpc?: number
};

export type GoodsHotKeywords = {
  suggestKeywords: SuggestKeyword[],
  bidCap: number,
  bidFloor: number,
  bidPrice: number,
  bidMedian: number
};

export enum ProductTag {
  HOT = '熱門商品',
  TRENDING = '全站熱搜',
  ROAS = 'ROAS最佳',
  HCP = '高點擊商品'
}
