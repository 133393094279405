import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel
} from './RtbCampaignBasicFormModel';

export interface UnknowAdTypeFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractUnknowAdTypeFormModel extends DefaultRtbCampaignBasicFormModel
  implements UnknowAdTypeFormModel {

  async init () {}
}

export class EditUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('edit', flowModel);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('create', flowModel);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
