import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { ProductNativeFormProps } from './ProductNativeFormModel';
import { connect, FormikContextType } from 'formik';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import { useSelectProductField } from './useSelectProductField';

export const ProductNativeForm: React.FunctionComponent<ProductNativeFormProps & { formik: FormikContextType<any> }> = ({
  getInitFormConfig,
  basicFields,
  ...props
}) => {

  const revealedBasicFields = useMemo(() => {
    const nameField = basicFields.find((field) => field.props.name === 'name');
    if (nameField) {
      nameField.props.validate = undefined;
    }
    const creativeTypeField = basicFields.find((field) => field.props.name === 'creativeType');
    const needShowCreativeType = creativeTypeField && creativeTypeField.props.options.length > 1;
    let hideFileds = ['name'];
    if (!needShowCreativeType) {
      hideFileds.push('creativeType');
    }
    return basicFields.filter((field) => {
      return !hideFileds.includes(field.props.name);
    });
  }, [basicFields]);
  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  const { flowPageModel, products } = props.model;

  const {
    selectProductsFieldContainerClass,
    renderProductsField
  } = useSelectProductField(advertiserId, props.model, false, false);

  useEffect(() => {
    if (flowPageModel.type === 'create') {
      setFieldValue('typeProperties.products', products);
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...revealedBasicFields])
            .addFormikCustom({
              name: 'typeProperties.products',
              label: i18n.t<string>('creativeSetupFlow.labels.products'),
              render: renderProductsField,
              formGroupClassName: selectProductsFieldContainerClass
            }, flowPageModel.type === 'edit')
            .addFormikLabel({
              name: 'typeProperties.product.name',
              label: i18n.t<string>('creativeSetupFlow.labels.product')
            }, flowPageModel.type === 'create')
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    flowPageModel.type,
    products,
    revealedBasicFields,
    selectProductsFieldContainerClass,
    setFieldValue,
    renderProductsField
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ProductNativeForm);
