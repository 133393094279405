import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import imageStyles from './imageForm.module.scss';
import styles from './richProductNativeForm.module.scss';
import { RichProductNativeFormProps } from './RichProductNativeFormModel';
import { connect, FormikContextType } from 'formik';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import { partial } from 'lodash';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';
import { useSelectProductField } from './useSelectProductField';

export const RichProductNativeForm: React.FunctionComponent<RichProductNativeFormProps & { formik: FormikContextType<any> }> = ({
  basicFields,
  hintModalData,
  renderHintModal,
  setHintModalData,
  getInitFormConfig,
  ...props
}) => {

  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());
  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);

  const { flowPageModel, products } = props.model;
  const { validTypes, validateImage } = props.model.imageFormModel;

  const {
    selectProductsFieldContainerClass,
    renderProductsField
  } = useSelectProductField(advertiserId, props.model, false, false);

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        sizeList: [{ width: 960, height: 320 }, { width: 2440, height: 250 }],
        customStyles: styles,
        closeHint: partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    if (flowPageModel.type === 'create') {
      setFieldValue('typeProperties.products', products);
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...basicFields])
            .addFormikFileInput({
              type: 'image',
              className: imageStyles.imageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              validTypes,
              fieldContentWidth: 318,
              hints: [],
              permanentHint: (
                <div>
                  <span className={imageStyles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.imageTypeHint')}
                  </span>
                  <span className={imageStyles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })}
                  </span>
                  <div
                    className={imageStyles.sizeInstructionBtn}
                    onClick={sizeInstructionModalData ?
                      closeSizeInstruction : showSizeInstruction
                    }
                  >
                    {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                    <FontAwesomeIcon icon={faInfoCircle}/>
                  </div>
                </div>
              ),
              validate: validateImage
            })
            .addFormikCustom({
              name: 'typeProperties.products',
              label: i18n.t<string>('creativeSetupFlow.labels.products'),
              render: renderProductsField,
              formGroupClassName: selectProductsFieldContainerClass
            }, flowPageModel.type === 'edit')
            .addFormikLabel({
              name: 'typeProperties.product.name',
              label: i18n.t<string>('creativeSetupFlow.labels.product')
            }, flowPageModel.type === 'create')
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      ).build()
    );
  }, [
    flowPageModel.type,
    products,
    basicFields,
    validTypes,
    selectProductsFieldContainerClass,
    validateImage,
    setFieldValue,
    renderHintModal,
    renderProductsField,
    showSizeInstruction,
    closeSizeInstruction,
    sizeInstructionModalData
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(RichProductNativeForm);
