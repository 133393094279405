import {
  RdpWebService,
  RestfulRdpWebService
} from 'ws/RdpWebService';
import { Retail, Product, ProductWithPagination } from 'core/product/Product';
import { Pageable } from 'ws/Pageable';
import moment from 'moment';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';

export interface RdpManager {
  getRetailsOfAdvertiser (agencyId: number, advertiserId: number): Promise<Retail[]>;
  getRetailProducts (retailId: number | string, pageable: Pageable, queries?: any): Promise<ProductWithPagination>;
  getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]>;
  getProductName (productId: string): Promise<string | null>;
  getCategories (): Promise<object>;
  getAdServingUrl (productId: string): Promise<string>;
}

export class DefaultRdpManager implements RdpManager {
  webService: RdpWebService;

  constructor (webService: RdpWebService = new RestfulRdpWebService()) {
    this.webService = webService;
  }

  async getRetailsOfAdvertiser (agencyId: number, advertiserId: number): Promise<Retail[]> {
    const retails = await this.webService.getRetailsOfAdvertiser(agencyId, advertiserId);
    return retails.map(retail => {
      const result = {
        id: retail.id,
        name: retail.name
      };
      generateI18nOfSelectOptions(({
        label: result.name,
        value: result.id
      }), 'retailers');
      return result;
    });
  }

  async getRetailProducts (retailId, pageable: Pageable, queries: any = {}): Promise<ProductWithPagination> {
    return this.webService.getRetailProducts(retailId, pageable, queries);
  }

  async getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]> {
    return this.webService.getProductCaches(agencyId, advertiser, productIds);
  }

  async getProductName (productId: string): Promise<string | null> {
    return this.webService.getProductName(productId);
  }

  async getCategories (): Promise<object> {
    const date = moment().format('YYYY-MM-DD');
    if (!process.env.REACT_APP_PRODUCT_CATEGORY_PATH) {
      return {};
    }
    const response = await fetch(`${process.env.REACT_APP_PRODUCT_CATEGORY_PATH}?t=${date}`);
    const categories = await response.json();
    return categories;
  }

  async getAdServingUrl (productId: string): Promise<string> {
    return this.webService.getAdServingUrl(productId);
  }
}
