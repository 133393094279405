import { RoleNames } from 'core';
import { AppMenuModel, DefaultAppMenuModel, DefaultAppMenuItemModel, AppMenuItemType } from 'containers/App/AppMenuBar';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { hasRoles, notRoles, addOnEnabled } from 'core/permission/PermissionDSL';
import { faChartBar, faCheckSquare, faBriefcase, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import productMenuIcon from 'assets/product-menu-icon.svg';
import _ from 'lodash';
import { keywordBidPriceItemTitle } from './menuItemStyledTitle';

export default function defaultMenus (): Array<AppMenuModel> {

  const { sysAdmin, agencyAdmin, agencyReport, adsReport, agencyManager } = RoleNames;
  const HomeMenuItems = [
    new DefaultAppMenuItemModel('appMenus.home.items.intelligentWarning', '/index', notRoles(sysAdmin, adsReport))
  ];

  const HomeMenu = new DefaultAppMenuModel('appMenus.home.title', HomeMenuItems, faHome);

  const reportMenuItems = [
    new DefaultAppMenuItemModel('appMenus.report.items.performance', '/reports/performance'),
    new DefaultAppMenuItemModel('appMenus.report.items.campaignDashboard', '/reports/campaign-dashboard', hasRoles(sysAdmin).or(hasRoles(agencyAdmin).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD)))),
    new DefaultAppMenuItemModel('appMenus.report.items.storedValue', '/stored-value', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.STORED_VALUE)))),
    new DefaultAppMenuItemModel('appMenus.report.items.tenmaxReconciliation', '/reports/tenmax-reconciliation', hasRoles(sysAdmin))

  ];
  const reportMenu = new DefaultAppMenuModel('appMenus.report.title', reportMenuItems, faChartBar);

  const companyMenuItems = [
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.agencies', '/agencies', hasRoles(sysAdmin, agencyAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.advertisers', '/advertisers', hasRoles(sysAdmin, agencyAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.accounts', '/accounts', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.pmp', '/pmp', hasRoles(sysAdmin))
  ];
  const companyMenu = new DefaultAppMenuModel('appMenus.comapnyManagement.title', companyMenuItems, faBriefcase);

  const retailMomoMenuItems = [
    new DefaultAppMenuItemModel('appMenus.retailMomo.items.products', '/products', notRoles(sysAdmin, adsReport).and(addOnEnabled(ADDONFEATURE.CHANNEL.RTB)))
  ];

  const retailMomoMenu = new DefaultAppMenuModel('appMenus.retailMomo.title', retailMomoMenuItems, productMenuIcon);

  const systemMenuItems = [
    new DefaultAppMenuItemModel('appMenus.system.items.intro', 'https://momoads.notion.site/momo-Ads-b105b3a2d9c74fcab72ee5dea7e382f2', null, AppMenuItemType.OPEN_NEW),
    new DefaultAppMenuItemModel(keywordBidPriceItemTitle(), 'https://kps.momoshop.com.tw/query', null, AppMenuItemType.OPEN_NEW)
  ];
  const systemMenu = new DefaultAppMenuModel('appMenus.system.title', systemMenuItems, faCog);

  const advertisementMenuItems = [
    new DefaultAppMenuItemModel('appMenus.ads.items.orders', '/orders', notRoles(sysAdmin, adsReport)),
    new DefaultAppMenuItemModel('appMenus.ads.items.creatives', '/creatives', notRoles(sysAdmin, agencyReport, adsReport))
  ];
  const advertisementMenu = new DefaultAppMenuModel('appMenus.ads.title', advertisementMenuItems, faCheckSquare);

  const menus = _.compact([
    HomeMenu,
    advertisementMenu,
    retailMomoMenu,
    reportMenu,
    companyMenu,
    systemMenu
  ]);
  return menus;
}
