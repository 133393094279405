import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { AdType, DeliverType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';

export type SmartCampaignConfig = {
  id: string;
  name: string;
  updateTime: string;
  priority: number;
  vendorNumber: string;
  startTime: string;
  endTime: string;
  optimize: L2ObjectOptimizationGoal;
  budget: number;
  dailyBudget: number;
  deliverType: DeliverType;
  planType: RtbCampaignPlanType;
  bidPrice: number;
  adType: AdType;
  productIds: string[];
  searchKeywords: string[];
  campaignId?: number;
  useTime?: string;
};

export const SMART_CONFIG_PLAN_TYPE_MAP = {
  [RtbCampaignPlanType.RS]: {
    [L2ObjectOptimizationGoal.CLICKS]: RtbCampaignPlanType.RS_CPC,
    [L2ObjectOptimizationGoal.IMPRESSIONS]: RtbCampaignPlanType.RS_CPM
  }
};
