import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface IntelligentWarningWebService {
  getSmartReminder (): Promise<object>;
}

export class RestfulIntelligentWarningWebService implements IntelligentWarningWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getSmartReminder (): Promise<object> {
    const response = await this.restClient.get(`/smartReminder/search`);
    return response.data;
  }
}
