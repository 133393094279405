import { Product } from 'core/product/Product';
import React from 'react';
import { ProductFilter } from './ProductFilter';
import { SmartCampaignConfig } from 'core/configuration/SmartCampaignConfig';

export type ProductListDataContextType = {
  selectedProducts: Product[];
  smartCampaignConfig?: SmartCampaignConfig;
  handleSelectedProducts: (fn: (selectedProducts: Product[]) => Product[]) => void;
  handleFilterChanged: (filter: ProductFilter) => void;
};

export const ProductListDataContext = React.createContext<ProductListDataContextType>({
  selectedProducts: [],
  smartCampaignConfig: undefined,
  handleSelectedProducts: () => {
   // This is intentionally left blank
  },
  handleFilterChanged: () => {
    // This is intentionally left blank
  }
});
