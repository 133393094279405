import { AdType, DailyBudgetPlan, DeliverType, RtbCampaignPlanType, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { OPERATE } from 'enum/Operate';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { omitBy, isEmpty, isNil, defaultTo } from 'lodash';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import i18n from 'i18n';

export interface SmartKeywordFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractSmartKeywordFormModel extends DefaultRtbCampaignBasicFormModel implements SmartKeywordFormModel {

  private bidPriceData?: {max: number, min: number};

  constructor (mode: 'create' | 'edit', flowModel: RtbCampaignSetupFlowPageModel) {
    super(mode, flowModel);
    this.defaultDeliverType = DeliverType.ACCELERATED;
  }

  get campaignAdType (): AdType {
    return AdType.SMART_KEYWORD;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return false;
  }

  get showTags (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [OPERATE.INCLUDE],
      notNeed: undefined,
      other: undefined
    };
  }

  async init (goLast: () => void) {
    const limitationModel = this.flowModel.getLimitationModel();
    const inited = !isNil(this.flowModel.state.campaign.basic.bidPrice);
    if (inited) {
      return;
    }

    this.updateState(this.modelDailyBudgetType, true);
    try {
      const smartGoodsHotKeywords = await this.flowModel.adRequestSourceManager.getSmartGoodsHotKeywords(defaultTo(this.flowModel.state.campaign.products, []).map(product => product.productId));
      if (smartGoodsHotKeywords.suggestKeywords.length === 0) {
        this.setModalData({
          title: i18n.t('common.warning'),
          message: i18n.t('campaignSetupFlow.messages.noSearchKeywords'),
          primaryButtonData: {
            title: i18n.t('common.buttons.ok'),
            callback: () => {
              this.closeModal();
              goLast();
            }
          }
        });
        return;
      }
      limitationModel.setLimitation(
        'include',
        'searchKeywords',
        smartGoodsHotKeywords.suggestKeywords.map(keyword => ({ value: keyword.suggestKeyword, label: keyword.suggestKeyword }))
      );

      const bidCap = this.getBidCap(this.formikValue.optimize);
      const bidFloor = this.getBidFloor(this.formikValue.optimize);
      this.bidPriceData = {
        max: Math.max(Math.min(smartGoodsHotKeywords.bidCap, bidCap), bidFloor),
        min: Math.max(Math.min(smartGoodsHotKeywords.bidMedian, bidCap), bidFloor)
      };
      let bidPrice = smartGoodsHotKeywords.bidPrice;
      bidPrice = Math.max(Math.min(bidPrice, bidCap), bidFloor);
      this.flowModel.setCampaign({
        ...this.flowModel.state.campaign,
        basic: {
          ...this.formikValue,
          bidPrice
        },
        limitations: limitationModel.limitationValue
      });
    } catch (e) {
      console.error(e);
    }
    this.updateState(this.modelDailyBudgetType, false);
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    return omitBy({
      ...optionsMap,
      [L1ObjectObjective.TRAFFIC]: {
        [RtbCampaignPlanType.RS_CPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ]
      }
    }, isEmpty);
  }

  setupDefaultCampaign (campaign: any) {
    super.setupDefaultCampaign(campaign);
    campaign.dailyTargetBudget = 0;
  }

  getBidPriceRange (optimize: RtbOptimize): {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  } {
    return {
      min: this.getBidFloor(optimize),
      recommend: this.bidPriceData ? {
        min: this.bidPriceData.min,
        max: this.bidPriceData.max
      } : {
        min: 0,
        max: 0
      }
    };
  }
}

export class EditSmartKeywordFormModel extends AbstractSmartKeywordFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('edit', flowModel);
  }

  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateSmartKeywordFormModel extends AbstractSmartKeywordFormModel {

  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('create', flowModel);
    this.modelDailyBudgetType = DailyBudgetPlan.DAILY;
  }

  get canEditBudgetPlan (): boolean {
    return false;
  }

  get canEditPriceModel (): boolean {
    return true;
  }
}
