import { CreativeType } from 'core/creative/Creative';
import { ImageCreativeListDataProvider } from './ImageCreativeListDataProvider';
import { ProductNativeCreativeListDataProvider } from './ProductNativeListDataProvider';
import { RichProductNativeCreativeListDataProvider } from './RichProductNativeListDataProvider';

export function getDataProvider (creativeType: CreativeType, model: any) {

  switch (creativeType) {
    case CreativeType.IMAGE:
      return new ImageCreativeListDataProvider(model);
    case CreativeType.PRODUCT_NATIVE:
      return new ProductNativeCreativeListDataProvider(model);
    case CreativeType.RICH_PRODUCT_NATIVE:
      return new RichProductNativeCreativeListDataProvider(model);
    case CreativeType.SHOP:
      return new ImageCreativeListDataProvider(model);
    default:
      return new ImageCreativeListDataProvider(model);
  }
}
