import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useMemo, useState } from 'react';
import formatters from './listFormatters';
import styles from './storedValueList.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultStoredValueManager } from 'core/storedValue/StoredValueManager';
import { StoredValueAgencyRecord, StoredValueAgencyRecordsWithPagination, StoredValueSummary } from 'core/storedValue/StoredValueLog';
import { debounce } from 'lodash';

export enum StoredValueListColumns {
  ID = 'adAgencyId',
  VENDOR_NUMBER = 'vendorNumber',
  USED = 'usedAmount',
  TOTAL = 'totalAmount',
  REMAIN = 'remainingAmount'
}

export type StoredValueListState = {
  readonly searchString: string;
  readonly agencies: StoredValueAgencyRecord[];
  readonly sortField: StoredValueListColumns,
  readonly sortOrder: 'desc' | 'asc',
  readonly page: number,
  readonly size: number,
  readonly totalCount: number
};

const defaultStoredValueManager = new DefaultStoredValueManager();

export const useStoredValueListModel = (
  storedValueManager = defaultStoredValueManager
) => {

  const [state, setState] = useState({
    searchString: '',
    agencies: [],
    sortField: StoredValueListColumns.ID,
    sortOrder: 'desc',
    page: 1,
    size: 10,
    totalCount: 0
  } as StoredValueListState);

  const { loading, callAPIs } = useCallAPI();

  const initData = useCallback(async () => {
    callAPIs([
      async () => storedValueManager.getStoredValueAgencyRecords(state.page, state.sortField, state.sortOrder, state.searchString)
    ], ({ records, pagination }: StoredValueAgencyRecordsWithPagination) => {
      setState(prevState => ({
        ...prevState,
        agencies: records,
        page: pagination.page,
        size: pagination.size,
        totalCount: pagination.totalCount
      }));
    });
  }, [storedValueManager, state.page, state.sortField, state.sortOrder, state.searchString, callAPIs]);

  useEffect(() => {
    initData();
  }, [initData]);

  const debouncedHandleSearch = useMemo(() => debounce((searchString: string) => setState(prev => ({ ...prev, searchString, page: 1 })), 1000), []);
  const onHandleSearch = useCallback((searchString: string): void => {
    if (searchString === '') {
      debouncedHandleSearch && debouncedHandleSearch.cancel();
      setState(prev => ({ ...prev, searchString, page: 1 }));
    } else {
      debouncedHandleSearch(searchString);
    }
  }, [debouncedHandleSearch]);

  const onSort = useCallback((field, direction) => {
    if (field === state.sortField && direction === state.sortOrder) {
      return;
    }
    setState(prevState => ({
      ...prevState,
      page: 1,
      sortField: field,
      sortOrder: direction
    }));
  }, [state.sortField, state.sortOrder]);

  const onChangePage = useCallback((page) => {
    if (page === state.page) {
      return;
    }
    setState(prevState => ({
      ...prevState,
      page
    }));
  }, [state.page]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `storedValueList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const priceFormatter = (value, rowData: StoredValueSummary) => formatPriceWithCurrency(rowData.currency, value ? value : 0);
  const columns = [
    renderColumn(columnDefinition(StoredValueListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.VENDOR_NUMBER)),
    renderColumn(columnDefinition(StoredValueListColumns.USED), priceFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.TOTAL), priceFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.REMAIN), priceFormatter)
  ];

  return {
    state,
    columns,
    loading,
    onHandleSearch,
    onSort,
    onChangePage
  };
};
