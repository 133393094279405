import { getNameIdColumn, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { SmartCampaignConfig } from 'core/configuration/SmartCampaignConfig';
import _ from 'lodash';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCoreContext } from 'contexts/coreContext';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { Product } from 'core/product/Product';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import moment from 'moment';

export enum SmartCampaignConfigListColumns {
  NAME = 'name',
  UPDATETIME = 'updateTime',
  BUDGET = 'budget',
  ADTYPE = 'adType',
  PRIORITY = 'priority',
  CAMPAIGN_ID = 'campaignId',
  USE_TIME = 'useTime',
  START_TIME = 'startTime'
}

const defaultRdpManager: RdpManager = new DefaultRdpManager();
const defaultRtbCampaignManager: RtbCampaignManager = new DefaultRtbCampaignManager();

export const useSmartCampaignConfigListModel = (
  advertiserId: number,
  defaultConfigs?: SmartCampaignConfig[],
  singleSelect: boolean = true
) => {

  const core = useCoreContext();
  const agencyId = _.get(core, 'accountManager.localeMeta.agencyId', 0);
  const vendorNumber = _.get(core, 'accountManager.localeMeta.vendorNumber', '');
  const shouldFetchConfigs = _.isNil(defaultConfigs);
  const [smartCampaignConfigs, setSmartCampaignConfigs] = useState<SmartCampaignConfig[]>(_.defaultTo(defaultConfigs, []));
  const [selectedConfigs, setSelectedConfigs] = useState<string[]>([]);
  const [selectedConfigProducts, setSelectedConfigProducts] = useState<Product[]>([]);
  const [finished, setFinished] = useState<boolean>(false);

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const fetchSmartCampaignConfigs = useCallback(async (vendorNumber: string) => {
    if (_.isNil(vendorNumber)) {
      setSmartCampaignConfigs([]);
      return;
    }
    callAPIs(
      [defaultRtbCampaignManager.getSmartCampaignConfigs.bind(defaultRtbCampaignManager, vendorNumber)],
      (configs: SmartCampaignConfig[]) => {
        setSmartCampaignConfigs(configs);
      }
    );
  }, [callAPIs]);

  useEffect(() => {
    shouldFetchConfigs && fetchSmartCampaignConfigs(vendorNumber);
  }, [vendorNumber, shouldFetchConfigs, fetchSmartCampaignConfigs]);

  const filteredConfigs: SmartCampaignConfig[] = useMemo(() => {
    return _.orderBy(
      _.filter(smartCampaignConfigs, (config) => moment(config.startTime).isAfter(moment().startOf('day').subtract(1, 'second'))),
      [
        SmartCampaignConfigListColumns.USE_TIME,
        SmartCampaignConfigListColumns.CAMPAIGN_ID,
        SmartCampaignConfigListColumns.UPDATETIME,
        SmartCampaignConfigListColumns.PRIORITY,
        SmartCampaignConfigListColumns.START_TIME
      ], [
        'desc',
        'asc',
        'desc',
        'asc',
        'asc'
      ]);
  }, [smartCampaignConfigs]);

  const columns = useMemo(() => (_.compact([
    getNameIdColumn(sortableColumn(SmartCampaignConfigListColumns.NAME, i18n.t<string>('smartCampaignConfigList.headers.name')), undefined, (config: SmartCampaignConfig) => _.isNil(config.campaignId) && _.isNil(config.useTime)),
    renderColumn(sortableColumn(SmartCampaignConfigListColumns.UPDATETIME, i18n.t<string>('smartCampaignConfigList.headers.updateTime'))),
    renderColumn(sortableColumn(SmartCampaignConfigListColumns.BUDGET, i18n.t<string>('smartCampaignConfigList.headers.budget'))),
    renderColumn(sortableColumn(SmartCampaignConfigListColumns.ADTYPE, i18n.t<string>('smartCampaignConfigList.headers.adType')), (adType: any) => {
      if (adType in AdType) {
        return i18n.t<string>(`campaignList.labels.adType${_.upperFirst(_.camelCase(adType))}`);
      }
      return i18n.t<string>('campaignList.labels.adTypeEmpty');
    }),
    renderColumn(sortableColumn(SmartCampaignConfigListColumns.CAMPAIGN_ID, i18n.t<string>('smartCampaignConfigList.headers.campaignId')), (campaignId: any) => _.defaultTo(campaignId, 'N/A')),
    renderColumn(sortableColumn(SmartCampaignConfigListColumns.USE_TIME, i18n.t<string>('smartCampaignConfigList.headers.useTime')), (useTime: any) => _.defaultTo(useTime, 'N/A'))
  ])), []);

  const onHandleSelect = useCallback((id: string | number, select: boolean) => {
    if (singleSelect) {
      setSelectedConfigs(select ? [id.toString()] : []);
      return;
    }
    setSelectedConfigs(prevConfigs => select ? _.uniq([...prevConfigs, id.toString()]) : prevConfigs.filter(configId => configId.toString() !== id.toString()));
  }, [singleSelect]);

  const onEnableSelect = useCallback((id: string | number) => {
    const config = filteredConfigs.find(config => config.id.toString() === id.toString());
    if (_.isEmpty(config)) {
      return false;
    }
    if (!_.isNil(config.campaignId) && !_.isNil(config.useTime)) {
      return false;
    }
    return true;
  }, [filteredConfigs]);

  const onFetchSelectedConfigProducts = useCallback(async () => {
    if (_.isNil(agencyId || _.isNil(advertiserId))) {
      setSelectedConfigProducts([]);
      setFinished(true);
      return;
    }

    const config = filteredConfigs.find(config => config.id.toString() === selectedConfigs[0].toString());
    if (_.isEmpty(config) || config.adType !== AdType.KEYWORD) {
      setSelectedConfigProducts([]);
      setFinished(true);
      return;
    }

    const productIds: string[] = config ? config.productIds : [];
    callAPIs(
      [defaultRdpManager.getProductCaches.bind(defaultRdpManager, agencyId, advertiserId, productIds)],
      (products: Product[]) => {
        const validProducts: Product[] = config
          ? products.filter(product => product.vendorNumber && product.vendorNumber === config.vendorNumber && product.vendorNumber === vendorNumber)
          : [];
        setSelectedConfigProducts(validProducts);
        setFinished(true);
      }
    );
  }, [agencyId, advertiserId, vendorNumber, callAPIs, selectedConfigs, filteredConfigs]);

  return {
    loading,
    finished,
    columns,
    filteredConfigs,
    selectedConfigs,
    selectedConfigProducts,
    onHandleSelect,
    onEnableSelect,
    onFetchSelectedConfigProducts
  };
};
