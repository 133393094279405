import themeConfig from './themeConfig';

const cloudStorageConfig = process.env.REACT_APP_CLOUD_STORAGE_CONFIG ? JSON.parse(process.env.REACT_APP_CLOUD_STORAGE_CONFIG) : {};

const config = {
  ...themeConfig,
  env: process.env.REACT_APP_ENV,
  cloudStorageConfig
};

export default config;
