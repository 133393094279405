import { faPlus, faTimes, faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { Product, SuggestKeyword } from 'core/product/Product';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './momoSearchKeywordsComponent.module.scss';
import i18n from 'i18n';
import { Form } from 'react-bootstrap';
import Tags from 'components/Tags/Tags';
import { SelectOptions } from 'components/commonType';
import classnames from 'classnames/bind';
import { compact, flatten, flow, get, trim, uniq } from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';

const cx = classnames.bind(styles);

export type SearchKeywordsComponentProps = {
  limitationModel: EditLimitationModel;
  products: Product[];
  adRequestSourceManager?: AdRequestSourceManager;
};

const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();

export const SearchKeywordsComponent: React.FC<SearchKeywordsComponentProps> = ({
  limitationModel,
  products,
  adRequestSourceManager = defaultAdRequestSourceManager
}) => {

  const keywordInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<any>(limitationModel.state.errors);
  const includeTA = get(limitationModel.limitationValue, 'include', []);
  const searchKeywordsTA = includeTA.find(ta => ta.type === 'searchKeywords');
  const searchKeywords = searchKeywordsTA ? searchKeywordsTA.value.map(keyword => keyword.value) : [];
  const [inited, setInited] = useState<boolean>(searchKeywords.length > 0 || get(errors, 'include.searchKeywords') !== undefined);
  const [keywords, setKeywords] = useState<string[]>(searchKeywords);
  const [productsHotKeywords, setProductsHotKeywords] = useState<SuggestKeyword[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInitHint, setShowInitHint] = useState<boolean>(false);
  const productHotKeywords = limitationModel.productHotKeywords;

  const uniqKeywords = useCallback((list: SuggestKeyword[]) => {
    const uniqMap: {[key: string]: SuggestKeyword} = {};
    list.forEach(suggestKeyword => {
      const { suggestKeyword: keyword, pr } = suggestKeyword;
      const existSuggestKeyword = uniqMap[keyword];
      if (!existSuggestKeyword || (existSuggestKeyword && +(existSuggestKeyword.pr) < +pr)) {
        uniqMap[keyword] = suggestKeyword;
      }
    });
    return [...Object.values(uniqMap)];
  }, []);

  const fetchProductHotKeywords = useCallback(async () => {
    productHotKeywords ? setLoading(false) : setLoading(true);

    const sortByPr = (list: SuggestKeyword[]) => list.sort((a, b) => b.pr - a.pr);
    const pickFirst200 = (list: SuggestKeyword[]) => list.slice(0, 500);
    const getSuggestKeywords = flow([compact, flatten, uniqKeywords, sortByPr, pickFirst200]);
    setProductsHotKeywords(getSuggestKeywords(productHotKeywords));

  }, [uniqKeywords, productHotKeywords]);

  const tagsValue = useMemo(() => {
    return keywords.map(keyword => ({ value: keyword, label: keyword }));
  }, [keywords]);

  const firstMount = useRef(true);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current) {
      return;
    }
    if (!inited) {
      const sortByPr = (list: SuggestKeyword[]) => list.sort((a, b) => b.pr - a.pr);
      const pickFirst50 = (list: SuggestKeyword[]) => list.slice(0, 300);
      const getSuggestKeywords = flow([uniqKeywords, sortByPr, pickFirst50]);
      const keywords = getSuggestKeywords(productHotKeywords).map(keyword => keyword.suggestKeyword);
      setShowInitHint(keywords.length > 0);
      setInited(true);
      setKeywords(keywords);
    }
  }, [inited, productHotKeywords, uniqKeywords]);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current || !inited) {
      return;
    }
    limitationModel.setLimitation('include', 'searchKeywords', tagsValue);
  }, [inited, limitationModel, tagsValue]);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    fetchProductHotKeywords();
  }, [fetchProductHotKeywords]);

  const addKeyword = useCallback((searchKeywords: string[]): void => {
    setKeywords(keywords => uniq([...keywords, ...searchKeywords]));
  }, []);

  const handleAddSearchString = useCallback((): void => {
    const inputRef = keywordInputRef.current;
    if (!inputRef) {
      return;
    }
    addKeyword(compact(inputRef.value.split(',').map(trim)));
    inputRef.value = '';
    setShowInitHint(false);
  }, [addKeyword]);

  const handleRemoveAllKeywords = useCallback((): void => {
    setShowInitHint(false);
    setKeywords([]);
  }, []);

  const handleOnTagsChange = useCallback((value) => {
    setShowInitHint(false);
    setKeywords(value.map((option: SelectOptions) => option.value));
  }, []);

  useEffect(() => {
    const handler = limitationModel.event.add(model => {
      setErrors(model.state.errors);
    });
    return () => {
      limitationModel.event.remove(handler);
    };
  }, [limitationModel]);

  const renderSuggestKeywords = () => {
    return productsHotKeywords.map((keywordData, index) => {
      const { suggestKeyword } = keywordData;
      const onAddKeyword = () => addKeyword([suggestKeyword]);
      const disabled = keywords.includes(suggestKeyword);
      const percentagePosition = (index + 1) / productsHotKeywords.length;
      const solidStarCount = Math.max(1, Math.ceil(5 - percentagePosition * 5));
      const renderStars = () => {
        const stars: React.JSX.Element[] = [];
        for (let i = 0; i < 5; i++) {
          const isSolid = i < solidStarCount;
          stars.push(
            <FontAwesomeIcon
              key={i}
              className={styles.star}
              icon={isSolid ? solidStar : faStar}
            />
          );
        }
        return stars;
      };
      return (
        <div
          key={suggestKeyword}
          className={
            cx(styles.suggestKeyword, {
              disabled
            })
          }
          onClick={disabled ? undefined : onAddKeyword}
        >
          <div>{suggestKeyword}</div>
          <div className={styles.starRow}>{renderStars()}</div>
          <div className={styles.actionButton}>
            <FontAwesomeIcon icon={faPlus}/>
          </div>
        </div>
      );
    });
  };

  const error = get(errors, 'include.searchKeywords');
  const tagsClassName = useMemo(() => cx(styles.tags, {
    hasError: error !== undefined
  }), [error]);

  const onEnterPress = useCallback((e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleAddSearchString();
    }
  }, [handleAddSearchString]);

  let errorTip;
  if (error) {
    if (Array.isArray(error)) {
      errorTip = error.map((err, index) => <div key={index} className={'errorTip'}>{err}</div>);
    } else {
      errorTip = <div className={'errorTip'}>{error}</div>;
    }
  }

  return (
    <fieldset>
      {!inited && <LoadingIndicator/>}
      <legend>
        <span>{i18n.t<string>('limitation.labels.searchKeywordsTitle')}</span>
      </legend>
      <div className={styles.keywordSettingContainer}>
        <div className={styles.inputArea}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.searchKeywordsDes')}
          </div>
          <div className={styles.row}>
            <Form.Control
              ref={keywordInputRef}
              type='text'
              name='searchKeywords'
              autoComplete='off'
              onKeyDown={onEnterPress}
              placeholder={i18n.t<string>('limitation.placeholders.searchKeywords')}
            />
            <div className={styles.actionButton} onClick={handleAddSearchString}>
              <FontAwesomeIcon icon={faPlus}/>
            </div>
          </div>
          <div className={styles.selectedArea}>
            <Form.Label>{i18n.t<string>('limitation.labels.selectedKeywords')}</Form.Label>
            <div className={styles.row}>
              <Tags
                className={tagsClassName}
                value={tagsValue}
                onChange={handleOnTagsChange}
                operate={'include'}
              />
              <div className={styles.actionButton} onClick={handleRemoveAllKeywords}>
                <FontAwesomeIcon icon={faTimes}/>
              </div>
            </div>
            {showInitHint &&
              <div className={styles.initHint}>
                {i18n.t<string>('limitation.labels.initHint')}
              </div>
            }
            {errorTip}
          </div>
        </div>
        <div className={styles.suggestKeywordsArea}>
          {loading &&
            <div className={styles.loadingIndicator}>
              <div className={styles.roller} />
            </div>
          }
          <>
            <div className={styles.suggestKeywordsTitle}>
              {i18n.t<string>('limitation.labels.suggestKeywordsTitle')}
            </div>
            <div className={styles.suggestKeywordsSubTitle}>
              {i18n.t<string>('limitation.labels.suggestKeywordsSubTitle')}
            </div>
            <div className={styles.suggestKeywords}>
              {renderSuggestKeywords()}
            </div>
          </>
        </div>
      </div>
    </fieldset>
  );
};
