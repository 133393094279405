import { Route, Switch } from 'react-router-dom';
import { PmpList } from './PmpList';
import { PmpSetupFlowPage } from './PmpSetupFlowPage';
import { useGetPmpOptions } from './PmpOptions';
import { useCreatePmpSetupFlowPageModel, useEditPmpSetupFlowPageModel } from './PmpSetupFlowPageModel';

export const Pmp = () => {

  const {
    spaceOptions,
    activeSpaceOptions
  } = useGetPmpOptions();

  const renderPmpCreateForm = () => {
    return (
      <PmpSetupFlowPage
        spaceOptions={activeSpaceOptions}
        useModel={useCreatePmpSetupFlowPageModel}
      />
    );
  };

  const renderPmpEditForm = () => {
    return (
      <PmpSetupFlowPage
        spaceOptions={activeSpaceOptions}
        useModel={useEditPmpSetupFlowPageModel}
      />
    );
  };

  const renderPmpList = () => {
    return (
      <PmpList spaceOptions={spaceOptions}/>
    );
  };

  return (
    <Switch>
      <Route
        path={'/pmp/new'}
        render={renderPmpCreateForm}
      />
      <Route
        path={'/pmp/:pmpId/edit'}
        render={renderPmpEditForm}
      />
      <Route
        render={renderPmpList}
      />
    </Switch>
  );
};
