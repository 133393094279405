import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import styles from './chooseProductsStep.module.scss';
import { ProductListDataContext } from 'containers/RetailMomo/Products/ProductListDataContext';
import { Product } from 'core/product/Product';
import { ProductHome } from 'containers/RetailMomo/Products/ProductHome';
import { defaultTo, get, isNil, omitBy, uniq, xor } from 'lodash';
import { Modal } from 'components/Modal/Modal';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { toast } from 'react-toastify';

export type BindProduct = {
  productId: string;
  retailId: string;
  name: string;
  category: string;
  imgLink: string;
};

export type ChooseProductsStepProps = {
  goNext: () => void;
  goLast: () => void;
  lockRemainSteps: () => void;
  flowModel: RtbCampaignSetupFlowPageModel;
  bindProducts?: Product[];
};

export const ChooseProductsStep: React.FunctionComponent<ChooseProductsStepProps> = ({
  goNext,
  goLast,
  lockRemainSteps,
  flowModel
}) => {

  const adType = flowModel.state.campaign.basic.adType;
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(defaultTo(flowModel.state.campaign.products, []));
  const [productChangeAlert, setProductChangeAlert] = useState<boolean>(false);

  useEffect(() => {
    lockRemainSteps();
  }, [lockRemainSteps]);

  const hasSetupKeywordTA = useMemo(() => {
    const limitationModel = flowModel.limitationModel;
    const keywordTAError = limitationModel ? get(limitationModel.state.errors, 'include.searchKeywords') : undefined;
    const includeTA = get(flowModel.state.campaign.limitations, 'include', []);
    const searchKeywordsTA = includeTA.find(ta => ta.type === 'searchKeywords');
    return (!!searchKeywordsTA && searchKeywordsTA.value.length > 0) || !!keywordTAError;
  }, [flowModel.limitationModel, flowModel.state.campaign]);

  const needUpdateProducts = useMemo(() => {
    const oriProducts = defaultTo(flowModel.state.campaign.products, []);
    const oriProductIds = uniq(oriProducts.map(p => p.productId));
    const selectedProductIds = uniq(selectedProducts.map(p => p.productId));
    return xor(oriProductIds, selectedProductIds).length > 0;
  }, [flowModel.state.campaign.products, selectedProducts]);

  const needResetKeywords = useMemo(() => {
    return hasSetupKeywordTA && needUpdateProducts;
  }, [needUpdateProducts, hasSetupKeywordTA]);

  const updateCampaignAndGoNext = useCallback((resetBidPrice: boolean) => {
    let campaign = flowModel.state.campaign;
    if (resetBidPrice) {
      campaign = {
        ...campaign,
        basic: {
          ...campaign.basic,
          bidPrice: undefined
        }
      };
    }
    const limitationModel = flowModel.limitationModel;
    const includeTA = campaign.limitations.include;
    limitationModel && limitationModel.clearLimitation('include', 'searchKeywords', true);
    flowModel.setCampaign({
      ...campaign,
      products: selectedProducts,
      limitations: omitBy({
        ...campaign.limitations,
        include: includeTA ? includeTA.filter(limitation => limitation.type !== 'searchKeywords') : undefined
      }, isNil)
    });
    setProductChangeAlert(false);
    goNext();
  }, [goNext, flowModel, selectedProducts]);

  const onGoNext = useCallback(() => {
    if (selectedProducts.length > 25) {
      toast.warn(i18n.t<string>('productList.messages.upperLimit'));
      return;
    }
    if (needResetKeywords) {
      setProductChangeAlert(true);
      return;
    }
    if (needUpdateProducts) {
      const campaign = flowModel.state.campaign;
      flowModel.setCampaign({
        ...campaign,
        products: selectedProducts
      });
    }
    goNext();
  }, [needResetKeywords, flowModel, selectedProducts, needUpdateProducts, goNext, setProductChangeAlert]);

  const closeGoNextAlert = useCallback(() => {
    setProductChangeAlert(false);
  }, []);

  return (
    <div className={styles.chooseProductsStep}>
      <div className={styles.productList}>
        {productChangeAlert && (
          adType === AdType.SMART_KEYWORD ?
            <Modal
              title={i18n.t<string>('common.warning')}
              primaryButton={{
                title: i18n.t<string>('common.labels.yes'),
                callback: () => updateCampaignAndGoNext(true)
              }}
              dismiss={closeGoNextAlert}
            >
              {i18n.t<string>('campaign.messages.resetBidPrice')}
            </Modal> :
            <Modal
              title={i18n.t<string>('common.warning')}
              primaryButton={{
                title: i18n.t<string>('common.labels.yes'),
                callback: () => updateCampaignAndGoNext(false)
              }}
              secondaryButton={{
                title: i18n.t<string>('common.labels.no'),
                callback: goNext
              }}
              dismiss={closeGoNextAlert}
            >
              {i18n.t<string>('campaign.messages.clearSearchKeywords')}
            </Modal>
          )
        }
        <ProductListDataContext.Provider
          value={{
            selectedProducts,
            smartCampaignConfig: flowModel.state.smartCampaignConfig,
            handleSelectedProducts: (fn: (selectedProducts: Product[]) => Product[]): void => {
              setSelectedProducts(fn(selectedProducts));
            },
            handleFilterChanged: () => {}
          }}
        >
          <ProductHome
            defaultFilters={{
              advertiser: flowModel.order.advertiserId
            }}
            enableCreateProductNativeCreative={false}
            title={i18n.t<string>('campaign.labels.selectProduct')}
            limitSelect={true}
          />
        </ProductListDataContext.Provider>
      </div>
      <div className={styles.buttonArea}>
        <Button variant='primary' size='sm' onClick={onGoNext}>
          {i18n.t<string>('campaign.buttons.completeAndCheck')}
        </Button>
        {flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={goLast}>
          {i18n.t<string>('campaign.buttons.back')}
        </Button>}
      </div>
    </div>
  );
};
