import { LoadingIndicator } from 'components/LoadingIndicator';
import { usePmpListModel } from './PmpListModel';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { Page } from 'components/Page/Page';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { SelectOptions } from 'components/commonType';
// import { FilterMenuTab } from 'components/FilterMenuTab/FilterMenuTab';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'components/Modal/Modal';
import { PmpDurationError } from './PmpDurationValidator';
import { Pmp } from 'core/pmp/Pmp';

export const PmpList: React.FC<{
  spaceOptions: SelectOptions[]
}> = ({
  spaceOptions
}) => {

  const {
    loading,
    columns,
    pageable,
    filterdPmps,
    selectedPmp,
    searchString,
    batchOperations,
    pmpDurationError,
    // filterMenuTabConfigs,
    cancelActivate,
    onHandleSearch,
    onHandleSelect,
    handleTableChange,
    onHandleSelectPage
  } = usePmpListModel(spaceOptions, true);

  const renderRowBtns = useCallback((pmp) => {
    const canEdit = !pmp.campaignId;
    return [(
        <IconWithTooltip
          key={`edit-btn-${pmp.id}`}
          disabled={!canEdit}
          icon={faPencilAlt}
          tooltipProps={{
            id: `edit-pmp-${pmp.id}`,
            link: `/pmp/${pmp.id}/edit`,
            tooltip: canEdit ?
              i18n.t<string>('pmpSetupFlowPage.titles.edit') :
              i18n.t<string>('pmpList.hints.cannotEdit')
          }}
        />
    )];
  }, []);

  const renderListOperations = useCallback(() => [
    <Link
      key='new-btn'
      to={'/pmp/new'}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('pmpList.buttons.create')}
    </Link>
    // <FilterMenuTab
    //   key='filter'
    //   filterMenuTabConfigs={filterMenuTabConfigs}
    // />
  ]
  , []);

  const renderActivateError = useCallback((errorData: PmpDurationError & Pmp) => {
    return (
      <Modal
        title={i18n.t<string>('pmpActivateModal.titles.sponsorshipConflict')}
        primaryButton={{
          title: i18n.t<string>('common.buttons.ok'),
          callback: cancelActivate
        }}
        dismiss={cancelActivate}
        animation={false}
      >
        <div>
          <div>{i18n.t<string>('pmpActivateModal.hints.confictWith')}</div>
          <ul style={{ paddingInlineStart: '20px' }}>
            {errorData.duration && <li>{i18n.t<string>('pmpActivateModal.hints.durationConflict')}</li>}
            {errorData.daypart && <li>{i18n.t<string>('pmpActivateModal.hints.daypartConflict')}</li>}
            <li>{i18n.t<string>('pmpActivateModal.hints.spaceConflict')}</li>
          </ul>
          <div>{errorData.campaignId ? i18n.t<string>('pmpActivateModal.hints.boundPmpSuggest') : i18n.t<string>('pmpActivateModal.hints.unboundPmpSuggest')}</div>
        </div>
      </Modal>
    );
  }, [cancelActivate]);

  return (
    <Page title={i18n.t<string>('pmpList.title')}>
      {loading && <LoadingIndicator/>}
      {pmpDurationError && renderActivateError(pmpDurationError)}
      <StickableBootstrapTable
        remote
        customPadding={false}
        keyField={'id'}
        data={filterdPmps}
        columns={columns}
        noDataIndication={i18n.t<string>('pmpList.placeholders.noData')}
        pagination={{
          page: pageable.page,
          sizePerPage: pageable.sizePerPage,
          totalSize: pageable.totalCount
        }}
        selectedRows={selectedPmp}
        onSelect={onHandleSelect}
        onSelectPage={onHandleSelectPage}
        defaultSearchString={searchString}
        searchbarPlaceholder={i18n.t<string>('pmpList.placeholders.searchbar')}
        batchOperaionConfig={batchOperations}
        onHandleSearch={onHandleSearch}
        renderListOperations={renderListOperations}
        renderRowBtns={renderRowBtns}
        onTableChange={handleTableChange}
      />
    </Page>
  );
};
