import { CreativeType } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18n';
import { CreativeFormBasicData } from '../FormContent/FormContentModel';
import { ImageMediaSummary } from './ImageMediaSummary';
import { CloudStorageManager, AWSS3Manager } from 'core/cloudStorage/CloudStorageManager';
import React from 'react';
import { ImageMultipleMediaSummary } from './ImageMultipleMediaSummary';
import { ProductNativeMediaSummary } from './ProductNativeMediaSummary';
import { RichProductNativeMediaSummary } from './RichProductNativeMediaSummary';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';

export interface CreativeSummaryModel {
  getCreativeBasicSummaryData: (
    getUploadedFileData?: (file: File) => Promise<any>,
    addUploadedFilesData?: (file: File, data: any) => Promise<void>
  ) => Promise<any>;
  getMediaSummaryComponent: () => any;
  getMediaSummary: () => any;
  getBasicSubmitData: () => Promise<any>;
  getJsonSubmitData: () => Promise<any>;
}

abstract class DefaultCreativeSummaryModel implements CreativeSummaryModel {

  constructor (
    protected creativeBasicFormData: CreativeFormBasicData,
    protected cloudStorageManager: CloudStorageManager = new AWSS3Manager()
  ) {}

  getI18nOfValueByKey (key, value) {
    switch (key) {
      case 'creativeType':
        return i18n.t<string>(`creativeType.${_.camelCase(CreativeType[value])}`);
      default:
        return value;
    }
  }

  transferObjectToSummaryData (data, keyPrefix = '') {
    if (!data) {
      return [];
    }
    return _.flattenDeep(Object.keys(data).map(key => {
      const currentKey = _.camelCase(`${keyPrefix}-${key}`);
      if (typeof data[key] === 'object' && !React.isValidElement(data[key])) {
        return this.transferObjectToSummaryData(data[key], currentKey);
      }
      return {
        label: i18n.t<string>(`creativeSetupFlow.labels.${currentKey}`),
        value: this.getI18nOfValueByKey(currentKey, data[key])
      };
    }));
  }

  async getCreativeBasicSummaryData () {
    const omitProps = ['advertiserId', 'medias', 'typeProperties', 'bannerExtra', 'bannerImageUrl', 'tenmaxCategory', 'retail'];
    const basic = _.omitBy(_.omit(this.creativeBasicFormData, omitProps), _.isUndefined);
    return _.compact([
      ...this.transferObjectToSummaryData(basic),
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]);
  }

  setBasicFormData (formData, omitKeys: string[]) {
    const basic = _.omit(this.creativeBasicFormData, omitKeys);
    for (let key in basic) {
      if (basic[key] !== undefined && basic[key] !== null) {
        formData.append(`creative.${key}`, basic[key]);
      }
    }
  }

  setBasicJson (json, omitKeys: string[]) {
    const basic = _.omit(this.creativeBasicFormData, omitKeys);
    for (let key in basic) {
      if (basic[key] !== undefined && basic[key] !== null) {
        json[key] = basic[key];
      }
    }
  }

  abstract getMediaSummaryComponent ();
  abstract getBasicSubmitData ();
  abstract getJsonSubmitData ();

  getTypePropertiesSummary (): any {
    return {};
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }
}

export class ImageSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return ImageMediaSummary;
  }

  async getBasicSubmitData (): Promise<any> {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    formData.append('creative.typeProperties[width]', imageData.width);
    formData.append('creative.typeProperties[height]', imageData.height);
    const primaryProduct = _.get(this.creativeBasicFormData, 'typeProperties.primaryProduct', {});
    formData.append('creative.typeProperties[productId]', primaryProduct.productId);
    formData.append('creative.typeProperties[productName]', primaryProduct.name);
    let imageUrl = imageData.url;
    if (imageData.file) {
      imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (imageUrl) {
      formData.append('creative.typeProperties[imageUrl]', imageUrl);
    }

    return formData;
  }

  async getJsonSubmitData (): Promise<any> {
    let jsonData: any = {
      typeProperties: {}
    };
    this.setBasicJson(jsonData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    if (this.creativeBasicFormData['creativeId']) {
      jsonData.id = this.creativeBasicFormData['creativeId'];
    }
    jsonData.advertiserId = this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'] : 0;
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    jsonData.typeProperties.width = imageData.width.toString();
    jsonData.typeProperties.height = imageData.height.toString();
    const primaryProduct = _.get(this.creativeBasicFormData, 'typeProperties.primaryProduct', {});
    jsonData.typeProperties.productId = primaryProduct.productId;
    jsonData.typeProperties.productName = primaryProduct.name;
    let imageUrl = imageData.url;
    if (imageData.file) {
      imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (imageUrl) {
      jsonData.typeProperties.imageUrl = imageUrl;
    }

    return jsonData;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const primaryProduct = _.get(typeProperties, 'primaryProduct');
    return _.omitBy({
      primaryProduct: primaryProduct ? getProductDescription(primaryProduct) : ''
    }, _.isEmpty);
  }
}

export class ProductNativeSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return ProductNativeMediaSummary;
  }

  async getCreativeBasicSummaryData () {
    const basic = _.omit(this.creativeBasicFormData, ['advertiserId', 'medias', 'typeProperties', 'bannerExtra', 'bannerImageUrl', 'products', 'product', 'tenmaxCategory', 'retail']);
    return _.compact([
      ...this.transferObjectToSummaryData(basic),
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]);
  }

  getTypePropertiesSummary () {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const product = _.get(typeProperties, 'product');
    return _.omitBy({
      product: product ? getProductDescription(product) : ''
    }, _.isEmpty);
  }

  async getBasicSubmitData (): Promise<any> {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'products', 'product']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const product = _.get(this.creativeBasicFormData, 'typeProperties.product', {});
    if (!_.isEmpty(product)) {
      formData.append('creative.typeProperties[product]', JSON.stringify(product));
    }

    return formData;
  }

  async getJsonSubmitData (): Promise<any> {
    let jsonData: any = {
      typeProperties: {}
    };
    this.setBasicJson(jsonData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'products', 'product']);
    if (this.creativeBasicFormData['creativeId']) {
      jsonData.id = this.creativeBasicFormData['creativeId'];
    }
    jsonData.advertiserId = this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'] : 0;
    const product = _.get(this.creativeBasicFormData, 'typeProperties.product', {});
    if (!_.isEmpty(product)) {
      jsonData.typeProperties.product = JSON.stringify(product);
    }

    return jsonData;
  }
}

export class CreateProductNativeSummaryModel extends ProductNativeSummaryModel {

  getTypePropertiesSummary () {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    return _.omitBy({
      products: _.get(typeProperties, 'products', []).map((product, index) => {
        return `${index + 1} ${getProductDescription(product)}`;
      }).join('\r\n')
    }, _.isEmpty);
  }

  async getBasicSubmitData (): Promise<any> {
    const submitData: any[] = [];
    const products = _.get(this.creativeBasicFormData, 'typeProperties.products', []);
    _.forEach(products, (product) => {
      const creative = {
        creative: {
          creativeType: this.creativeBasicFormData.creativeType,
          advertiserId: this.creativeBasicFormData.advertiserId,
          tenmaxCategory: this.creativeBasicFormData.tenmaxCategory,
          typeProperties: {
            product: JSON.stringify({
              productId: product.productId,
              retailId: product.retailId,
              name: product.name,
              imgLink: product.imgLink,
              multipack: product.multipack
            })
          }
        }
      };
      submitData.push(creative);
    });
    return submitData;
  }

  async getJsonSubmitData (): Promise<any> {
    return this.getBasicSubmitData();
  }
}

export class MultiImageSummaryModel extends ImageSummaryModel {

  getMediaSummaryComponent (): any {
    return ImageMultipleMediaSummary;
  }

  async getBasicSubmitData () {
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imagesData = _.get(medias, 'images', {});
    const imagesArray: { file: File, width: number, height: number } [] = Object.values(imagesData);
    const primaryProduct = _.get(this.creativeBasicFormData, 'typeProperties.primaryProduct', {});
    const submitData: any[] = [];
    const sizeImageMap: { [key: string]: { file: File, width: number, height: number }[] } = imagesArray.reduce((acc, image) => {
      const sizeDes = `${image.width}x${image.height}`;
      if (sizeDes in acc) {
        acc[sizeDes].push(image);
      } else {
        acc[sizeDes] = [image];
      }
      return acc;
    }, {});
    for (let size in sizeImageMap) {
      const imagesArray = sizeImageMap[size];
      for (let i = 0; i < imagesArray.length; ++i) {
        const image = imagesArray[i];
        const imageUrl = await this.cloudStorageManager.uploadFileToCloud(image.file);
        let name = `${this.creativeBasicFormData.name}_${size}`;
        if (imagesArray.length > 1) {
          name = `${name}(${i + 1})`;
        }
        submitData.push({
          creative: {
            name,
            landingPageUrl: this.creativeBasicFormData.landingPageUrl,
            creativeType: this.creativeBasicFormData.creativeType,
            advertiserId: this.creativeBasicFormData.advertiserId,
            tenmaxCategory: this.creativeBasicFormData.tenmaxCategory,
            bannerUrl: this.creativeBasicFormData.bannerUrl,
            typeProperties: {
              width: image.width.toString(),
              height: image.height.toString(),
              imageUrl,
              productId: primaryProduct.productId,
              productName: primaryProduct.name
            }
          }
        });
      }
    }

    return submitData;
  }

  async getJsonSubmitData (): Promise<any> {
    return this.getBasicSubmitData();
  }
}

export class RichProductNativeSummaryModel extends DefaultCreativeSummaryModel {

  imageUrl?: string;
  adServingUrl?: string;

  constructor (
    creativeBasicFormData: CreativeFormBasicData,
    cloudStorageManager: CloudStorageManager = new AWSS3Manager(),
    private rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return RichProductNativeMediaSummary;
  }

  getMainProduct () {
    return _.get(this.creativeBasicFormData.typeProperties, 'product', {});
  }

  async getCreativeBasicSummaryData () {
    const agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
    if (agencyId) {
      const product = this.getMainProduct();
      this.adServingUrl = product.adServingUrl;
      if (!this.adServingUrl) {
        this.adServingUrl = await this.rdpManager.getAdServingUrl(product.productId);
      }
    }
    const basic = _.omit(this.creativeBasicFormData, ['advertiserId', 'medias', 'typeProperties', 'bannerExtra', 'bannerImageUrl', 'products', 'product', 'tenmaxCategory', 'retail']);
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    this.imageUrl = imageData.url;
    if (imageData.file) {
      this.imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }

    return _.compact([
      ...this.transferObjectToSummaryData(basic),
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]);
  }

  getTypePropertiesSummary () {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const basicSummary = super.getTypePropertiesSummary();
    const product = _.get(typeProperties, 'product');
    return _.omitBy({
      ...basicSummary,
      product: product ? getProductDescription(product) : ''
    }, _.isEmpty);
  }

  getMediaSummary (): any {
    const image = _.get(this.creativeBasicFormData, 'medias.image', {});
    if (!this.imageUrl || !image || !this.adServingUrl) {
      return {};
    }
    return {
      typeProperties: {
        product: {
          ...this.getMainProduct(),
          adServingUrl: this.adServingUrl
        }
      },
      medias: {
        image: {
          ...image,
          url: this.imageUrl
        }
      }
    };
  }

  async getBasicSubmitData (): Promise<any> {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'products', 'product']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');

    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    formData.append('creative.typeProperties[width]', imageData.width);
    formData.append('creative.typeProperties[height]', imageData.height);
    if (!this.imageUrl && imageData.file) {
      this.imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (this.imageUrl) {
      formData.append('creative.typeProperties[imageUrl]', this.imageUrl);
    }

    const product = _.get(this.creativeBasicFormData, 'typeProperties.product', {});
    if (!_.isEmpty(product)) {
      formData.append('creative.typeProperties[product]', JSON.stringify(product));
    }

    return formData;
  }

  async getJsonSubmitData (): Promise<any> {
    let jsonData: any = {
      typeProperties: {}
    };
    this.setBasicJson(jsonData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'products', 'product']);
    if (this.creativeBasicFormData['creativeId']) {
      jsonData.id = this.creativeBasicFormData['creativeId'];
    }
    jsonData.advertiserId = this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'] : 0;

    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    jsonData.typeProperties.width = imageData.width.toString();
    jsonData.typeProperties.height = imageData.height.toString();
    if (!this.imageUrl && imageData.file) {
      this.imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (this.imageUrl) {
      jsonData.typeProperties.imageUrl = this.imageUrl;
    }

    const product = _.get(this.creativeBasicFormData, 'typeProperties.product', {});
    if (!_.isEmpty(product)) {
      jsonData.typeProperties.product = JSON.stringify(product);
    }

    return jsonData;
  }
}

export class CreateRichProductNativeSummaryModel extends RichProductNativeSummaryModel {

  getMainProduct () {
    return _.get(this.creativeBasicFormData.typeProperties, 'products.0');
  }

  getTypePropertiesSummary () {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const basicSummary = super.getTypePropertiesSummary();
    return _.omitBy({
      ...basicSummary,
      products: _.get(typeProperties, 'products', []).map((product, index) => {
        return `${index + 1} ${getProductDescription(product)}`;
      }).join('\r\n')
    }, _.isEmpty);
  }

  getMediaSummary (): any {
    const image = _.get(this.creativeBasicFormData, 'medias.image', {});
    if (!this.imageUrl || !image || !this.adServingUrl) {
      return {};
    }
    const firstProduct = this.getMainProduct();
    if (!firstProduct) {
      return {};
    }
    return {
      typeProperties: {
        product: {
          ...firstProduct,
          adServingUrl: this.adServingUrl
        }
      },
      medias: {
        image: {
          ...image,
          url: this.imageUrl
        }
      }
    };
  }

  async getBasicSubmitData (): Promise<any> {
    const submitData: any[] = [];
    const products = _.get(this.creativeBasicFormData, 'typeProperties.products', []);
    const image = _.get(this.creativeBasicFormData, 'medias.image', {});
    const imageData = {
      width: image.width.toString(),
      height: image.height.toString(),
      imageUrl: this.imageUrl
    };
    if (image.file && !this.imageUrl) {
      imageData.imageUrl = await this.cloudStorageManager.uploadFileToCloud(image.file);
    }

    _.forEach(products, (product) => {
      const creative = {
        creative: {
          name: `${this.creativeBasicFormData.name} - ${product.name}`,
          creativeType: this.creativeBasicFormData.creativeType,
          advertiserId: this.creativeBasicFormData.advertiserId,
          tenmaxCategory: this.creativeBasicFormData.tenmaxCategory,
          typeProperties: {
            ...imageData,
            product: JSON.stringify({
              productId: product.productId,
              retailId: product.retailId,
              name: product.name,
              imgLink: product.imgLink,
              multipack: product.multipack
            })
          }
        }
      };
      submitData.push(creative);
    });
    return submitData;
  }

  async getJsonSubmitData (): Promise<any> {
    return this.getBasicSubmitData();
  }
}

function getProductDescription (product) {
  return `${product.name} (ID: ${product.productId})`;
}
