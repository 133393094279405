import { CreativeType } from 'core/creative/Creative';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { CampaignLimitation } from 'core/limitation/Limitation';
import { Product } from 'core/product/Product';

export type RtbCampaign = {
  basic: RtbCampaignBasic;
  limitations: CampaignLimitation;
  products?: Product[];
};

export type RtbCampaignBasic = {
  id?: number;
  name: string;
  groupId: number;
  advertiserId: number;
  orderId: number;
  budget: number;
  startDate: string;
  endDate: string;
  createDate?: string;
  priceModel: RtbCampaignPlanType;
  orderPrice?: number;
  optimize: L2ObjectOptimizationGoal;
  bidPrice?: number;
  creativeDeliverType: CreativeDeliverType;
  deliverType: DeliverType;
  state?: CampaignState;
  effectiveStatus?: string;
  spents?: number;
  currencyRate?: number;
  olapActualSpent?: number;
  olapExpectSpent?: number;
  expectedSpent: number;
  impres?: number;
  clicks?: number;
  dailyTargetBudget: number | null;
  frequency?: CampaignFrequency;
  dayPart?: { [key: string]: string[] | number[] | string };
  checkpoints: CheckPoint[];
  dealIds?: string[];
  adType: AdType;
  additionalInfo?: AdditionalInfo;
  bindings?: {
    bindingId: string,
    active: boolean
  },
  smartCampaignConfigId?: string;
  report?: any;
  ageMin: number;
  ageMax: number;
};

export type AdditionalInfo = {
  creativeAmount: CreativeAmount,
  limitations: { [key: string]: object[] }
};

export type CreativeAmount = {
  bindingCount: number,
  enableCount: number
};

export type CampaignFrequency = {
  maxFrequency: number;
  intervalDays: number;
};

export type CheckPoint = {
  target: number;
  time: string;
  utime: string;
};

export enum AdType {
  DISPLAY = 'DISPLAY',
  KEYWORD = 'KEYWORD',
  SMART_KEYWORD = 'SMART_KEYWORD',
  PMP = 'PMP',
  SMART_CONFIG = 'SMART_CONFIG',
  UNKNOWN = 'UNKNOWN',
  SHOP = 'SHOP'
}

export enum RtbOptimize {
  IMPRESSIONS = 'IMPRESSIONS',
  CLICKS = 'CLICKS'
}

export enum RtbCampaignPlanType {
  RS = 'rs',
  FCPC = 'fcpc',
  FCPM = 'fcpm',
  RS_CPC = 'rsCpc',
  RS_CPM = 'rsCpm'
}

export enum CreativeDeliverType {
  OPTIMIZE = 1,
  AVERAGE = 2
}

export enum CampaignState {
  ACTIVATE = 1,
  DEACTIVATE = 2,
  DELETE = 3,
  STOPPING = 4
}

export enum DailyBudgetPlan {
  SCHEDULE,
  DAILY
}

export enum DeliverType {
  STANDARD = 1,
  ACCELERATED = 2
}

export const AD_TYPE_MAP_CREATIVE_TYPE = {
  [AdType.DISPLAY]: [CreativeType.IMAGE, CreativeType.PRODUCT_NATIVE, CreativeType.RICH_PRODUCT_NATIVE],
  [AdType.KEYWORD]: [CreativeType.PRODUCT_NATIVE],
  [AdType.SMART_KEYWORD]: [CreativeType.PRODUCT_NATIVE],
  [AdType.PMP]: [CreativeType.IMAGE],
  [AdType.SMART_CONFIG]: [CreativeType.PRODUCT_NATIVE], // TODO: If smart config allows other adType, should add corresponding creativeType here
  [AdType.SHOP]: [CreativeType.SHOP]
};

export const RTBCAMPAIGN_DEFAULT_AGE_MIN = 13;
export const RTBCAMPAIGN_DEFAULT_AGE_MAX = 65;

export enum Gender {
  ALL = -1,
  MALE = 1,
  FEMALE
}
