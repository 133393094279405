import _ from 'lodash';
import moment from 'moment';
import {
  RtbCampaignBasic,
  RtbCampaignPlanType,
  DailyBudgetPlan,
  DeliverType,
  CampaignState,
  AdType,
  RtbOptimize
} from 'core/rtbCampaign/RtbCampaign';
import { SelectOptions } from 'components/commonType';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Currency, AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { DefaultRtbCampaignBasicFormValidator, RtbCampaignBasicFormValidator } from './RtbCampaignBasicFormValidator';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { OPERATE } from 'enum/Operate';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';

export interface RtbCampaignBasicFormModel {
  readonly actionType: string;
  readonly l1Object: L1Object;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly canUseDailyBudget: boolean;
  readonly priceModelOptions: SelectOptions[];
  readonly campaignBasic: RtbCampaignBasic;
  readonly campaignDeliverTypeOptions: SelectOptions[];
  readonly defaultDeliverType: DeliverType;
  readonly order: Order;
  readonly currency: string;
  readonly budgetMinimum: number;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
  readonly minDate: string;
  readonly maxDate: string;
  readonly defaultPriceModel: RtbCampaignPlanType;
  readonly campaignAdType: AdType;
  readonly showOptimizeSection: boolean;
  readonly showDeliverType: boolean;
  readonly availablePriceModel: RtbCampaignPlanType[];
  readonly formikValue?: any;
  readonly limitationOperates: {
    need: string[],
    other?: string[]
  };
  init (goLast: () => void): Promise<void>;
  getCurrentPriceModel (): RtbCampaignPlanType;
  setCurrentPriceModel (planType: RtbCampaignPlanType);
  getBidPriceRange: (optimize: RtbOptimize) => {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  };
  getDefaultOptimizeType: (planType: RtbCampaignPlanType) => L2ObjectOptimizationGoal | undefined;
  getRemainBudget: (campaignBudget: number) => number;
  changeDailyBudgetOptions: (dailyBudgetType: DailyBudgetPlan) => void;
  getDailyBudgetState: (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ) => DAILY_BUDGET_STATE;
  getCampaignTotalDay: (startDate: string, endDate: string) => number;
  state: RtbCampaignBasicFormState;
  readonly event: UpdateEventListener<RtbCampaignBasicFormModel>;
  onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void;
  validate: (campaignBasic: any, order: Order) => any;
  onUnmount: (handler?: number) => void;
  setFormikValue: (value: any) => void;
  setupDefaultCampaign: (campaign: any) => void;
}

export enum DAILY_BUDGET_STATE {
  DEFAULT,
  UNDER_BUDGET,
  OVER_BUDGET,
  MEET_BUDGET
}

export type RtbCampaignBasicFormProps = {
  readonly model: RtbCampaignBasicFormModel;
};

type ModalData = {
  title: string,
  message: string,
  primaryButtonData: {
    title: string,
    callback: () => void
  }
};

export type RtbCampaignBasicFormState = {
  dailyBudgetType: DailyBudgetPlan;
  loading: boolean;
  modalData?: ModalData;
};

export abstract class DefaultRtbCampaignBasicFormModel
  implements RtbCampaignBasicFormModel {
  modelDailyBudgetType: DailyBudgetPlan;
  event: FireableUpdateEventListener<RtbCampaignBasicFormModel>;
  currentPriceModel: RtbCampaignPlanType;
  canUseRS: boolean;
  validator: RtbCampaignBasicFormValidator;
  rtbCampaignManager = new DefaultRtbCampaignManager();
  formikValue?: any;
  onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void;
  defaultDeliverType: DeliverType;
  loading: boolean = false;
  order: Order;
  l1Object: L1Object;
  campaignBasic: RtbCampaignBasic;
  defaultCampaign: any;
  addonFeatureManager: AddonFeatureManager;
  modalData?: ModalData;

  constructor (
    public actionType: string,
    public flowModel: RtbCampaignSetupFlowPageModel
  ) {
    this.order = flowModel.order;
    this.l1Object = flowModel.l1Object;
    this.campaignBasic = flowModel.state.campaign.basic;
    this.addonFeatureManager = flowModel.addonFeatureManager;
    this.defaultCampaign = flowModel.defaultCampaign;
    this.onPriceModelChangeCallback = (type, campaignBasic) => {
      flowModel.onPriceModelChange(type, campaignBasic);
    };
    this.defaultDeliverType = DeliverType.STANDARD;
    this.modelDailyBudgetType = !this.campaignBasic.dailyTargetBudget || this.campaignBasic.dailyTargetBudget < 1 ? DailyBudgetPlan.SCHEDULE : DailyBudgetPlan.DAILY;
    this.event = new FireableUpdateEventListener<RtbCampaignBasicFormModel>();
    this.currentPriceModel = this.campaignBasic.priceModel;
    this.canUseRS = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.REVENUE_SHARING);
    this.validator = new DefaultRtbCampaignBasicFormValidator(
      actionType,
      this.defaultCampaign,
      this.getBidPriceRange.bind(this),
      this.addonFeatureManager,
      this.getPriceModels.bind(this),
      this.getOptimizes.bind(this)
    );
  }

  abstract init (goLast: () => void): Promise<void>;

  get needSetupAgeGender (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [OPERATE.INCLUDE, OPERATE.PREFERRED],
      notNeed: [OPERATE.EXCLUDE],
      other: []
    };
  }

  get campaignAdType (): AdType {
    return AdType.UNKNOWN;
  }

  get showOptimizeSection (): boolean {
    return true;
  }

  get showDeliverType (): boolean {
    return true;
  }

  setFormikValue (value: any) {
    this.formikValue = value;
  }

  getRtbOptionsMap () {
    const channel: L1ObjectChannel = this.l1Object.channel;
    const enableFCPC = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.FIXED_CPC);
    const enableFCPM = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.FIXED_CPM);
    const defaultRtbOptionsMap: any = {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      },
      [L1ObjectObjective.TRAFFIC]: _.omitBy({
        [RtbCampaignPlanType.RS_CPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.RS_CPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: enableFCPM ? [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ] : undefined,
        [RtbCampaignPlanType.FCPC]: enableFCPC ? [
          L2ObjectOptimizationGoal.CLICKS
        ] : undefined
      }, _.isUndefined)
    };
    const channelOptionsMap = {
      [L1ObjectChannel.RTB]: defaultRtbOptionsMap
    };
    return channelOptionsMap[channel];
  }

  getPriceModels () {
    const objective = this.l1Object.objective;
    const optionsMap = this.getRtbOptionsMap();
    return Object.keys(optionsMap[objective]);
  }

  getOptimizes (priceModel: RtbCampaignPlanType) {
    const objective = this.l1Object.objective;
    const optionsMap = this.getRtbOptionsMap();
    const optimizes = optionsMap[objective][priceModel];
    return optimizes ? optimizes : [];
  }

  getCurrentPriceModel (): RtbCampaignPlanType {
    return this.currentPriceModel;
  }

  setCurrentPriceModel (planType: RtbCampaignPlanType) {
    this.currentPriceModel = planType;
  }

  getAddonByPlanTypeOrOptimize (planType) {
    switch (planType) {
      case RtbCampaignPlanType.FCPC:
      case L2ObjectOptimizationGoal.CLICKS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPC;
      case RtbCampaignPlanType.FCPM:
      case L2ObjectOptimizationGoal.IMPRESSIONS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPM;
      case RtbCampaignPlanType.RS:
        return ADDONFEATURE.CAMPAIGN.REVENUE_SHARING;
      default:
        return;
    }
  }

  get availablePriceModel () {
    const availablePriceModel: any[] = [];
    this.getPriceModels().forEach(priceModel => {
      const relatedAddon = this.getAddonByPlanTypeOrOptimize(priceModel);
      const addonEnable = relatedAddon ? this.addonFeatureManager.isFeatureEnable(relatedAddon) : true;
      if (addonEnable) {
        availablePriceModel.push(priceModel);
      }
    });
    return availablePriceModel;
  }

  get defaultPriceModel (): RtbCampaignPlanType {
    return this.canUseRS && this.availablePriceModel.includes(RtbCampaignPlanType.RS) ?
      RtbCampaignPlanType.RS :
      this.availablePriceModel[0];
  }

  get priceModelOptions (): SelectOptions[] {
    const options = SelectOptionsUtils.createSelectOptionsFromEnum(
      RtbCampaignPlanType, 'campaign.labels.', [], _.camelCase
    );
    return SelectOptionsUtils.filter(options, this.availablePriceModel);
  }

  get campaignDeliverTypeOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      DeliverType, 'campaign.labels.'
    );
  }

  getRemainBudget (campaignBudget: number): number {
    const budgetBalance = _.get(this.order, 'budgetBalance', 0);
    const totalBudget = this.defaultCampaign.basic.id === undefined ?
        budgetBalance :
        budgetBalance + this.defaultCampaign.basic.budget;
    return _.round(Number(totalBudget) - campaignBudget, 2);
  }

  get budgetMinimum (): number {
    const budgetMinimum = _.get(
      this.order,
      'campaignConstraint.budgetMinimum',
      100
    );
    const spents = this.campaignBasic.spents ? this.campaignBasic.spents : 0;
    return this.campaignBasic.state === CampaignState.DEACTIVATE ? getPriceValue(this.order.currency, spents) : Number(budgetMinimum);
  }

  getDailyBudgetState (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ): DAILY_BUDGET_STATE {
    if (!dailyBudget || dailyBudget < 1 || dailyBudget > totalBudget) {
      return DAILY_BUDGET_STATE.DEFAULT;
    }
    const lastDay = totalBudget / dailyBudget;
    if (lastDay === totalDay) {
      return DAILY_BUDGET_STATE.MEET_BUDGET;
    }
    if (lastDay < totalDay) {
      return DAILY_BUDGET_STATE.OVER_BUDGET;
    }
    return DAILY_BUDGET_STATE.UNDER_BUDGET;
  }

  get canUseDailyBudget (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.BUDGET_DOMINATE);
  }

  get minDate (): string {
    const orderStartDate = moment(_.get(this.order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (this.campaignBasic && this.campaignBasic.startDate) {
      return moment(this.campaignBasic.startDate).isBefore(orderStartDate)
        ? moment().isAfter(orderStartDate)
          ? thisHour
          : orderStartDate.format('YYYY-MM-DD_HH:mm:ss')
        : moment(this.campaignBasic.startDate).isBefore()
          ? moment(this.campaignBasic.startDate).format('YYYY-MM-DD_HH:mm:ss')
          : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }

  get maxDate (): string {
    return moment(_.get(this.order, 'endDate')).endOf('day').format('YYYY-MM-DD_HH:mm:ss');
  }

  getDefaultOptimizeType (priceModel: RtbCampaignPlanType) {
    const validOptimizes = this.getOptimizes(priceModel);
    let l1ObjectDefaultOptimizationGoal = validOptimizes[0];
    return l1ObjectDefaultOptimizationGoal;
  }

  getCampaignTotalDay (startDate: string, endDate: string): number {
    const format = 'YYYY-MM-DD';
    return moment(endDate, format)
      .add(1, 'days')
      .diff(moment(startDate, format), 'days');
  }

  getBidCap (optimize: RtbOptimize): number {
    const bidPriceData = this.order.campaignBidPrice.find(data => data.type === this.campaignAdType);
    let optimizeKey = '';
    switch (optimize) {
      case RtbOptimize.CLICKS:
        optimizeKey = 'cpc';
        break;
      case RtbOptimize.IMPRESSIONS:
        optimizeKey = 'cpm';
        break;
      default:
        optimizeKey = '';
    }
    return _.get(bidPriceData, `autoBidCap.${optimizeKey}`, 0);
  }

  getBidFloor (optimize: RtbOptimize): number {
    const bidPriceData = this.order.campaignBidPrice.find(data => data.type === this.campaignAdType);
    let optimizeKey = '';
    switch (optimize) {
      case RtbOptimize.CLICKS:
        optimizeKey = 'cpc';
        break;
      case RtbOptimize.IMPRESSIONS:
        optimizeKey = 'cpm';
        break;
      default:
        optimizeKey = '';
    }
    return _.get(bidPriceData, `bidFloor.${optimizeKey}`, 0);
  }

  getBidPriceRange (optimize: RtbOptimize): {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  } {
    return {
      max: this.getBidCap(optimize),
      min: this.getBidFloor(optimize)
    };
  }

  get currency (): string {
    const currency = _.get(this.order, 'currency', Currency.NTD);
    return currency;
  }

  abstract get canEditBudgetPlan (): boolean;
  abstract get canEditPriceModel (): boolean;

  setupDefaultCampaign (campaign: any) {
    const optimize = this.getDefaultOptimizeType(this.defaultPriceModel);
    campaign.priceModel = this.defaultPriceModel;
    campaign.optimize = optimize;
    campaign.frequency = undefined;
    const optimizeSameAsPriceModel = optimize ? this.rtbCampaignManager.checkOptimizeSameAsPriceModel(this.defaultPriceModel, optimize) : false;
    const onlyBidCap = optimizeSameAsPriceModel ||
      this.defaultPriceModel === RtbCampaignPlanType.RS_CPC ||
      this.defaultPriceModel === RtbCampaignPlanType.RS_CPM;
    campaign.bidStrategy = onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
    campaign.deliverType = this.defaultDeliverType;
  }

  changeDailyBudgetOptions (dailyBudgetType: DailyBudgetPlan): void {
    this.updateState(dailyBudgetType);
  }

  validate (campaignBasic: RtbCampaignBasic, order: Order) {
    const isDailySchedule = this.state.dailyBudgetType.toString() === DailyBudgetPlan.DAILY.toString();
    return this.validator.validate(this.l1Object, campaignBasic, order, isDailySchedule);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
  }

  updateState (dailyBudgetType: DailyBudgetPlan, loading: boolean = false): void {
    this.modelDailyBudgetType = dailyBudgetType;
    this.loading = loading;
    this.event.fireEvent(this);
  }

  get state (): RtbCampaignBasicFormState {
    return {
      dailyBudgetType: this.modelDailyBudgetType,
      loading: this.loading,
      modalData: this.modalData
    };
  }

  closeModal = () => {
    this.modalData = undefined;
    this.event.fireEvent(this);
  }

  setModalData = (modalData: ModalData) => {
    this.modalData = modalData;
    this.loading = false;
    this.event.fireEvent(this);
  }
}
