import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { FormikProps } from 'formik';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import _ from 'lodash';
import { CreativeFormBasicData, FormContentModel } from './FormContent/FormContentModel';
import { CreativeManager } from 'core/creative/CreativeManager';
import i18n from 'i18next';
import { CreativeType } from 'core/creative/Creative';
import styles from './creativeBasicForm.module.scss';
import { FormConfig } from 'components/form/FormConfig';

export interface CreativeBasicFormModel {
  readonly type: string;
  readonly initCreative: CreativeFormBasicData;
  readonly state: CreativeBasicFormState;
  readonly event: UpdateEventListener<CreativeBasicFormModel>;
  readonly enabledCreativeTypes: string[];
  readonly needLandingUrl: boolean;
  setFormikProps (props: FormikProps<any>): void;
  validate (): any;
  getCreativeBasicValue (): any;
  getContentModelByCreativeType (): FormContentModel | undefined;
  onCreativeTypeChange (newType): void;
  onUnmount (handler?: number): void;
  setHintModalData (data: {
    component: any,
    props: any
  }): void;
  getInitFormConfig (): FormConfig;
}

export type CreativeBasicFormProps = {
  readonly model: CreativeBasicFormModel;
};

export type CreativeBasicFormState = {
  readonly loading: boolean;
  readonly needLandingUrl: boolean;
  readonly hintModalData?: any;
  readonly basicFields?: any[];
};

export class DefaultCreativeBasicFormModel implements CreativeBasicFormModel {
  type: string;
  event: FireableUpdateEventListener<CreativeBasicFormModel>;
  loading: boolean;
  initCreative: CreativeFormBasicData;
  contentModel?: FormContentModel;
  creativeManager: CreativeManager;
  enabledCreativeTypes: string[];
  hintModalData?: any;
  urlMacroHintModalData?: any;
  basicFields: any = [];
  formikProps?: FormikProps<CreativeFormBasicData>;
  currentCreativeType: CreativeType;

  constructor (
    type: string,
    creative: CreativeFormBasicData,
    creativeManager: CreativeManager,
    enabledCreativeTypes: CreativeType[],
    private onTypeChange: (creativeType) => void,
    private formContentModelGetter: (creativeType: CreativeType) => FormContentModel
  ) {
    this.type = type;
    this.event = new FireableUpdateEventListener<CreativeBasicFormModel>();
    this.loading = false;
    this.initCreative = creative;
    this.contentModel = this.formContentModelGetter(creative.creativeType);
    this.creativeManager = creativeManager;
    this.enabledCreativeTypes = enabledCreativeTypes.map(key => CreativeType[key]);
    this.currentCreativeType = creative.creativeType;
    this.updateBasicFields();
  }

  get needLandingUrl () {
    return this.contentModel ? this.contentModel.needBannerUrl : true;
  }

  get state (): CreativeBasicFormState {
    return {
      loading: this.loading,
      needLandingUrl: this.needLandingUrl,
      hintModalData: this.hintModalData,
      basicFields: this.basicFields
    };
  }

  updateBasicFields () {
    const enableSelectCreativeTypeModelTypes = ['create', 'createAndBind'];
    this.basicFields = new FormConfig.FieldsBuilder()
      .addFormikSelect({
        label: i18n.t<string>('creativeSetupFlow.labels.creativeType'),
        name: 'creativeType',
        simpleValue: true,
        options: this.enabledCreativeTypes.map(type => {
          return {
            label: i18n.t<string>(`creativeType.${_.camelCase(type)}`),
            value: CreativeType[type]
          };
        }),
        onChange: this.onCreativeTypeChange,
        validate: validateEmpty
      }, !enableSelectCreativeTypeModelTypes.includes(this.type))
      .addFormikLabel({
        label: i18n.t<string>('creativeSetupFlow.labels.creativeType'),
        name: 'creativeType',
        formatter: value => i18n.t<string>(`creativeType.${_.camelCase(CreativeType[value])}`)
      }, enableSelectCreativeTypeModelTypes.includes(this.type))
      .addFormikInput({
        label: i18n.t<string>('creativeSetupFlow.labels.name'),
        name: 'name',
        validate: validateEmpty
      })
      .addFormikUrlInput({
        label: i18n.t<string>('creativeSetupFlow.labels.bannerUrl'),
        name: 'bannerUrl',
        className: styles.bannerUrl,
        validate: this.validateBannerUrl
      }, !this.needLandingUrl)
      .build();
  }

  getInitFormConfig = () => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          this.basicFields
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build();
  }

  getContentModelByCreativeType = () => {
    return this.contentModel;
  }

  setFormikProps (props: FormikProps<any>) {
    this.formikProps = props;
  }

  getCreativeBasicValue () {
    if (!this.formikProps) {
      return {};
    }
    return this.formikProps.values;
  }

  validateBannerUrl = (url) => {
    if (this.loading) {
      return i18n.t<string>('creativeSetupFlow.labels.analyzingHint');
    }

    const error = validateUrl(url);
    if (error) {
      return error;
    }

    const urlRegex = /^https?:\/\/(?:[a-zA-Z0-9]+\.)?momoshop\.com\.tw(?:\/.+)?$/;
    const url3pRegex = /^https?:\/\/(?:[a-zA-Z0-9]+\.)?momo\.dm(?:\/.+)?$/;
    if (!urlRegex.test(url) && !url3pRegex.test(url)) {
      return i18n.t<string>('creativeSetupFlow.labels.urlErrorHint');
    }
  }

  validate = async () => {
    if (!this.formikProps) {
      return {};
    }

    const errors = await this.formikProps.validateForm();
    return errors;
  }

  onCreativeTypeChange = (creativeType) => {
    this.contentModel = this.formContentModelGetter(creativeType);
    const nameValue = _.get(this.formikProps, 'values.name');
    let defaultCreativeName = nameValue ? nameValue : this.contentModel.defaultCreativeName;
    if (creativeType === CreativeType.PRODUCT_NATIVE) {
      defaultCreativeName = '';
    }
    this.formikProps && this.contentModel && this.formikProps.resetForm({
      values: ({
        ...this.formikProps.values,
        name: defaultCreativeName,
        creativeType,
        typeProperties: this.contentModel.getInitTypeProperties(),
        medias: {}
      }),
      errors: {}
    });
    this.urlMacroHintModalData = undefined;
    this.hintModalData = undefined;
    this.currentCreativeType = creativeType;
    this.updateBasicFields();
    this.onTypeChange(creativeType);
  }

  setHintModalData = (data?: any) => {
    this.hintModalData = data;
    if (data) {
      this.urlMacroHintModalData = undefined;
    }
    this.updateState(false);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    if (this.formikProps) {
      this.initCreative = this.formikProps?.values;
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
