import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Switch, Route } from 'react-router';
import styles from './index.module.scss';
import i18n from 'i18n';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { IntelligentWarningTop } from './IntelligentWarningTop';
import { IntelligentWarningTableWrapper } from './IntelligentWarningTableWrapper';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useCallAPI } from 'hooks/useCallAPI';
import {
  IntelligentWarningManager,
  DefaultIntelligentWarningManager
} from 'core/intelligentWarning/IntelligentWarningManager';
import { SmartReminder } from 'core/intelligentWarning/IntelligentWarning';
import { formatData } from './IntelligentWarningModel';

const defaultIntelligentWarningManager: IntelligentWarningManager =
  new DefaultIntelligentWarningManager();

export const IntelligentWarningWrapper: React.FC = () => {
  const [data, setData] = useState<SmartReminder>({});
  const { loading, callAPIs } = useCallAPI();

  const fetchData = useCallback(async () => {
    try {
      await callAPIs(
        [
          defaultIntelligentWarningManager.getSmartReminder.bind(
            defaultIntelligentWarningManager
          )
        ],
        (data) => {
          setData(data);
        }
      );
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }, [callAPIs]);

  const topData = useMemo(() => formatData(data), [data]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.container}>
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('intelligentWarning.labels.title')}
        </div>
        <IntelligentWarningTop data={topData} />
      </div>
      <IntelligentWarningTableWrapper data={data} />
      {loading && <LoadingIndicator />}
    </div>
  );
};

const IntelligentWarningView = withErrorBoundary(
  ERROR_CODE.HOME_PAGE,
  IntelligentWarningWrapper
);

export const IntelligentWarning: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route render={IntelligentWarningView} />
      </Switch>
    </div>
  );
};
