import React from 'react';
import { VariableSizeList as List } from 'react-window';
import ReactSelect, { createFilter, components } from 'react-select';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './select.module.scss';
import classnames from 'classnames/bind';
import { SelectOptions } from '../commonType';
import i18n from 'i18n';
import InfiniteLoader from 'react-window-infinite-loader';

type SelectProps = {
  labelKey: string;
  valueKey: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  options: SelectOptions[];
  simpleValue?: boolean;
  value?: any;
  name?: string;
  className?: any;
  onChange: any;
  isClearable?: boolean;
  placeholder?: string;
  defaultValue?: any;
  maxHeight?;
  getValue?;
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  optionComponent?: any;
  singleValue?: any;
  multiValueLabel?: any;
  multiValueContainer?: any;
  componentWidthFitParent?: boolean;
  menuPlacement?: string
  hideSelectedOptions?: boolean;
};

type SelectState = {
  totalOptions: SelectOptions[];
  optionsToShow: SelectOptions[];
};

const DropdownIndicator = (props) => {
  const { innerProps, selectProps } = props;
  const menuIsOpen = selectProps.menuIsOpen;
  const icon = menuIsOpen ? faCaretUp : faCaretDown;

  return (
      <div
        {...innerProps}
        className={styles.dropDown}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
  );
};

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};

const IndicatorSeparator = (props) => (<span/>);

const isOptionDisabled = (option) => option.disabled;

const sliceSize = 400;

class Select extends React.Component<SelectProps, SelectState> {
  cssClass: any;
  static defaultProps = {
    labelKey: 'label',
    valueKey: 'value',
    isMulti: false,
    isDisabled: false,
    isLoading: false,
    options: [],
    simpleValue: false,
    onChange: _.noop,
    isClearable: false,
    placeholder: 'common.placeholder.pleaseSelect',
    closeMenuOnSelect: true,
    defaultValue: []
  };

  searchString: string = '';

  constructor (props) {
    super(props);
    this.hanldeChange = this.hanldeChange.bind(this);
    this.cssClass = classnames.bind(styles);
    this.getMenuList = this.getMenuList.bind(this);
    this.state = {
      totalOptions: props.options,
      optionsToShow: props.options.length > sliceSize ?
        props.options.slice(0, sliceSize) :
        props.options
    };
  }

  static getDerivedStateFromProps (nextProps: Readonly<SelectProps>, prevState: Readonly<SelectState>): SelectState | null {
    if (nextProps.options !== prevState.totalOptions) {
      return {
        totalOptions: nextProps.options,
        optionsToShow: nextProps.options.length > sliceSize ?
          nextProps.options.slice(0, sliceSize) :
          nextProps.options
      };
    }
    return null;
  }

  shouldComponentUpdate (nextProps: Readonly<SelectProps>, nextState: Readonly<SelectState>): boolean {
    return nextProps.options !== this.props.options ||
      nextProps.value !== this.props.value ||
      nextProps.className !== this.props.className ||
      nextProps.onChange !== this.props.onChange ||
      nextProps.isDisabled !== this.props.isDisabled ||
      nextState.optionsToShow !== this.state.optionsToShow;
  }

  getSelectOptionValue (value) {
    const { simpleValue, isMulti, valueKey, options } = this.props;
    if (!simpleValue) {
      return value;
    }
    if (_.isNil(value)) {
      return isMulti ? [] : null;
    }
    const filterSimpleSingleValue = (option: SelectOptions[]) => {
      const allOptions = _.flatten(option.map(o => o.options ? o.options : [])).concat(option);
      return allOptions.find(o => o[valueKey] === value);
    };
    const filterSimpleMutipleValue = (option: SelectOptions[]) => {
      const allOptions = _.flatten(option.map(o => o.options ? o.options : [])).concat(option);
      return allOptions.filter(o => value.includes(o[valueKey]));
    };
    const filterSimpleValue = isMulti
      ? filterSimpleMutipleValue
      : filterSimpleSingleValue;
    return filterSimpleValue(options);
  }

  getValue () {
    const { value } = this.props;
    return _.defaultTo(this.getSelectOptionValue(value), '');
  }

  getDefaultValue () {
    const { defaultValue } = this.props;
    return _.defaultTo(this.getSelectOptionValue(defaultValue), '');
  }

  hanldeChange (optionValue) {
    const {
      simpleValue = false,
      isMulti,
      valueKey,
      onChange: parentHandleChange = _.noop
    } = this.props;
    if (simpleValue && optionValue) {
      const selectValue = isMulti
        ? optionValue.map(o => o[valueKey])
        : optionValue[valueKey];
      parentHandleChange(selectValue);
    } else {
      parentHandleChange(optionValue);
    }
  }

  isItemLoaded = (index) => {
    if (this.state.optionsToShow.length === this.state.totalOptions.length) {
      return true;
    }
    // trigger loadMoreItems when the item' index is close to the end of the list
    return this.state.optionsToShow.length - index > 20;
  }

  loadMoreItems = (_1, stopIndex) => {
    const totalOptions = this.state.totalOptions
      .filter(option => option.label.toLowerCase().includes(this.searchString.toLowerCase()));
    const end = Math.min(stopIndex + sliceSize, totalOptions.length);
    this.setState(prev => ({
      ...prev,
      optionsToShow: totalOptions.slice(0, end)
    }));
  }

  renderItem (style, value) {
    return <div style={style}>{value}</div>;
  }

  getMenuList ({ children, options, maxHeight, getValue, ...props }) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const getItemSize = index => {
      if (!ctx || !children[index]) {
        return 35;
      }
      const predictionWidth = ctx.measureText(children[index]['props']['label']).width;
      if (predictionWidth < 284) {
        return 35;
      }
      if (predictionWidth < 560) {
        return 55;
      }
      return 75;
    };

    if (!children.length) {
      // @ts-ignore
      return <components.NoOptionsMessage {...props} />;
    }

    const itemCount = this.state.optionsToShow.length;
    return (
      <InfiniteLoader
        isItemLoaded={this.isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={this.loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <List
            width={'100%'}
            height={maxHeight}
            itemCount={itemCount}
            itemSize={getItemSize}
            initialScrollOffset={0}
            onItemsRendered={onItemsRendered}
            ref={ref}
          >
            {({ index, style }) => this.renderItem(style, children[index])}
          </List>
        )}
      </InfiniteLoader>
    );
  }

  handleSearch = (searchString: string) => {
    this.searchString = searchString;
    this.loadMoreItems(0, this.state.optionsToShow.length);
  }

  render () {
    const {
      labelKey,
      valueKey,
      isMulti,
      isDisabled,
      isLoading,
      options,
      name,
      className: parentClass,
      isClearable,
      placeholder,
      closeMenuOnSelect,
      maxMenuHeight,
      optionComponent,
      singleValue,
      multiValueLabel,
      multiValueContainer,
      componentWidthFitParent,
      menuPlacement,
      hideSelectedOptions
    } = this.props;
    const value = this.getValue();
    const defaultValue = this.getDefaultValue();
    const getOptionLabel = (option: any) => option[labelKey];
    const getOptionValue = (option: any) => option[valueKey];
    const className = this.cssClass(parentClass, ['selectComponent'], {
      isMulti: isMulti,
      widthFitParent: componentWidthFitParent
    });
    let components = { DropdownIndicator, IndicatorSeparator, ClearIndicator };
    if (optionComponent) {
      // @ts-ignore
      components = { ...components, Option: optionComponent };
    }
    if (singleValue) {
      // @ts-ignore
      components = { ...components, SingleValue: singleValue };
    }
    if (multiValueContainer) {
      // @ts-ignore
      components = { ...components, MultiValueContainer: multiValueContainer };
    }
    if (multiValueLabel) {
      // @ts-ignore
      components = { ...components, MultiValueLabel: multiValueLabel };
    }
    const hasLargeData = options.length > sliceSize;
    if (hasLargeData) {
      // @ts-ignore
      components = { ...components, Option: optionComponent ? optionComponent : CustomOption, MenuList: this.getMenuList };
    }
    return (
      <ReactSelect
        menuPlacement={menuPlacement}
        inputId={name}
        components={components}
        className={className}
        value={value}
        defaultValue={defaultValue}
        name={name}
        options={this.state.optionsToShow}
        onChange={this.hanldeChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        isLoading={isLoading}
        classNamePrefix='react-select'
        isClearable={isClearable}
        filterOption={createFilter({ ignoreAccents: false })}
        placeholder={placeholder && i18n.t<string>(placeholder)}
        maxMenuHeight={maxMenuHeight}
        isOptionDisabled={isOptionDisabled}
        onInputChange={hasLargeData ? this.handleSearch : undefined}
        hideSelectedOptions={hideSelectedOptions === undefined ? true : hideSelectedOptions}
      />
    );
  }
}

class CustomOption extends React.Component<any> {

  render () {
    const { innerProps, isFocused, ...otherProps } = this.props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
    return (
        // @ts-ignore
      <components.Option {...newProps} className='react-select__option'>
        {this.props.children}
      </components.Option>
    );
  }
}

export const CheckboxOption = props => {
  const onChange = () => null;
  return (
    <components.Option {...props}>
      <input type='checkbox' checked={props.isSelected} onChange={onChange} />
      <label style={{ width: 'auto' }}>{props.label}</label>
    </components.Option>
  );
};

export default Select;
