import { AdType, DailyBudgetPlan, DeliverType, RtbCampaignPlanType, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { OPERATE } from 'enum/Operate';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { omitBy, isEmpty, defaultTo } from 'lodash';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';

export interface KeywordFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractKeywordFormModel extends DefaultRtbCampaignBasicFormModel implements KeywordFormModel {

  bidPriceData?: {max: number, min: number};

  constructor (mode: 'create' | 'edit', flowModel: RtbCampaignSetupFlowPageModel) {
    super(mode, flowModel);
    this.defaultDeliverType = DeliverType.ACCELERATED;
  }

  async init () {
    const limitationModel = this.flowModel.getLimitationModel();
    try {
      const productHotKeywords = await this.flowModel.adRequestSourceManager.getProductHotKeywords(defaultTo(this.flowModel.state.campaign.products, []).map(product => product.productId));
      limitationModel.setProductHotKeywords(productHotKeywords?.suggestKeywords);
      const bidCap = this.getBidCap(this.formikValue.optimize);
      const bidFloor = this.getBidFloor(this.formikValue.optimize);
      this.bidPriceData = {
        max: Math.max(Math.min(productHotKeywords.bidCap, bidCap), bidFloor),
        min: Math.max(Math.min(productHotKeywords.bidMedian, bidCap), bidFloor)
      };
    } catch (e) {
      console.error(e);
    }

  }

  get campaignAdType (): AdType {
    return AdType.KEYWORD;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [OPERATE.INCLUDE],
      notNeed: undefined,
      other: undefined
    };
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    const enableFCPC = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.FIXED_CPC);
    return omitBy({
      ...optionsMap,
      [L1ObjectObjective.TRAFFIC]: {
        [RtbCampaignPlanType.RS_CPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.FCPC]: enableFCPC ? [
          L2ObjectOptimizationGoal.CLICKS
        ] : undefined
      }
    }, isEmpty);
  }

  setupDefaultCampaign (campaign: any) {
    super.setupDefaultCampaign(campaign);
    campaign.dailyTargetBudget = 0;
  }

  getBidPriceRange (optimize: RtbOptimize): {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  } {
    return {
      max: this.getBidCap(optimize),
      min: this.getBidFloor(optimize),
      recommend: this.bidPriceData ? {
        min: this.bidPriceData.min,
        max: this.bidPriceData.max
      } : {
        min: 0,
        max: 0
      }
    };
  }
}

export class EditKeywordFormModel extends AbstractKeywordFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('edit', flowModel);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateKeywordFormModel extends AbstractKeywordFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('create', flowModel);
    this.modelDailyBudgetType = DailyBudgetPlan.DAILY;
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
