import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { MultiImageFormProps } from './ImageFormModel';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import styles from './imageForm.module.scss';
import { get, partial } from 'lodash';
import { useSelectProductField } from './useSelectProductField';

export const ImageFormMutliple: React.FunctionComponent<MultiImageFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    hintModalData,
    renderHintModal,
    setHintModalData,
    getInitFormConfig
  } = props;

  const {
    flowPageModel,
    products
  } = props.model;

  const primaryProductId = get(flowPageModel.initCreative.basic.typeProperties.primaryProduct, 'productId');
  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const {
    selectProductsFieldContainerClass,
    renderProductsField
  } = useSelectProductField(advertiserId, props.model, true, false);
  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        closeHint: partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    if (primaryProductId === undefined && products.length > 0) {
      setFieldValue('typeProperties.primaryProduct', products[0]);
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikMultiImageInput({
              label: i18n.t<string>('creativeSetupFlow.labels.images'),
              name: 'medias.images',
              maxWidth: 318,
              validTypes: props.model.validTypes,
              hints: [],
              permanentHint: (
                <div>
                  <span className={styles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.imageTypeHint')}
                  </span>
                  <span className={styles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })}
                  </span>
                  <div
                    className={styles.sizeInstructionBtn}
                    onClick={sizeInstructionModalData ?
                      closeSizeInstruction : showSizeInstruction
                    }
                  >
                    {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                    <FontAwesomeIcon icon={faInfoCircle}/>
                  </div>
                </div>
              ),
              validate: props.model.validateImages
            })
            .addFormikCustom({
              name: 'typeProperties.primaryProduct',
              label: i18n.t<string>('creativeSetupFlow.labels.primaryProduct'),
              render: renderProductsField,
              formGroupClassName: selectProductsFieldContainerClass
            }, primaryProductId !== undefined)
            .addFormikLabel({
              name: 'typeProperties.primaryProduct.name',
              label: i18n.t<string>('creativeSetupFlow.labels.primaryProduct')
            }, primaryProductId === undefined)
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      )
      .build()
    );
  }, [
    products,
    props.basicFields,
    props.model.validateImages,
    props.model.validTypes,
    sizeInstructionModalData,
    selectProductsFieldContainerClass,
    primaryProductId,
    renderProductsField,
    setFieldValue,
    renderHintModal,
    showSizeInstruction,
    closeSizeInstruction
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ImageFormMutliple);
