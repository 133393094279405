import { AuthenticationManager } from 'core';
import { NetworkState } from 'components/NetworkState';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { ServerError } from 'core/ServerError';

export type LoginPageState = NetworkState & {
  passwordVisibility: boolean;
  formError: {[name: string]: string};
  alertMessage?: string;
};

export interface LoginPageModel {

  readonly state: LoginPageState;
  readonly event: UpdateEventListener<LoginPageModel>;

  dismiss (): void;
  login (email: string, password: string): void;
  triggerPasswordVisibility (): void;
  setFormError (field: string, error?: string): void;
  setAlertMessage (message?: string): void;
}

export type LoginPageProps = {

  readonly model: LoginPageModel;
};

export class DefaultLoginPageModel implements LoginPageModel {
  // state
  loading: boolean;
  error: Error | null;
  passwordVisibility: boolean;

  manager: AuthenticationManager;
  modelEvent: FireableUpdateEventListener<LoginPageModel>;
  formError: {[name: string]: string};
  alertMessage?: string;

  constructor (manager: AuthenticationManager) {
    this.error = null;
    this.loading = false;
    this.manager = manager;
    this.modelEvent = new FireableUpdateEventListener<LoginPageModel>();
    this.passwordVisibility = false;
    this.formError = {};
  }

  get state (): LoginPageState {
    return {
      error: this.error,
      alertMessage: this.alertMessage,
      loading: this.loading,
      passwordVisibility: this.passwordVisibility,
      formError: this.formError
    };
  }

  get event (): UpdateEventListener<LoginPageModel> {
    return this.modelEvent;
  }

  dismiss () {
    this.error = null;
    this.modelEvent.fireEvent(this);
  }

  async login (email: string, password: string) {
    this.updateState(true);
    try {
      await this.manager.login(email, password);
      this.updateState(false);
    } catch (e) {
      if (e instanceof ServerError && e.code === 401) {
        this.updateState(false, new Error(e.serverMessage));
      } else {
        this.updateState(false, e as Error);
      }
    }
  }

  triggerPasswordVisibility = () => {
    this.passwordVisibility = !this.passwordVisibility;
    this.updateState(false);
  }

  setFormError (field: string, error?: string): void {
    if (this.formError[field] === error) {
      return;
    }
    if (!error) {
      delete this.formError[field];
    } else {
      this.formError = {
        ...this.formError,
        [field]: error
      };
    }
    this.updateState(false);
  }

  setAlertMessage (message?: string): void {
    this.alertMessage = message;
    this.modelEvent.fireEvent(this);
  }

  updateState (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.modelEvent.fireEvent(this);
  }
}
