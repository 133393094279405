import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import './IntelligentWarningTable.scss';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { almostDue, budgetNotEnough, bidPriceNotEnough, productCtrTooLow } from 'core/intelligentWarning/IntelligentWarning';
import { formatCTR } from './IntelligentWarningModel';

type IntelligentWarningTableProps = {
  type: string;
  data: almostDue[] | budgetNotEnough[] | bidPriceNotEnough[] | productCtrTooLow[];
};

export const IntelligentWarningTable: React.FC<IntelligentWarningTableProps> = ({ type, data }) => {
  // 定義表格的表頭
  const tableHeaders: Record<string, string[]> = {
    almostDue: ['campName', 'campaignId', 'startDay', 'endDay'],
    budgetNotEnough: ['campName', 'campaignId', 'budgetRemain', 'dailySpend', 'termRemain'],
    bidPriceNotEnough: ['campName', 'campaignId', 'cpc', 'suggestPrice'],
    productCtrTooLow: ['campName', 'campaignId', 'goodsCode', 'ctr', 'impression']
  };

  // 根據不同的類型取得對應的表頭
  const headers = tableHeaders[type] || [];

  // 判斷是否為商品相關的表頭
  const isGoodsHeader = (header: string) => ['goodsCode', 'ctr', 'impression'].includes(header);

  // 表格上方即將到期提示文案
  const reminder = {
    almostDue: 'activityExpiringReminder',
    budgetNotEnough: 'budgetNotEnoughReminder',
    bidPriceNotEnough: 'bidPriceNotEnoughReminder',
    productCtrTooLow: 'productCtrTooLowReminder'
  };

  return (
    <div className='intelligentWarningTable'>
      <p className='hint'>
        <Trans i18nKey={`intelligentWarning.hints.${reminder[type]}`}>
          <span />
        </Trans>
      </p>
      <Table hover>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>
                {i18n.t<string>(`intelligentWarning.labels.${header}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((activity, index) => (
              <React.Fragment key={index}>
                <tr>
                  {headers.map((header, headerIndex) => (
                    // 如果不是商品相關的表頭，則顯示活動的資訊
                    !isGoodsHeader(header) && (
                      <td key={headerIndex} rowSpan={activity.goods?.length || 1} style={header === 'campName' ? { width: '460px' } : undefined}>
                        {/* 如果是活動名稱，則顯示連結 */}
                        {header === 'campName' ? (
                          <Link to={`/orders/${activity.orderNumber}/campaign-groups/${activity.groupId}/campaigns/${activity.campaignId}/edit`}>
                            {activity.campName}
                          </Link>
                        ) : (
                          activity[header as keyof typeof activity]
                        )}
                      </td>
                    )
                  ))}
                  {activity.goods && activity.goods.length > 0 && (
                    <>
                      {/* 顯示第一個商品資訊 */}
                      <td>{activity.goods[0].goodsCode}</td>
                      <td>{formatCTR(activity.goods[0].ctr)}</td>
                      <td>{activity.goods[0].impression}</td>
                    </>
                  )}
                </tr>
                {/* 顯示剩下的商品資訊 */}
                {activity.goods && activity.goods.slice(1).map((good, goodIndex) => (
                  <tr key={goodIndex}>
                    <td>{good.goodsCode}</td>
                    <td>{formatCTR(good.ctr)}</td>
                    <td>{good.impression}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              {/* 沒資料時顯示無資料提示 */}
              <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                {i18n.t<string>('intelligentWarning.hints.noData')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
