import _ from 'lodash';
import client from './RestClient';
import {
  RtbCampaign,
  CheckPoint,
  RtbCampaignBasic,
  RTBCAMPAIGN_DEFAULT_AGE_MIN,
  RTBCAMPAIGN_DEFAULT_AGE_MAX,
  RtbOptimize,
  RtbCampaignPlanType,
  AdType
} from 'core/rtbCampaign/RtbCampaign';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import { SelectOptions } from 'components/commonType';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { AxiosInstance } from 'axios';
import { SmartCampaignConfig, SMART_CONFIG_PLAN_TYPE_MAP } from 'core/configuration/SmartCampaignConfig';

export type CreateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type UpdateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export interface RtbCampaignWebService {
  getCampaign (campaignId: number): Promise<RtbCampaign>;
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignBasic[]>;
  createCampaign (body: any, l1ObjectId?: number | string): Promise<number>;
  createPmpCampaign (body: any, l1ObjectId: number | string): Promise<number>;
  updateCampaign (body: any, l1ObjectId?: number | string): Promise<number>;
  splitCampaign (body: any, campaignId, l1ObjectId?: number | string): Promise<number>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string)
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>>;
  getCampaignName (campaignId: number): Promise<string | null>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getAdTypeOptions (): Promise<SelectOptions[]>;
  getRedirectInfo (campaignId: number | string): Promise<{
    orderNumber: string,
    l1ObjectId: string
  }>;
  getSmartCampaignConfigs (vendorNumber: string): Promise<SmartCampaignConfig[]>;
}

function wrapCheckPoint (json: any): CheckPoint {
  return {
    target: _.get(json, 'target'),
    time: _.get(json, 'time'),
    utime: _.get(json, 'utime')
  };
}

const optimizeMap = {
  [RtbOptimize.IMPRESSIONS]: L2ObjectOptimizationGoal.IMPRESSIONS,
  [RtbOptimize.CLICKS]: L2ObjectOptimizationGoal.CLICKS,
  CPM: L2ObjectOptimizationGoal.IMPRESSIONS,
  CPC: L2ObjectOptimizationGoal.CLICKS
};

function wrapBasic (json: any): RtbCampaignBasic {
  let l2ObjectOptimizationGoal = optimizeMap[json.optimize];
  const serverPriceModel = _.get(json, 'priceModel');
  let priceModel = serverPriceModel;
  if (serverPriceModel === RtbCampaignPlanType.RS) {
    if (l2ObjectOptimizationGoal === L2ObjectOptimizationGoal.CLICKS) {
      priceModel = RtbCampaignPlanType.RS_CPC;
    } else if (l2ObjectOptimizationGoal === L2ObjectOptimizationGoal.IMPRESSIONS) {
      priceModel = RtbCampaignPlanType.RS_CPM;
    }
  }
  return {
    id: _.get(json, 'campaignId'),
    name: _.get(json, 'name'),
    advertiserId: _.get(json, 'advertiserId'),
    orderId: _.get(json, 'orderId'),
    budget: _.get(json, 'budget', 0),
    startDate: _.get(json, 'startDate'),
    endDate: _.get(json, 'endDate'),
    createDate: _.get(json, 'createDate'),
    priceModel,
    orderPrice: _.get(json, 'orderPrice'),
    optimize: l2ObjectOptimizationGoal ? l2ObjectOptimizationGoal : L2ObjectOptimizationGoal.UNSPECIFIED,
    bidPrice: _.get(json, 'bidPrice'),
    creativeDeliverType: _.get(json, 'creativeDeliverType'),
    deliverType: _.get(json, 'deliverType'),
    state: _.get(json, 'state'),
    effectiveStatus: _.get(json, 'effectiveStatus'),
    spents: _.get(json, 'spents', _.get(json, 'spent', 0)),
    currencyRate: _.get(json, 'currencyRate'),
    olapActualSpent: _.get(json, 'olapActualSpent'),
    olapExpectSpent: _.get(json, 'olapExpectSpent'),
    expectedSpent: _.get(json, 'expectedSpent', 0),
    impres: _.get(json, 'impres', 0),
    clicks: _.get(json, 'clicks', 0),
    dailyTargetBudget: _.get(json, 'dailyTargetBudget'),
    frequency: _.get(json, 'frequency'),
    dayPart: _.get(json, 'dayPart'),
    checkpoints: _.get(json, 'checkpoints', []).map(json =>
      wrapCheckPoint(json)
    ),
    adType: _.get(json, 'adType'),
    additionalInfo: wrapAdditionalInfo(json),
    groupId: _.get(json, 'groupId'),
    report: _.get(json, 'report'),
    ageMin: _.get(json, 'ageMin'),
    ageMax: _.get(json, 'ageMax'),
    bindings: _.get(json, 'bindings')
  };
}

function wrapCreativeAmount (json: any) {
  return {
    bindingCount: _.get(json, 'bindingCount', 0),
    enableCount: _.get(json, 'enableCount', 0)
  };
}

function wrapAdditionalInfo (json: any) {
  const additionalInfo = _.get(json, 'additionalInfo', null);
  if (additionalInfo === null) {
    return undefined;
  }
  return {
    creativeAmount: wrapCreativeAmount(_.get(additionalInfo, 'creativeAmount')),
    limitations: wrapLimitationsFromServer(
      _.get(additionalInfo, 'limitations', []),
      json.dealIds,
      json.ageMin,
      json.ageMax
    ),
    pmp: _.get(additionalInfo, 'pmp')
  };
}

export function wrapCampaign (json: any): RtbCampaign {
  const basic = wrapBasic(_.get(json, 'campaign'));
  const isKeyword = [AdType.KEYWORD, AdType.SMART_KEYWORD].includes(basic.adType);
  return {
    basic,
    limitations: wrapLimitationsFromServer(
      _.get(json, 'limitations', []),
      _.get(json, 'campaign.dealIds'),
      isKeyword ? undefined : _.get(json, 'campaign.ageMin', RTBCAMPAIGN_DEFAULT_AGE_MIN),
      isKeyword ? undefined : _.get(json, 'campaign.ageMax', RTBCAMPAIGN_DEFAULT_AGE_MAX)
    )
  };
}

function wrapCampaignList (json: any): Array<RtbCampaignBasic> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapBasic(json)
  );
}

function wrapSmartCampaignConfigsFromServer (json: any): SmartCampaignConfig[] {
  return _.defaultTo(json, []).map((json: any) => {
    let optimize = optimizeMap[json.optimize];
    let configPlanType = json.planType;

    return {
      id: _.get(json, 'settingId'),
      name: _.get(json, 'settingFile'),
      updateTime: _.get(json, 'updateTime'),
      priority: _.get(json, 'priority'),
      vendorNumber: _.get(json, 'vendorNumber'),
      startTime: _.get(json, 'startTime'),
      endTime: _.get(json, 'endTime'),
      optimize: optimize ? optimize : L2ObjectOptimizationGoal.UNSPECIFIED,
      budget: _.get(json, 'budget'),
      dailyBudget: _.get(json, 'dailyBudget'),
      deliverType: _.get(json, 'deliverType'),
      planType: SMART_CONFIG_PLAN_TYPE_MAP[configPlanType][optimize],
      bidPrice: _.get(json, 'bidPrice'),
      adType: _.get(json, 'adType'),
      productIds: _.get(json, 'productId', []),
      searchKeywords: _.get(json, 'searchKeywords', []),
      campaignId: _.get(json, 'campaignId'),
      useTime: _.get(json, 'useTime')
    };
  });
}

export class RestfulCampaignWebService implements RtbCampaignWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}`);
    return wrapCampaign(response.data);
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignBasic[]> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/campaigns`,
      { params: { additional_info: 'creativeAmount,limitations,pmp' } });
    return wrapCampaignList(response.data.records);
  }

  async getCampaignName (campaignId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/name`);
    return response.data.result;
  }

  async createCampaign (body: any, l1ObjectId: number | string): Promise<number> {
    const isKeyword = [AdType.KEYWORD, AdType.SMART_KEYWORD].includes(body.campaign.adType);
    const campaignPath = isKeyword ? 'keyword-campaigns' : 'campaigns';
    let path = `/v2/l1-objects/${l1ObjectId}/${campaignPath}`;
    const response = await this.restClient.post(path, body);
    console.log('createCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async createPmpCampaign (body: any, l1ObjectId: number | string): Promise<number> {
    const response = await this.restClient.post(`/v2/l1-objects/${l1ObjectId}/pmp-campaigns`, body);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async updateCampaign (body: any, l1ObjectId: number | string): Promise<number> {
    const isKeyword = [AdType.KEYWORD, AdType.SMART_KEYWORD].includes(body.campaign.adType);
    const campaignPath = isKeyword ? 'keyword-campaigns' : 'campaigns';
    let path = `/v2/l1-objects/${l1ObjectId}/${campaignPath}/${_.get(body, 'campaign.campaignId')}`;
    const response = await this.restClient.put(path, body);
    console.log('updateCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async splitCampaign (body: any, campaignId, l1ObjectId: number | string): Promise<number> {
    const isKeyword = [AdType.KEYWORD, AdType.SMART_KEYWORD].includes(body.campaign.adType);
    const campaignPath = isKeyword ? 'keyword-campaigns' : 'campaigns';
    let path = `/v2/l1-objects/${l1ObjectId}/${campaignPath}/${campaignId}/split`;
    const response = await this.restClient.post(path, body);
    console.log('splitCampaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async deleteCampaigns (campaignIds: number[]): Promise<void> {
    await this.restClient.delete(`/v2/rtb-campaigns?campaignIds=${campaignIds.join(',')}`);
  }

  async updateCampaignState (campaignData: {
    l2ChannelId: (number | string)
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/rtb-campaigns/${state}`, campaignData);
  }

  async getCampaignOptions (from?: string, to?: string) {
    const path = '/v2/rtb-campaigns/options';
    const params = { from, to };
    const paramsString = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const response = await this.restClient.get(`${path}?${paramsString}`);
    return _.get(response, 'data.records', []);
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/orderNumber`);
    return response.data;
  }

  async getAdTypeOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/rtb-campaigns/ad-type/options');
    return _.get(response, 'data.records', []);
  }

  async getRedirectInfo (campaignId: number | string): Promise<{
    orderNumber: string,
    l1ObjectId: string
  }> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/redirect-info`);
    return response.data;
  }

  async getSmartCampaignConfigs (vendorNumber: string): Promise<SmartCampaignConfig[]> {
    const response = await this.restClient.get(`/v2/smart-campaigns-config?vendorNumber=${vendorNumber}`);
    return wrapSmartCampaignConfigsFromServer(response.data.records);
  }
}
