export enum CHANNEL {
  RTB = 'openRTB'
}

enum CAMPAIGN {
  BID_PRICE_CONSTRAINT = 'bidPriceConstraint',
  FIXED_CPC = 'fix_cpc',
  FIXED_CPM = 'fix_cpm',
  REVENUE_SHARING = 'revenueSharing',
  BUDGET_DOMINATE = 'budgetDominate',
  SMART_KEYWORD = 'smartKeyword'
}

export enum CREATIVES {
  IMAGE = 'option_1',
  PRODUCT_NATIVE = 'option_9',
  RICH_PRODUCT_NATIVE = 'option_12',
  SHOP = 'option_14'
}

enum COMPANY {
  AGENCY_ALLOW_ORDER_SETTLEMENT = 'allowOrderSettlement',
  USE_FIXED_EXCHANGE_RATE = 'fixedExchangeRate',
  ADVERTISER_VIEW_SPENT_IN_REPORT = 'advertiserViewSpentInReport',
  STORED_VALUE = 'storedValue',
  ADV_ADMIN_INFO_RESTRICTION = 'advAdminInfoRestriction'
}

enum LIMITATION {
  LIMITATION_SPACETYPE = 'spaceType',
  LIMITATION_DEVICE = 'device'
}

enum REPORT {
  REPORT_CAMPAIGN_DASHBOARD = 'campaignDashboard'
}

export type AddonFeatureType =
  | CHANNEL
  | CAMPAIGN
  | CREATIVES
  | COMPANY
  | LIMITATION
  | REPORT;

export const ADDONFEATURE = {
  CHANNEL,
  CAMPAIGN,
  CREATIVES,
  COMPANY,
  LIMITATION,
  REPORT
};
