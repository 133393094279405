import _ from 'lodash';

import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { OPERATE } from 'enum/Operate';
import { SelectComponent } from '../SelectComponent';
import { ItemType, defaultItemSetting } from '../SelectItemComponent';
import i18n from 'i18next';
import { SelectOptions } from 'components/commonType';
import { SearchKeywordsComponent } from '../SearchKeywordsComponent/SearchKeywordsComponent';
import { rtbInventories } from './rtbLimitationInventory';
import { LocaleMeta } from 'core';
import { getByteLength } from 'utils/StringUtil';

const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();

export const DEFAULT_INVENTORY = 'default';
export const operateTitles = {
  [OPERATE.INCLUDE]: {
    0: 'limitation.labels.incNoValue',
    1: 'limitation.labels.incHaveValue'
  },
  [OPERATE.EXCLUDE]: {
    0: 'limitation.labels.excNoValue',
    1: 'limitation.labels.excHaveValue'
  },
  [OPERATE.PREFERRED]: {
    0: 'limitation.labels.preferNoValue',
    1: 'limitation.labels.preferHaveValue'
  },
  [OPERATE.NONPREFERRED]: {
    0: 'limitation.labels.nonpreferNoValue',
    1: 'limitation.labels.nonpreferHaveValue'
  }
};

export enum LIMITATION_TYPE {
  CAMPAIGN = 'campaign',
  CREATIVE = 'creative'
}

export const defaultInventorySetting = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  limitationType: LIMITATION_TYPE,
  localeMeta?: LocaleMeta,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => _.compact([
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    ...rtbInventories.os,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.os.name],
    addonFeature: `${limitationType}_device`,
    cb: adRequestSourceManager.getOS.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent
  },
  {
    ...rtbInventories.device,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.device.name],
    addonFeature: `${limitationType}_device`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.device.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => adRequestSourceManager.getDevice(),
    supportOperates: ['include', 'exclude'],
    component: SelectComponent
  },
  _.isEmpty(_.get(localeMeta, 'limitSpaceType', {})) ? {
    ...rtbInventories.spaceType,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.spaceType.name],
    addonFeature: `${limitationType}_spaceType`,
    cb: adRequestSourceManager.getSpaceTypes.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.spaceType.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchSpaceType',
    component: SelectComponent
  } : undefined,
  {
    ...rtbInventories.momoSegment,
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    singleSelect: false,
    itemSetting: {
      type: ItemType.auto,
      categorySelectable: false,
      readonly: false
    },
    searchPlaceholder: 'limitation.placeholders.searchMomoSegments',
    supportOperates: ['include', 'exclude'],
    component: SelectComponent,
    cb: async () => {
      if (!localeMeta) {
        return [];
      }
      return adRequestSourceManager.getMomoSegments(localeMeta.vendorNumber);
    }
  }
]);

export const inventoryNameList = (limitationType: LIMITATION_TYPE) => {
  return defaultInventorySetting({}, limitationType).map(inventory => inventory.name);
};

const suggestedKeywordValidator = (_, value: SelectOptions[]) => {
  if (!value) {
    return;
  }
  let errors: string[] = [];
  let hasTooLongKeyword = false;
  value.forEach(option => {
    const tooLong = getByteLength(option.value.toString()) > 70;
    option.isError = tooLong;
    hasTooLongKeyword = hasTooLongKeyword || tooLong;
  });
  if (hasTooLongKeyword) {
    errors.push(i18n.t<string>('editLimitation.errors.someKeywordTooLong'));
  }
  if (value.length > 500) {
    errors.push(i18n.t<string>('editLimitation.errors.tooManyKeywords', { max: 500 }));
  }
  return errors.length > 0 ? errors : undefined;
};

export const keywordAdsInventorySetting = () => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    name: 'searchKeywords',
    requiredOperate: ['include'],
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    title: 'limitation.labels.searchKeyword',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SearchKeywordsComponent,
    supportOperates: ['include'],
    validator: suggestedKeywordValidator
  }
];
