import { SelectOptions } from 'components/commonType';
import {
  AdRequestSourceWebService,
  RestfulAdRequestSourceWebService
} from 'ws/AdRequestSourceWebService';
import { SmartGoodsHotKeywrods, GoodsHotKeywords } from 'core/product/Product';

export interface AdRequestSourceManager {
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getProductHotKeywords (goodsCodes: string[]): Promise<GoodsHotKeywords>;
  getSmartGoodsHotKeywords (goodsCodes: string[]): Promise<SmartGoodsHotKeywrods>;
  getMomoSegments (vendorNumber: string): Promise<SelectOptions[]>;
}

export class DefaultAdRequestSourceManager implements AdRequestSourceManager {
  webService: AdRequestSourceWebService;

  constructor (
    webService: AdRequestSourceWebService = new RestfulAdRequestSourceWebService()
  ) {
    this.webService = webService;
  }

  getOS (): Promise<SelectOptions[]> {
    return this.webService.getOS();
  }

  getSpaceTypes (): Promise<SelectOptions[]> {
    return this.webService.getSpaceTypes();
  }

  getDevice (): Promise<SelectOptions[]> {
    return this.webService.getDevice();
  }

  async getProductHotKeywords (goodsCodes: string[]): Promise<GoodsHotKeywords> {
    if (goodsCodes.length === 0) {
      return Promise.resolve({
        suggestKeywords: [],
        bidCap: 0,
        bidFloor: 0,
        bidPrice: 0,
        bidMedian: 0
      });
    }
    return this.webService.getProductHotKeywords(goodsCodes);
  }
  async getMomoSegments (vendorNumber: string): Promise<SelectOptions[]> {
    return this.webService.getMomoSegments(vendorNumber);
  }

  async getSmartGoodsHotKeywords (goodsCodes: string[]): Promise<SmartGoodsHotKeywrods> {
    if (goodsCodes.length === 0) {
      return Promise.resolve({
        suggestKeywords: [],
        bidCap: 0,
        bidFloor: 0,
        bidPrice: 0,
        bidMedian: 0
      });
    }

    return this.webService.getSmartGoodsHotKeywords(goodsCodes);
  }
}
