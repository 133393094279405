import {
  IntelligentWarningWebService,
  RestfulIntelligentWarningWebService
} from 'ws/IntelligentWarningWebService';

export interface IntelligentWarningManager {
  getSmartReminder (): Promise<object>;
}

export class DefaultIntelligentWarningManager implements IntelligentWarningManager {
  webService: IntelligentWarningWebService;

  constructor (webService: IntelligentWarningWebService = new RestfulIntelligentWarningWebService()) {
    this.webService = webService;
  }

  async getSmartReminder (): Promise<object> {
    return this.webService.getSmartReminder();
  }
}
