import { Actor, Account } from 'core';
import * as parser from 'utils/ActorRoleParser';
import { ColumnDefinition, sortableColumn, SortDescriptor } from 'components/TableColumn/TableColumn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface PermissionActionHandler {

  (actor: Actor): void;
}

export type PermissionAction = {

  readonly icon: IconProp;
  readonly tooltip?: string;
  readonly type?: string;
  readonly handler: PermissionActionHandler;
};

export interface AccountActorListModel {

  readonly actors: Array<Actor>;

  readonly nameColumn: ColumnDefinition;
  readonly permissionColumn: ColumnDefinition;
  readonly permissionActions: Array<PermissionAction>;
  readonly editBtnsColumn: ColumnDefinition;

  readonly agenciesTabTitle: string;
  readonly advertisersTabTitle: string;
  readonly noDataDescription: string;
  readonly defaultSorts: SortDescriptor;
}

export type AccountActorListProps = {

  readonly model: AccountActorListModel;
};

export class DefaultAccountActorListModel implements AccountActorListModel {

  constructor (
    private account: Account,
    private key: string,
    public permissionActions: Array<PermissionAction>
  ) {}

  get noDataDescription (): string {
    return 'accounts.noDataAvailable';
  }

  get defaultSorts (): SortDescriptor {
    return [{
      order: 'asc',
      dataField: 'displayOrder'
    }];
  }

  get agenciesTabTitle (): string {
    return 'accounts.tabs.agencies';
  }

  get advertisersTabTitle (): string {
    return 'accounts.tabs.advertisers';
  }

  get nameColumn (): ColumnDefinition {
    return {
      sort: false,
      text: 'accounts.headers.company',
      dataField: 'companyName',
      classes: () => 'company-name',
      headerClasses: () => 'company-name'
    };
  }

  get permissionColumn (): ColumnDefinition {
    return {
      sort: false,
      text: 'accounts.headers.permission',
      dataField: 'roleName'
    };
  }

  get editBtnsColumn (): ColumnDefinition {
    return sortableColumn('editBtn', '', false);
  }

  get actors (): Array<Actor> {
    return this.account.actors.filter(actor => {
      const type = parser.typeOf(actor);
      return type.toLowerCase() === this.key;
    });
  }
}
