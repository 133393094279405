import React from 'react';
import i18n from 'i18n';
import { Card } from 'react-bootstrap';
import './IntelligentWarningCard.scss';
import { Link } from 'react-router-dom';
import { CategoryItem } from 'core/intelligentWarning/IntelligentWarning';

export const IntelligentWarningCard: React.FC<CategoryItem> = (data) => {
  return (
    <>
      {data.items.length > 0 ? (
        <Card className='intelligentWarningCard'>
          <Card.Body>
            <Card.Title>
              {i18n.t<string>(`intelligentWarning.labels.${data.category}`)}
            </Card.Title>
            <Card.Text>
              {i18n.t<string>(`intelligentWarning.hints.${data.category}`)}
              <span className='act'>
                {data.items.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={item.link}>{item.name}</Link>
                      {index < data.items.length - 1 ? '、' : ''}
                    </React.Fragment>
                  );
                })}
              </span>
            </Card.Text>
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};
