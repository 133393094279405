import { CreativeBasic } from 'core/creative/Creative';
import { get } from 'lodash';

export const RichProductNativeCreativeDetail: React.FC<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = get(props.creative, 'creativeValue', get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }

  const width = get(creativeValue, 'images.0.w');
  const imageUrl = get(creativeValue, 'images.0.imageUrl');
  if (!imageUrl || !width) {
    return <div/>;
  }

  const adServingUrl = get(creativeValue, 'product.adServingUrl');
  if (!adServingUrl) {
    return <div/>;
  }

  const asiaMaxUrl = process.env.REACT_APP_ASIAMAX_URL ? process.env.REACT_APP_ASIAMAX_URL : 'https://beta-ssp.momo.tenmax.tw';
  const templateUrlMap = {
    2440: `${asiaMaxUrl}/docs/c90394dd9faf4ed9_template.html`,
    960: `${asiaMaxUrl}/docs/d27d4fd5107c45e4_template.html`
  };
  const iframeWidthMap = {
    2440: 1220,
    960: 375
  };
  const templateUrl = `${templateUrlMap[width]}?productMETA=${adServingUrl}&bannerImageLink=${imageUrl}`;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ width: 'min-content' }}>
        <iframe
          style={{ border: 'none' }}
          width={iframeWidthMap[width]}
          height={125}
          title='richProductNativeCreativeDetail'
          src={templateUrl}
        />
      </div>
    </div>
  );
};
