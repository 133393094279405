import { BasicFormProps } from './BasicFormProps';
import { CreativeFormData } from './FormContentModel';
import _ from 'lodash';
import { Creative } from 'core/creative/Creative';
import ProductNativeForm from './ProductNativeForm';
import { Product } from 'core/product/Product';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModelInterface';
import { ProductSelectFormModel } from './ProductSelectFormModel';

export type ProductNativeFormProps = {
  readonly model: ProductNativeFormModel;
} & BasicFormProps;

export class ProductNativeFormModel extends ProductSelectFormModel {

  public needBannerUrl: boolean = false;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    public products: Product[] = []
  ) {
    super(flowPageModel, products);
  }

  getInitTypeProperties (): any {
    return {
      products: this.products
    };
  }

  getFormContent = (): any => {
    return ProductNativeForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const typeProperties = {
      product: _.get(creativeBasic, 'product')
    };
    return {
      basic: {
        ...creativeBasic,
        typeProperties
      }
    };
  }
}
