import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './iconWithTooltip.module.scss';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

interface IconWithTooltipProps {
  disabled?: boolean;
  placement?: 'bottom' | 'top' | 'left' | 'right' | 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
  className?: string;
  icon: IconDefinition | IconProp | string;
  onClick?: () => void;
  tooltipProps?: {
    id: string | number,
    tooltip: React.ReactElement | string,
    link?: string
  };
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
  disabled,
  placement,
  className,
  icon,
  onClick,
  tooltipProps
}) => {
  const cx = classnames.bind(styles);
  const finalClassName = cx('iconWithTooltip', className, {
    disabled
  });
  const props = {
    className: finalClassName,
    icon,
    onClick: disabled ? undefined : onClick
  };
  const iconElement = typeof icon === 'string' ?
    <img
      className={styles.defaultLogo}
      src={icon}
      alt={'hint-icon'}
    /> :
    <FontAwesomeIcon {...props} icon={icon}/>;

  return tooltipProps ?
    <OverlayTrigger
      placement={placement}
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip id={tooltipProps.id + '-iconTooltip'}>
          {tooltipProps.tooltip}
        </Tooltip>
      }
    >
      {tooltipProps.link && !disabled ?
        <Link to={tooltipProps.link}>
          {iconElement}
        </Link> :
        iconElement
      }
    </OverlayTrigger> :
    iconElement;
};

IconWithTooltip.defaultProps = {
  disabled: false,
  placement: 'bottom'
};

export default IconWithTooltip;
