/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Dropdown.scss';
import i18n from 'i18n';

class Dropdowns extends React.PureComponent<any> {

  constructor (props) {
    super(props);
    this.state = {
      moveItemGroup : 'hide',
      toggleIcon : '',
      DropdownToggle : 'DropdownToggle'
    };
    this.openmoveItemGroup = this.openmoveItemGroup.bind(this);
    this.handleDeleteLimitation = this.handleDeleteLimitation.bind(this);
    this.handleChangeOperateToINCLUDE = this.handleChangeOperateToINCLUDE.bind(this);
    this.handleChangeOperateToEXCLUDE = this.handleChangeOperateToEXCLUDE.bind(this);
    this.handleChangeOperateToNONPREFERRED = this.handleChangeOperateToNONPREFERRED.bind(this);
  }

  openmoveItemGroup = () => {
    if (this.state['moveItemGroup'] === 'hide') {
      this.setState({
        moveItemGroup: '',
        toggleIcon : 'pointDown',
        DropdownToggle : 'DropdownToggleOn'
      });
    } else {
      this.setState({
        moveItemGroup: 'hide',
        toggleIcon : '',
        DropdownToggle : 'DropdownToggle'
      });
    }
  }

  handleDeleteLimitation = (event) => {
    event && event.stopPropagation();
    const {
      deleteLimitation,
      operate,
      type
    } = this.props;
    deleteLimitation(operate, type);
  }

  handleChangeOperateToINCLUDE = (event) => {
    event && event.stopPropagation();
    const {
      changeOperate,
      operate,
      type
    } = this.props;
    changeOperate(operate, type, 'include');
  }

  handleChangeOperateToEXCLUDE = (event) => {
    event && event.stopPropagation();
    const {
      changeOperate,
      operate,
      type
    } = this.props;
    changeOperate(operate, type, 'exclude');
  }

  handleChangeOperateToNONPREFERRED = (event) => {
    event && event.stopPropagation();
    const {
      changeOperate,
      operate,
      type
    } = this.props;
    changeOperate(operate, type, 'nonPreferred');
  }

  render () {
    const { operate, type, supportOperates } = this.props;
    return (
      <Dropdown>
        <Dropdown.Toggle variant='link' id={`${operate}-${type}-LimitationDropdown`} className='toggle'>
          <FontAwesomeIcon icon={faEllipsisV}/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={this.handleDeleteLimitation}
            className='dropdownItem'
          >
            {i18n.t<string>('common.buttons.delete')}
          </Dropdown.Item>
          {supportOperates && operate &&
            <>
              <div
                onClick={this.openmoveItemGroup}
                className={this.state['DropdownToggle']}
              >
                <Dropdown.Item disabled={true}>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={this.state['toggleIcon']}
                  />
                  {' '}
                  <span className='DropdownToggleText'>
                    {i18n.t<string>('limitation.move.to')}
                  </span>
                </Dropdown.Item>
              </div>
              <div className={this.state['moveItemGroup']}>
                {supportOperates.includes('include') && operate !== 'include'
                ?
                  <Dropdown.Item
                    className='moveItem dropdownItem'
                    onClick={this.handleChangeOperateToINCLUDE}
                  >
                    {i18n.t<string>('limitation.include')}
                  </Dropdown.Item>
                :
                  null
                }
                {supportOperates.includes('nonPreferred') && operate !== 'nonPreferred'
                ?
                  <Dropdown.Item
                    className='moveItem dropdownItem'
                    onClick={this.handleChangeOperateToNONPREFERRED}
                  >
                    {i18n.t<string>('limitation.nonPreferred')}
                  </Dropdown.Item>
                :
                  null
                }
                {supportOperates.includes('exclude') && operate !== 'exclude'
                ?
                  <Dropdown.Item
                    className='moveItem dropdownItem'
                    onClick={this.handleChangeOperateToEXCLUDE}
                  >
                    {i18n.t<string>('limitation.exclude')}
                  </Dropdown.Item>
                :
                  null
                }
              </div>
            </>
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default Dropdowns;
