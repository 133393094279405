import i18n from 'i18n';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { Button } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { AdType, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { SmartCampaignConfig } from 'core/configuration/SmartCampaignConfig';
import { SmartCampaignConfigListColumns, useSmartCampaignConfigListModel } from 'containers/SmartCampaignConfig/SmartCampaignConfigListModel';
import { Product } from 'core/product/Product';

export const SelectSmartConfigStep: React.FC<{
  advertiserId: number,
  goNext: (config: SmartCampaignConfig, campaign: {
    basic: Partial<RtbCampaignBasic>,
    limitations: any,
    products?: Product[]
  }) => void,
  goLast: () => void,
  configs?: SmartCampaignConfig[]
}> = ({
  advertiserId,
  goNext,
  goLast,
  configs
}) => {

  const {
    loading,
    finished,
    columns,
    filteredConfigs,
    selectedConfigs,
    selectedConfigProducts,
    onHandleSelect,
    onEnableSelect,
    onFetchSelectedConfigProducts
  } = useSmartCampaignConfigListModel(advertiserId, configs);

  const wrapCampaignAdditions = useCallback((config: SmartCampaignConfig) => {
    switch (config.adType) {
      case AdType.KEYWORD:
        return {
          limitations: {
            include: [{
              type: 'searchKeywords',
              value: config.searchKeywords.map(keyword => ({ label: keyword, value: keyword }))
            }]
          },
          products: !isEmpty(config.productIds) ? !isEmpty(selectedConfigProducts) ? selectedConfigProducts : [] : []
        };
      default:
        return {
          limitations: {},
          products: undefined
        };
    }
  }, [selectedConfigProducts]);

  const completeAndCheck = useCallback(async () => {
    onFetchSelectedConfigProducts();
  }, [onFetchSelectedConfigProducts]);

  const goMainStep = useCallback(() => {
    const config = filteredConfigs.find(config => config.id === selectedConfigs[0]);
    if (!config) return;

    const campaignBasic = {
      name: config.name,
      budget: config.budget,
      dailyTargetBudget: config.dailyBudget !== 0 ? config.dailyBudget : null,
      startDate: moment(config.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(config.endTime).format('YYYY-MM-DD HH:mm:ss'),
      adType: config.adType,
      priceModel: config.planType,
      optimize: config.optimize,
      bidPrice: config.bidPrice,
      deliverType: config.deliverType,
      smartCampaignConfigId: config.id
    };
    goNext(config, {
      basic: campaignBasic,
      ...wrapCampaignAdditions(config)
    });
  }, [goNext, filteredConfigs, selectedConfigs, wrapCampaignAdditions]);

  useEffect(() => {
    if (finished) {
      goMainStep();
    }
  }, [finished, goMainStep]);

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      {loading && <LoadingIndicator/>}
      <StickableBootstrapTable
        customPadding={false}
        keyField={SmartCampaignConfigListColumns.NAME}
        data={filteredConfigs}
        columns={columns}
        noDataIndication={i18n.t<string>('selectSmartConfigStep.placeholders.noData')}
        defaultSorted={[{
          dataField: SmartCampaignConfigListColumns.PRIORITY,
          order: 'asc'
        }]}
        selectedRows={selectedConfigs}
        onSelect={onHandleSelect}
        enableSelect={onEnableSelect}
        hidePagination={true}
      />
      <div style={{ padding: '30px' }}>
        <Button disabled={selectedConfigs.length === 0} style={{ marginRight: '20px' }} size='sm' onClick={completeAndCheck}>{i18n.t<string>('common.buttons.completeAndCheck')}</Button>
        <Button variant='secondary' size='sm' onClick={goLast}>{i18n.t<string>('common.buttons.back')}</Button>
      </div>
    </div>
  );
};
