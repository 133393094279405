import React from 'react';
import styles from './productList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { ColumnDescription } from 'react-bootstrap-table-next';

type SelectConfig = {
  productsOfCurrentConditionLength: number,
  selectedProductsOfCurrentCondition: string[],
  hasSelectedProductsOfOtherCondition: boolean,
  singleSelect: boolean
  limitSelect: boolean
};
const nameHeaderFormatter = (
  selectConfig: SelectConfig,
  onSelectAll: () => void,
  column: ColumnDescription,
  _1,
  { sortElement }
) => {
  const checked = selectConfig.productsOfCurrentConditionLength === selectConfig.selectedProductsOfCurrentCondition.length;
  return (
    <div className={styles.nameHeader}>
      <div className={styles.selectCheckBox}>
        {(selectConfig.singleSelect || selectConfig.limitSelect) ? undefined :
          <>
            <input
              type='checkbox'
              checked={checked}
              ref={element => element && (
                element.indeterminate = (selectConfig.hasSelectedProductsOfOtherCondition && selectConfig.selectedProductsOfCurrentCondition.length === 0) ||
                  (!checked && selectConfig.selectedProductsOfCurrentCondition.length > 0)
              )}
              onChange={onSelectAll}
              id={'inputAll'}
            />
            <label htmlFor='inputAll' />
          </>
        }
      </div>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const nameFormatter = (productId: any, product, _2, formatExtraData: any): any => {
  const { selectedProducts, onSelect } = formatExtraData;

  return (
    <div className={styles.nameCell}>
      <div className={styles.selectCheckBox}>
        <input
          type='checkbox'
          checked={selectedProducts.indexOf(productId.toString()) > -1}
          onChange={_.partial(onSelect, productId.toString())}
          id={`input${productId}`}
        />
        <label htmlFor={`input${productId}`} />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {product.name}
        </div>
        <div className={styles.id}>
          {'ID: ' + productId}
        </div>
      </div>
    </div>
  );
};

const imageFormatter = (imageData: any) => {
  if (!imageData) {
    return <div />;
  }

  return (
    <div className={styles.imgCell}>
      <div className={styles.imgPreview}>
        <img src={imageData} alt={'product img detail'} />
      </div>
    </div>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  imageFormatter
};
export default formatters;
