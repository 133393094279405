
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pmp, PmpFormType, PmpType } from 'core/pmp/Pmp';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import i18n from 'i18n';
import moment from 'moment';
import { SelectOptions } from 'components/commonType';
import { SummaryData } from 'components/SummaryDetail/SummaryDetail';
import { usePmpSummaryModel } from './PmpSummaryModel';
import { isEmpty, omit } from 'lodash';
import { useParams } from 'react-router-dom';

const pmpManager = new DefaultPmpManager();

export type PmpSetupFlowModel = {
  pmp?: PmpFormType,
  title: string,
  loading: boolean,
  summaryData: SummaryData[],
  redirectPath?: string,
  defaultPmp?: Pmp,
  setPmp: (pmp: PmpFormType) => void,
  handleSubmit: (pmp: PmpFormType) => void
};

export const useCreatePmpSetupFlowPageModel = (
  spaceOptions: SelectOptions[]
): PmpSetupFlowModel => {

  const pmpAgency = process.env.REACT_APP_PMP_AGENCY;
  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const initPmp = useMemo(() => ({
    name: '',
    adAgencyId: pmpAgency ? [+pmpAgency] : [],
    startTime: moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment().add(7, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    pmpType: PmpType.SPONSORSHIP,
    budget: 1,
    space: [],
    dayPart: {}
  }), [pmpAgency]);

  const [pmp, setPmp] = useState<PmpFormType | undefined>(initPmp);
  const summaryData = usePmpSummaryModel(
    pmp,
    spaceOptions
  );

  const handleSubmit = useCallback((pmp: PmpFormType) => {
    callAPIs([
      () => pmpManager.createPmp({
        ...pmp,
        dayPart: omit(pmp.dayPart, ['enabled'])
      })
    ], () => {
      setRedirectPath('/pmp');
    });
  }, [callAPIs]);

  return {
    pmp,
    title: i18n.t<string>('pmpSetupFlowPage.titles.create'),
    loading: loading,
    redirectPath,
    summaryData,
    setPmp,
    handleSubmit
  };
};

export const useEditPmpSetupFlowPageModel = (
  spaceOptions: SelectOptions[]
): PmpSetupFlowModel => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const { pmpId } = useParams<{ pmpId: string }>();
  const [pmp, setPmp] = useState<PmpFormType | undefined>();
  const [defaultPmp, setDefaultPmp] = useState<Pmp>();

  useEffect(() => {
    callAPIs([
      () => pmpManager.getPmp(+pmpId)
    ], pmp => {
      setPmp({
        ...pmp,
        space: pmp.space.filter(space => spaceOptions.some(option => option.value === space)),
        dayPart: isEmpty(pmp.dayPart) ? pmp.dayPart : {
          enabled: '1',
          ...pmp.dayPart
        }
      });
      setDefaultPmp(pmp);
    });
  }, [pmpId, spaceOptions, callAPIs]);

  const handleSubmit = useCallback((pmp: PmpFormType) => {
    if (!defaultPmp) return;
    callAPIs([
      () => pmpManager.updatePmp({
        ...defaultPmp,
        ...pmp,
        id: +pmpId
      })
    ], () => {
      setRedirectPath(`/pmp/${pmpId}`);
    });
  }, [pmpId, defaultPmp, callAPIs]);

  const summaryData = usePmpSummaryModel(
    pmp,
    spaceOptions
  );

  return {
    pmp,
    title: i18n.t<string>('pmpSetupFlowPage.titles.edit'),
    loading,
    summaryData,
    redirectPath,
    setPmp,
    handleSubmit
  };
};
