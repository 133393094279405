import React, { useContext, useRef } from 'react';
import styles from './productHome.module.scss';
import i18n from 'i18n';
import { useProductHomeModel } from './ProductHomeModel';
import { Formik, FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import { ProductFilter } from './ProductFilter';
import { FormikField } from 'components/form/field/FormikField';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { SearchBar } from 'components/SearchBar';
import { ProductList } from './ProductList';
import { Redirect } from 'react-router-dom';
import { get, partial } from 'lodash';
import { InputField } from 'components/form/field/InputField';
import { ProductListDataContext } from './ProductListDataContext';
import { CheckboxOption } from 'components/Select/Select';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

const MultiValueLabel = ({ selectProps, data }) => {
  const values = selectProps.value;
  if (values) {
    return values[values.length - 1].label === data.label
      ? data.label
      : data.label + ', ';
  }
  return '';
};

export type ProductHomeProps = {
  defaultFilters?: ProductFilter;
  enableCreateProductNativeCreative: boolean;
  title?: string;
  singleSelect?: boolean;
  limitSelect?: boolean;
};

export const ProductHome: React.FC<ProductHomeProps> = (props) => {

  const searchBarRef = useRef<any>(null);
  const {
    smartCampaignConfig
  } = useContext(ProductListDataContext);
  const {
    defaultFilters,
    enableCreateProductNativeCreative,
    title = i18n.t<string>('productHome.labels.title'),
    singleSelect,
    limitSelect
  } = props;

  const {
    loading,
    pathname,
    redirectData,
    filters,
    minPrice,
    maxPrice,
    pagination,
    tagOptions,
    allProducts,
    productsData,
    advertiserOptions,
    defaultOrderOptions,
    editActions,
    handleSearchString,
    handleMinPriceInput,
    handleMaxPriceInput,
    handleSubmit,
    validate,
    onProductListChange,
    category1Options,
    category2Options,
    category3Options,
    category4Options,
    handleCategory1Change,
    handleCategory2Change,
    handleCategory3Change,
    resetFilters
  } = useProductHomeModel(enableCreateProductNativeCreative, defaultFilters);

  const renderFilters = (formProps: FormikProps<ProductFilter>) => {
    const cannotSelectFilters = !get(filters, 'advertiser');
    const onProductOwnerChange = () => {
      resetFilters();
      searchBarRef.current && searchBarRef.current.clear();
    };
    return (
      <Form>
        <>
          <div className={styles.fieldContent}>
            {!get(defaultFilters, 'advertiser') &&
              <FormikField.Select
                label={i18n.t<string>('productHome.filters.labels.advertiser')}
                name='advertiser'
                options={advertiserOptions}
                fieldContentWidth={230}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
                className={styles.productHomeSelect}
                onChange={onProductOwnerChange}
              />
            }
            <InputField
              label={i18n.t<string>('productHome.filters.labels.priceMargin')}
              name='minPrice'
              fieldContentWidth={100}
              isFlexibleContent={true}
              value={minPrice ? minPrice.toString() : ''}
              onChange={handleMinPriceInput}
              className={styles.priceInput}
              type='number'
              disabled={cannotSelectFilters}
            />
            <div className={styles.tilde}>~</div>
            <InputField
              name='maxPrice'
              fieldContentWidth={100}
              isFlexibleContent={true}
              value={maxPrice ? maxPrice.toString() : ''}
              onChange={handleMaxPriceInput}
              className={styles.priceInput}
              type='number'
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              label={i18n.t<string>('productHome.filters.labels.defaultOrder')}
              name='defaultOrder'
              options={defaultOrderOptions}
              fieldContentWidth={230}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              className={styles.productHomeSelect}
              disabled={cannotSelectFilters}
            />
          </div>
          <div className={styles.fieldContent}>
            <FormikField.Select
              label={i18n.t<string>('productHome.filters.labels.category')}
              name='category1'
              options={category1Options}
              fieldContentWidth={230}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={handleCategory1Change}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category2'
              options={category2Options}
              fieldContentWidth={230}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={partial(handleCategory2Change, formProps.values.category1)}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category3'
              options={category3Options}
              fieldContentWidth={230}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={partial(handleCategory3Change, formProps.values.category1, formProps.values.category2)}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category4'
              options={category4Options}
              fieldContentWidth={230}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
          </div>
          <div className={styles.fieldContent}>
            <div className={styles.tagOptionsContainer}>
              <FormikField.Select
                label={i18n.t<string>('productHome.filters.labels.tags')}
                name='tags'
                options={tagOptions}
                fieldContentWidth={230}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                optionComponent={CheckboxOption}
                multiValueContainer={MultiValueLabel}
                simpleValue
                className={styles.productHomeSelect}
                disabled={cannotSelectFilters}
              />
              {filters.tags && filters.tags.length > 0 &&
                <div className={styles.tagsCount}>
                  {filters.tags.length}
                </div>
              }
              <IconWithTooltip
                icon={faQuestionCircle}
                placement='bottom-start'
                tooltipProps={{
                  id: 'tags-hint',
                  tooltip: i18n.t<string>('productHome.filters.hints.tags')
                }}
              />
            </div>
          </div>
          <div className={styles.fieldContent}>
            {pathname?.includes('campaigns/new') &&
            <p className='description'>說明:已為您預選熱門商品標籤，可再勾選其他推薦標籤，或取消勾選所有標籤以查看所有商品</p>
            }
          </div>
        </>
      </Form>
    );
  };

  return (
    <>
      {redirectData && <Redirect to={redirectData} />}
      {loading && <LoadingIndicator />}
      <div className={styles.container}>
        <div className={styles.titleArea}>
          <div className={styles.title}>
            {title}
            {smartCampaignConfig &&
              <div className={styles.capsule}>
                {i18n.t<string>('campaign.labels.smartConfigHint')}
              </div>
            }
          </div>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.sysAdmin, RoleNames.adsReport)}
          >
            <div className={styles.filtersFormContainer}>
              <Formik
                initialValues={filters}
                enableReinitialize
                onSubmit={handleSubmit}
                validate={validate}
                validateOnBlur={false}
              >
                {renderFilters}
              </Formik>
            </div>
          </PermissionChecker>
          <SearchBar
            ref={searchBarRef}
            disabled={filters.advertiser === undefined}
            model={{
              placeholder: i18n.t<string>('productHome.placeholders.searchbar'),
              search: handleSearchString,
              defaultValue: filters.search
            }}
          />
        </div>
        <ProductList
          singleSelect={singleSelect}
          limitSelect={limitSelect}
          filterSet={filters}
          productList={productsData}
          allProducts={allProducts}
          pagination={pagination}
          editActions={editActions()}
          onListChange={onProductListChange}
        />
      </div>
    </>
  );
};
