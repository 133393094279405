import { SelectOptions } from 'components/commonType';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useState } from 'react';

const pmpManager = new DefaultPmpManager();

export const useGetPmpOptions = () => {
  const pmpAgency = process.env.REACT_APP_PMP_AGENCY;
  const [spaceOptions, setSpaceOptions] = useState<SelectOptions[]>([]);
  const [activeSpaceOptions, setActiveSpaceOptions] = useState<SelectOptions[]>([]);

  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs([
      () => pmpManager.getPmpSpaces(),
      () => pmpManager.getPmpActiveSpaces()
    ], (spaceOptions, activeSpaceOptions) => {
      setSpaceOptions(spaceOptions);
      setActiveSpaceOptions(activeSpaceOptions);
    });
  }, [callAPIs]);

  return {
    loading,
    spaceOptions,
    agencyOptions: pmpAgency ? [{
      label: pmpAgency,
      value: +pmpAgency
    }] : [],
    activeSpaceOptions
  };
};
