import { useState, useEffect, useCallback, useMemo, useRef,useContext } from 'react';
import { SelectOptions } from 'components/commonType';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import _ from 'lodash';

import { Product, ProductTag, ProductWithPagination, Retail } from 'core/product/Product';
import { ProductFilter } from './ProductFilter';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';

import { useCallAPI } from 'hooks/useCallAPI';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { useHistory, useLocation } from 'react-router-dom';
import { useCoreContext } from 'contexts/coreContext';
import { Pagination } from 'core/pagination/Pagination';
import { ProductListDataContext } from 'containers/RetailMomo/Products/ProductListDataContext';

type EditAction = {
  label: string;
  onClick: (selectedProducts: string[]) => void | Promise<void>;
};

const defaultRdpManager: RdpManager = new DefaultRdpManager();
const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export const useProductHomeModel = (
  enableCreateProductNativeCreative: boolean,
  defaultFilters?: ProductFilter
) => {

  const allProductList = useRef<Product[]>([]);
  const {
    selectedProducts
  } = useContext(ProductListDataContext);
  const [categoryData, setCategoryData] = useState<object>({});
  const categories = useMemo(() => {
    if (_.isEmpty(categoryData)) {
      return {};
    }
    let result = {};
    Object.keys(categoryData).forEach(key => {
      const keys = key.split('>').map(_.trim);
      const resultPath = keys.reduce((acc, key) => `${acc}.${key}`, '');
      const vendorNumberList: string[] = categoryData[key];
      vendorNumberList.push('all');
      vendorNumberList.forEach(vendorNumber => {
        _.set(result, `${vendorNumber}${resultPath}`, {});
      });
    });
    return result;
  }, [categoryData]);
  const [filters, setFilters] = useState<ProductFilter>(_.defaultTo(defaultFilters, {}));
  const [category1Options, setCategory1Options] = useState<SelectOptions[]>([]);
  const [category2Options, setCategory2Options] = useState<SelectOptions[]>([]);
  const [category3Options, setCategory3Options] = useState<SelectOptions[]>([]);
  const [category4Options, setCategory4Options] = useState<SelectOptions[]>([]);
  const [productsData, setProductsData] = useState<Product[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 25,
    totalCount: 0
  });
  const tagOptions = useMemo(() => createSelectOptions([ProductTag.ROAS, ProductTag.TRENDING, ProductTag.HOT, ProductTag.HCP]), []);
  const [defaultSelectHCP,setDefaultSelectHCP] = useState<boolean>(false);
  const [advertiserOptions, setAdvertiserOptions] = useState<SelectOptions[]>([]);
  const [retailerTypeOptions, setRetailerTypeOptions] = useState<(SelectOptions & { name: string })[]>([]);
  const [cache, setCache] = useState<{[key: string]: any}>({});
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const [redirectData, setRedirectData] = useState<{
    pathname: string,
    state: any
  }>();

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const defaultOrderOptions = createSelectOptions([
    'unlimited',
    'salePriceDesc',
    'salePriceAsc'
  ], 'productHome.filters.options.');

  const setInitRetailer = useCallback((initRetailer, cache) => {
    const cacheKey = `${initRetailer[0].name}_${initRetailer[0].value}`;
    if (cache[cacheKey]) {
      return;
    }
    setFilters(filter => {
      const originRetailerId = _.get(filter, 'retailerId', '');
      const retailer = initRetailer.find(retailer => retailer.value.toString() === originRetailerId);
      return {
        ...filter,
        retailerId: retailer ? originRetailerId : initRetailer[0].value.toString()
      };
    });
    setCache({
      [cacheKey]: initRetailer
    });
  },[]);

  const history = useHistory();
  const location = useLocation();
  const pathname = _.get(location, 'pathname');
  const core = useCoreContext();
  const agencyId = _.get(core, 'accountManager.localeMeta.agencyId');
  const localeMeta = core?.accountManager.localeMeta;
  const vendorNumber = localeMeta ?
    localeMeta.selfServe ? 'all' : localeMeta.vendorNumber :
    undefined;
  const advertiser = filters.advertiser;

  const setInitAdvertiser = useCallback(() => {
    const advertiserId = _.get(core, 'authenticationManager.actor.advertiserId');
    if (_.isNil(advertiser) && !_.isNil(advertiserId)) {
      setFilters(filter => ({
        ...filter,
        advertiser: advertiserId
      }));
    }
  }, [core, advertiser]);

  const fetchCategories = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getCategories.bind(defaultRdpManager)],
      (categories: any) => {
        setCategoryData(categories);
      }
    );
  }, [callAPIs]);

  const fetchRetailerOptions = useCallback(async (advertiser: number) => {
    if (!agencyId) {
      return;
    }

    callAPIs(
      [defaultRdpManager.getRetailsOfAdvertiser.bind(defaultRdpManager, agencyId, advertiser)],
      (retailers: Retail[]) => {
        const newRetailerTypeOptions = [
          ...retailers.map(retailer => ({
            label: retailer.name,
            value: retailer.id,
            name: retailer.name
          }))
        ];
        setRetailerTypeOptions(newRetailerTypeOptions);
      }
    );
  }, [agencyId, callAPIs]);

  const fetchAdvertiserOptions = useCallback(async () => {
    callAPIs(
      [defaultAdvertiserManager.getAdvertiserOptions.bind(defaultAdvertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertiserOptions(advertisers);
      }
    );
  }, [callAPIs]);

  const initCategoryOptions = useCallback(() => {
    if (!vendorNumber) {
      return;
    }
    const categoriesOfVendor = _.defaultTo(categories[vendorNumber], {});
    setCategory1Options(createSelectOptions(Object.keys(categoriesOfVendor), 'productHome.filters.options.'));
  }, [categories, vendorNumber]);

  const handleCategory1Change = useCallback((category1: string) => {
    if (!vendorNumber) {
      return;
    }
    const categoriesOfVendor = _.defaultTo(categories[vendorNumber], {});
    if (categoriesOfVendor[category1] === undefined) {
      setCategory2Options([]);
    } else {
      setCategory2Options(createSelectOptions(Object.keys(categoriesOfVendor[category1]), 'productHome.filters.options.'));
    }
    setCategory3Options([]);
    setCategory4Options([]);
  }, [categories, vendorNumber]);

  const handleCategory2Change = useCallback((category1: string | undefined, category2: string) => {
    if (!vendorNumber) {
      return;
    }
    const categoriesOfVendor = _.defaultTo(categories[vendorNumber], {});
    if (category1 === undefined) {
      setCategory2Options([]);
    } else if (categoriesOfVendor[category1][category2] === undefined) {
      setCategory3Options([]);
    } else {
      setCategory3Options(createSelectOptions(Object.keys(categoriesOfVendor[category1][category2]), 'productHome.filters.options.'));
    }
    setCategory4Options([]);
  }, [categories, vendorNumber]);

  const handleCategory3Change = useCallback((category1: string | undefined, category2: string | undefined, category3: string) => {
    if (!vendorNumber) {
      return;
    }
    const categoriesOfVendor = _.defaultTo(categories[vendorNumber], {});
    if (category1 === undefined || category2 === undefined) {
      setCategory3Options([]);
    } else if (categoriesOfVendor[category1][category2][category3] === undefined) {
      setCategory4Options([]);
    } else {
      setCategory4Options(createSelectOptions(Object.keys(categoriesOfVendor[category1][category2][category3]), 'productHome.filters.options.'));
    }
  }, [categories, vendorNumber]);

  const resetFilters = useCallback(() => {
    setFilters(filter => ({
      ...filter,
      category1: '',
      category2: '',
      category3: '',
      category4: '',
      search: '',
      defaultOrder: '',
      minPrice: undefined,
      maxPrice: undefined
    }));
    setMinPrice(undefined);
    setMaxPrice(undefined);
  }, []);

  const handleSearchQueries: any = useCallback((filters: ProductFilter, categories: any) => {
    let category: string = '';
    if (vendorNumber) {
      const categoryOfVendor = _.defaultTo(categories[vendorNumber], undefined);
      const category1 = _.get(filters, 'category1', '');
      const category2 = _.get(filters, 'category2', '');
      const category3 = _.get(filters, 'category3', '');
      const category4 = _.get(filters, 'category4', '');
      if (!categoryOfVendor || categoryOfVendor[category1] === undefined) {
        category = '';
      } else if (categoryOfVendor[category1][category2] === undefined) {
        category = category1;
      } else if (categoryOfVendor[category1][category2][category3] === undefined) {
        category = `${category1} > ${category2}`;
      } else if (categoryOfVendor[category1][category2][category3][category4] === undefined) {
        category = `${category1} > ${category2} > ${category3}`;
      } else {
        category = _.compact([filters.category1, filters.category2, filters.category3, filters.category4]).join(' > ');
      }
    }
    const defaultOrder = _.get(filters, 'defaultOrder');
    let order;
    let sort;
    if (defaultOrder === 'salePriceDesc') {
      order = 'desc';
      sort = 'salePrice';
    } else if (defaultOrder === 'salePriceAsc') {
      order = 'asc';
      sort = 'salePrice';
    }

    return _.omitBy({
      customLabel2: filters.tags ? `|${filters.tags.join('|')}|` : undefined,
      search: filters.search,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      category: !_.isEmpty(category) ? category : null,
      order,
      sort
    }, _.isNil);
  }, [vendorNumber]);

  const fetchProductsData = useCallback(async (page: number | undefined = 1) => {
    if (!_.isEmpty(filters) && !_.isNil(filters.advertiser)) {
      const retailId = _.get(filters, 'retailerId', '');
      const queries = handleSearchQueries(filters, categories);
      if (_.isEmpty(retailId)) {
        return;
      }
      const pageable = { page, sizePerPage: 25 };
      callAPIs(
        [defaultRdpManager.getRetailProducts.bind(defaultRdpManager, retailId, pageable, queries)],
        (productWithPagination: ProductWithPagination) => {
          setPagination(productWithPagination.pagination);
          allProductList.current = _.uniqBy(allProductList.current.concat(productWithPagination.products), 'productId');
          setProductsData(productWithPagination.products);
        }
      );
    }
  }, [filters, callAPIs, categories, handleSearchQueries]);

  const onProductListChange = useCallback((type, props) => {
    if (type === 'sort') {
      return;
    }
    fetchProductsData(props.page);
  }, [fetchProductsData]);

  const editActions = (): EditAction[] | undefined => {

    const createProductNativeCreativeAction = {
      label: 'productList.labels.createProductNativeCreative',
      onClick: (selectedProducts: string[]) => {
        setRedirectData({
          pathname: '/creatives/new',
          state: {
            products: allProductList.current.filter(product => selectedProducts.includes(product.productId.toString())),
            filters: filters
          }
        });
      }
    };

    const editActions: EditAction[] = [];
    enableCreateProductNativeCreative && editActions.push(createProductNativeCreativeAction);
    return editActions;
  };

  const updateSearchFilter = useCallback((searchString) => {
    setFilters(filter => ({
      ...filter,
      search: searchString
    }));
  }, [setFilters]);

  const updateMinPriceFilter = useCallback((minPrice) => {
    setFilters(filter => ({
      ...filter,
      minPrice: minPrice
    }));
  }, [setFilters]);

  const updateMaxPriceFilter = useCallback((maxPrice) => {
    setFilters(filter => ({
      ...filter,
      maxPrice: maxPrice
    }));
  }, [setFilters]);

  const debouncedUpdateSearchFilter = useMemo(() => _.debounce(updateSearchFilter, 1000), [updateSearchFilter]);

  const handleSearchString = (searchString: string): void => {
    if (searchString === '') {
      debouncedUpdateSearchFilter && debouncedUpdateSearchFilter.cancel();
      updateSearchFilter('');
    } else {
      debouncedUpdateSearchFilter(searchString);
    }
  };

  const debouncedUpdateMinPriceFilter = useMemo(() => _.debounce(updateMinPriceFilter, 1000), [updateMinPriceFilter]);

  const handleMinPriceInput = (e): void => {
    const minPrice = e.target.value;
    setMinPrice(minPrice);
    if (!minPrice) {
      debouncedUpdateMinPriceFilter && debouncedUpdateMinPriceFilter.cancel();
      updateMinPriceFilter(undefined);
    } else {
      debouncedUpdateMinPriceFilter(minPrice);
    }
  };

  const debounceUpdateMaxPriceFilter = useMemo(() => _.debounce(updateMaxPriceFilter, 1000), [updateMaxPriceFilter]);

  const handleMaxPriceInput = (e): void => {
    const maxPrice = e.target.value;
    setMaxPrice(maxPrice);
    if (!maxPrice) {
      debounceUpdateMaxPriceFilter && debounceUpdateMaxPriceFilter.cancel();
      updateMaxPriceFilter(undefined);
    } else {
      debounceUpdateMaxPriceFilter(maxPrice);
    }
  };

  const handleSubmit = () => {
    // This is intentional
  };

  const validate = async (productFilter: ProductFilter) => {
    setFilters(productFilter);
  };

  useEffect(() => {
    fetchCategories();
    setInitAdvertiser();
  }, [fetchCategories, setInitAdvertiser]);

  useEffect(() => {
    // clear location state to prevent user refresh page and used cached filter
    if (pathname === '/products') {
      history.replace(pathname, undefined);
    }
  }, [history, pathname]);

  useEffect(() => {
    if (!_.isEmpty(retailerTypeOptions)) {
      setInitRetailer(retailerTypeOptions, cache);
    }
  }, [setInitRetailer, retailerTypeOptions, cache]);

  useEffect(() => {
    fetchAdvertiserOptions();
    initCategoryOptions();
  }, [fetchAdvertiserOptions, initCategoryOptions]);

  useEffect(() => {
    if (!_.isNil(advertiser)) {
      fetchRetailerOptions(advertiser);
    }
  }, [advertiser, fetchRetailerOptions]);

  useEffect(() => {
    if (!defaultSelectHCP) {
      setDefaultSelectHCP(true);
      if (pathname?.includes('campaigns/new') && selectedProducts.length === 0) {
        setFilters(prevFilters => ({
          ...prevFilters,
          tags: [...(prevFilters.tags || []), '熱門商品']
        }));
      }
    }

    if (defaultSelectHCP) {
      fetchProductsData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectHCP, fetchProductsData]);

  return {
    loading,
    pathname,
    filters,
    productsData,
    pagination,
    tagOptions,
    advertiserOptions,
    defaultOrderOptions,
    redirectData,
    minPrice,
    maxPrice,
    allProducts: allProductList.current,
    editActions,
    handleSearchQueries,
    handleSearchString,
    handleMinPriceInput,
    handleMaxPriceInput,
    handleSubmit,
    validate,
    onProductListChange,
    category1Options,
    category2Options,
    category3Options,
    category4Options,
    handleCategory1Change,
    handleCategory2Change,
    handleCategory3Change,
    resetFilters
  };
};
