import { CreativeManagementAction, CreativeManagementStateFactory, RtbCreativeManagementStateFactory } from './CampaignCreativeManagement/CreativeManagementStateFactory';
import { RtbCampaigns } from 'containers/RtbCampaigns';
import { DefaultRtbCampaignsModel } from 'containers/RtbCampaigns/RtbCampaignsModel';
import { CampaignList } from 'containers/RtbCampaigns/RtbCampaignList/CampaignList';
import { DefaultCampaignListModel } from 'containers/RtbCampaigns/RtbCampaignList/CampaignListModel';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { createCampaignBindInfo } from 'core/binding/CampaignBindInfo';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import _ from 'lodash';
import i18n from 'i18n';
import { SelectOptions } from 'components/commonType';
import { DefaultPmpManager, PmpManager } from 'core/pmp/PmpManager';
import { toast } from 'react-toastify';

export type L1ObjectComponentsData = {
  campaignsComponent: {
    component: any,
    props: any
  };
  listComponent: {
    component: any,
    props: any
  };
};

export interface L1ObjectChannelDetailModel {
  readonly l2ObjectList: any[];
  readonly componentsData?: L1ObjectComponentsData;
  initChannelModel: (defaultSearchString?: string | null) => Promise<void>;
  getCreativeManagementStateFactory: (
    campaignIds,
    actionParam,
    history
  ) => CreativeManagementStateFactory | undefined;
  getL1ObjectChannelViewData (): any;
}

class RTBCampaignGroupDetailModel implements L1ObjectChannelDetailModel {

  l2ObjectList: RtbCampaignBasic[] = [];
  inactivePmpSpaces: SelectOptions[] = [];
  componentsData?: L1ObjectComponentsData;

  constructor (
    protected order: Order,
    protected l1Object: L1Object,
    protected addonFeatureManager: AddonFeatureManager,
    protected refreshGroupDetail,
    protected localeMeta?: LocaleMeta,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    protected pmpManager: PmpManager = new DefaultPmpManager()
  ) {}

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.l2ObjectList = await this.campaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
      this.inactivePmpSpaces = await this.pmpManager.getPmpInactiveSpaces();
      const campaignListModel = new DefaultCampaignListModel(
        this.order,
        this.l1Object,
        this.l2ObjectList,
        this.addonFeatureManager,
        this.refreshGroupDetail,
        () => {
          // This is intentional
        },
        defaultSearchString ? defaultSearchString : '',
        this.l1Object.budgetBalance,
        this.inactivePmpSpaces
      );
      const campaignsModel = new DefaultRtbCampaignsModel(
        this.order,
        this.addonFeatureManager,
        this.l1Object
      );
      this.componentsData = {
        campaignsComponent: {
          component: RtbCampaigns,
          props: {
            model: campaignsModel
          }
        },
        listComponent: {
          component: CampaignList,
          props: {
            model: campaignListModel
          }
        }
      };
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  getCreativeManagementStateFactory (
    campaignIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.l2ObjectList.map(createCampaignBindInfo);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }

    return new RtbCreativeManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.l1Object
    );
  }

  getL1ObjectChannelViewData () {
    return _.compact([
      {
        label: i18n.t<string>('l1Object.labels.objectiveLabel'),
        value: this.l1Object.objective ? i18n.t<string>(`l1Object.labels.objective.${this.l1Object.objective.toLowerCase()}`) : ''
      }
    ]);
  }
}

export function getL1ObjectChannelDetailModel (
  order: Order,
  l1Object: L1Object,
  addonFeatureManager: AddonFeatureManager,
  refreshGroupDetail,
  localeMeta?: LocaleMeta
): L1ObjectChannelDetailModel | undefined {

  const needLocaleMetaArgs: [Order, L1Object, AddonFeatureManager, boolean, LocaleMeta | undefined] =
    [order, l1Object, addonFeatureManager, refreshGroupDetail, localeMeta];
  const channelModelClassGetterMap = {
    [L1ObjectChannel.RTB]: () => new RTBCampaignGroupDetailModel(...needLocaleMetaArgs)
  };
  const channelModelClassGetter = channelModelClassGetterMap[l1Object.channel];
  if (!channelModelClassGetter) {
    return undefined;
  }

  return channelModelClassGetter();
}

function getCampaignBindList (creativeBindData, campaignIds) {
  return creativeBindData ? creativeBindData.filter(campaign =>
    campaign.id && campaignIds.includes(campaign.id.toString())
  ) : [];
}
