import React from 'react';
import { Trans } from 'react-i18next';
import styles from './menuItemStyledTitle.module.scss';

export const keywordBidPriceItemTitle = () => {
  return (
    <Trans i18nKey='appMenus.system.items.keywordBidPrice'>
      ...<span className={styles.danger}>...</span>
    </Trans>
  );
};
