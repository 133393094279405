import { ProductHome } from 'containers/RetailMomo/Products/ProductHome';
import { ProductListDataContext } from 'containers/RetailMomo/Products/ProductListDataContext';
import { Fragment, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n';
import styles from './selectProductField.module.scss';
import { get, partial } from 'lodash';
import { SelectOptions } from 'components/commonType';
import Tags from 'components/Tags/Tags';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FormikField } from 'components/form/field/FormikField';
import { ProductSelectFormModel } from './ProductSelectFormModel';

export const useSelectProductField = (
  advertiserId: number,
  productSelectFormModel: ProductSelectFormModel,
  singleSelect: boolean,
  limitSelect: boolean
) => {

  const {
    products,
    filterSet,
    handleRemoveProduct,
    handleProductsButtonClick,
    handleSelectedProducts,
    onModalConfirm,
    onModalCancel,
    validateProducts,
    handleFilterChanged
  } = productSelectFormModel;

  const location = useLocation();
  const defaultFilters = useMemo(() => get(location.state, 'filters', {
    advertiser: advertiserId
  }), [advertiserId, location.state]);

  const productListModalData = useMemo(() => {
    const modalData = {
      render: () =>
        <ProductListDataContext.Provider
          value={{
            selectedProducts: products,
            smartCampaignConfig: undefined,
            handleSelectedProducts,
            handleFilterChanged
          }}
        >
          <ProductHome
            defaultFilters={filterSet || defaultFilters}
            enableCreateProductNativeCreative={false}
            singleSelect={singleSelect}
            limitSelect={limitSelect}
          />
        </ProductListDataContext.Provider>,
      props: {
        title: '',
        primaryButton: {
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onModalConfirm
        },
        secondaryButton: {
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onModalCancel
        },
        dismiss: onModalCancel,
        className: styles.productListModal
      }
    };
    return modalData;
  }, [
    filterSet,
    defaultFilters,
    products,
    singleSelect,
    limitSelect,
    handleSelectedProducts,
    handleFilterChanged,
    onModalConfirm,
    onModalCancel
  ]);

  const renderProductsField = useCallback(() => {
    const onModalOpen = partial(handleProductsButtonClick, productListModalData);
    const productsOptions: SelectOptions[] = products.map((product) => ({
      label: product.name,
      value: product.productId.toString()
    }));
    const renderFieldContent = () => (
      <Fragment>
        {products.length > 0 ?
          <div className={styles.tagsContainer}>
            <Tags
              value={productsOptions}
              disableInput
              onChange={handleRemoveProduct}
              className={styles.tags}
            />
            <div className={styles.editIcon}>
              <IconWithTooltip
                icon={faPencilAlt}
                tooltipProps={{
                  id: `select-product-field-edit-products-hint`,
                  tooltip: i18n.t<string>('creativeSetupFlow.labels.editProductsHint')
                }}
                onClick={onModalOpen}
              />
            </div>
          </div> :
          <div className={styles.selectProductsRow}>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={onModalOpen}
            >
              {i18n.t<string>('creativeSetupFlow.labels.selectProducts')}
            </button>
            <FormikField.Label
              isFlexibleContent={true}
              fieldContentWidth={20}
              inputColSm={3}
              name='selectProducts'
              validate={validateProducts}
            />
          </div>}
      </Fragment>
    );
    return (
      <Fragment>
        {!productSelectFormModel.flowPageModel.isModalOpen && renderFieldContent()}
      </Fragment>
    );
  }, [
    productSelectFormModel.flowPageModel.isModalOpen,
    products,
    productListModalData,
    handleProductsButtonClick,
    handleRemoveProduct,
    validateProducts
  ]);

  return {
    selectProductsFieldContainerClass: styles.productsFieldContainer,
    renderProductsField
  };
};
