import { BasicFormProps } from './BasicFormProps';
import { CreativeFormData } from './FormContentModel';
import _ from 'lodash';
import { Creative } from 'core/creative/Creative';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModelInterface';
import { ImageFormModel } from './ImageFormModel';
import { ProductNativeFormModel } from './ProductNativeFormModel';
import RichProductNativeForm from './RichProductNativeForm';

export type RichProductNativeFormProps = {
  readonly model: RichProductNativeFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export class RichProductNativeFormModel extends ProductNativeFormModel {

  public needBannerUrl: boolean = false;
  public imageFormModel: ImageFormModel;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {
    super(flowPageModel);
    this.imageFormModel = new ImageFormModel(flowPageModel, {
      960: [320],
      2440: [250]
    });
  }

  getInitTypeProperties (): any {
    return super.getInitTypeProperties();
  }

  getFormContent = (): any => {
    return RichProductNativeForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = _.get(creativeValue, 'images.0.imageUrl', creative.basic.bannerImageUrl);
    const width = _.get(creativeValue, 'images.0.w', 960);
    const height = _.get(creativeValue, 'images.0.h', 320);
    const medias = _.omitBy({
      image: _.omitBy({
        url: imageUrl,
        width,
        height
      }, _.isUndefined)
    }, _.isEmpty);
    const productNativeFormModelData = super.getFormModelData(creative);
    return {
      basic: {
        ...creativeBasic,
        typeProperties: productNativeFormModelData.basic.typeProperties,
        medias
      }
    };
  }
}
