import { Retail, Product, ProductWithPagination, ProductTag } from 'core/product/Product';
import client from './RestClient';
import _ from 'lodash';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';
import { AxiosInstance } from 'axios';

export interface RdpWebService {
  getRetailsOfAdvertiser (agencyId: number, advertiser: number): Promise<Retail[]>;
  getRetailProducts (retailId: number | string, pageable: Pageable, queries?: any): Promise<ProductWithPagination>;
  getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]>;
  getProductName (productId: string): Promise<string | null>;
  getAdServingUrl (productId: string): Promise<string>;
}

const wrapPagination = (json: any): Pagination => {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
};

const wrapProductFromServer: any = (json: any) => {
  const skus = _.get(json, 'skus[0]', {});

  const tags = [ProductTag.ROAS, ProductTag.TRENDING, ProductTag.HOT, ProductTag.HCP];
  return {
    productId: _.get(json, 'productId', 0),
    retailId: _.get(json, 'retailId', ''),
    name: _.get(skus, 'name', ''),
    category: _.get(skus, 'category', ''),
    salePrice: _.get(skus, 'salePrice', 0),
    imgLink: _.get(skus, 'imgLink', ''),
    multipack: _.get(skus, 'multipack', 0),
    vendorNumber: _.get(skus, 'customLabel0', ''),
    tags: _.get(skus, 'customLabel2', '').split('|').filter(tag => tag !== '').sort((a, b) => tags.indexOf(a) - tags.indexOf(b))
  };
};

export class RestfulRdpWebService implements RdpWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getRetailsOfAdvertiser (agencyId: number, advertiser: number): Promise<Retail[]> {
    const response = await this.restClient.get(`/rdp/retail/some?agencyId=${agencyId}&advertiserId=${advertiser}`);
    return response.data;
  }

  async getRetailProducts (retailId: number | string, pageable: Pageable, queries: any = {}): Promise<ProductWithPagination> {
    let { page, sizePerPage } = pageable;
    const response = await this.restClient.post(`/rdp/retail/${retailId}/products/search?page=${page}&size=${sizePerPage}`, queries);
    return {
      products: response.data.records.map(product => wrapProductFromServer(product)),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]> {
    const response = await this.restClient.post(`/rdp/products/cache?agencyId=${agencyId}&advertiserId=${advertiser}`, productIds);
    return response.data.records.map(product => wrapProductFromServer(product));
  }

  async getProductName (productId: string): Promise<string | null> {
    const response = await this.restClient.get(`/rdp/products/${productId}/name`);
    return response.data.result;
  }

  async getAdServingUrl (productId: string): Promise<string> {
    const response = await this.restClient.get(`/rdp/products/${productId}/ad-serving-url`);
    return response.data;
  }
}
