import React from 'react';
import i18n from 'i18n';
import { IntelligentWarningCard } from './IntelligentWarningCard';
import './IntelligentWarningTop.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { CategoryItem } from 'core/intelligentWarning/IntelligentWarning';

type IntelligentWarningTopProps = {
  data: CategoryItem[];
};

export const IntelligentWarningTop: React.FC<IntelligentWarningTopProps> = ({
  data
}) => {
  const hasData = data.some((item) => item.items.length > 0);

  return (
    <div className='intelligentWarningTop'>
      <h2>
        <FontAwesomeIcon icon={faLightbulb} />{' '}
        {i18n.t<string>(`intelligentWarning.labels.dailyReminder`)}
      </h2>
      <div className='wrapper'>
        {hasData ? (
          data.map((item) => (
            <IntelligentWarningCard key={item.category} {...item} />
          ))
        ) : (
          <p className='hint'>
            {i18n.t<string>('intelligentWarning.hints.noData')}
          </p>
        )}
      </div>
    </div>
  );
};
