import React from 'react';
import _ from 'lodash';
import { FormikContextType, connect } from 'formik';

import { DailyBudgetPlan, DeliverType } from 'core/rtbCampaign/RtbCampaign';
import RtbCampaignInfo from './RtbCampaignInfo';
import moment from 'moment';
import RtbCampaignOptimize from './RtbCampaignOptimize';
import { getFieldErrors } from 'utils/FormikUtils';
import { RtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Modal } from 'components/Modal/Modal';

export class RtbCampaignBasicForm extends React.Component<{ goLast: () => void, model: RtbCampaignBasicFormModel, formik: FormikContextType<any> }> {
  handler?: number;
  constructor (props) {
    super(props);
    this.handleChangeDailyBudgetPlan = this.handleChangeDailyBudgetPlan.bind(
      this
    );
    this.handleGetRemainBudget = this.handleGetRemainBudget.bind(this);
    this.handleGetCampaignTotalDay = this.handleGetCampaignTotalDay.bind(this);
    this.handleGetDailyBudgetState = this.handleGetDailyBudgetState.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init(this.props.goLast);
    }
  }

  componentDidMount () {
    let dailyTargetBudget = _.get(this.props, 'formik.values.dailyTargetBudget');
    if (dailyTargetBudget === null) {
      dailyTargetBudget = undefined;
    }
    // wait for formik didMount flag set to true
    setTimeout(() => {
      this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
    }, 0);

    this.handler = this.props.model.event.add(model => {
      this.setState(model.state, () => {
        const { dailyBudgetType } = this.props.model.state;
        if (dailyBudgetType === _.get(this.props, 'formik.values.dailyBudgetType')) {
          return;
        }
        let dailyTargetBudget: any = undefined;
        if (dailyBudgetType === DailyBudgetPlan.DAILY) {
          dailyTargetBudget = '0';
        }

        this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
      });
    });
    this.props.model.init(this.props.goLast);
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleChangeDayPartSwitch = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      this.props.formik.setFieldValue('dayPart', undefined);
    }
  }

  handleChangeDailyBudgetPlan = (newBudgetPlan) => {
    const originShowDeliverType = this.props.model.showDeliverType;
    this.props.model.changeDailyBudgetOptions(newBudgetPlan);
    const newShowDeliverType = this.props.model.showDeliverType;
    if (!newShowDeliverType) {
      const setFieldValue = _.get(this.props, 'formik.setFieldValue');
      setFieldValue && setFieldValue('deliverType', DeliverType.STANDARD);
    } else if (originShowDeliverType !== newShowDeliverType) {
      const setFieldValue = _.get(this.props, 'formik.setFieldValue');
      setFieldValue && setFieldValue('deliverType', this.props.model.defaultDeliverType);
    }
  }

  handleChangePriceModel = (model) => {
    this.props.model.setCurrentPriceModel(model);
    this.props.model.onPriceModelChangeCallback(model, this.props.formik.values);
  }

  handleGetRemainBudget () {
    const campaignBudget = _.get(this.props, 'formik.values.budget', '');
    return this.props.model.getRemainBudget(campaignBudget);
  }

  handleGetCampaignTotalDay () {
    const startDate = _.get(this.props, 'formik.values.startDate');
    const endDate = _.get(this.props, 'formik.values.endDate');

    return this.props.model.getCampaignTotalDay(startDate, endDate);
  }

  handleGetDailyBudgetState () {
    const dailyBudget = _.get(
      this.props,
      'formik.values.dailyTargetBudget'
    );
    const budget = _.get(this.props, 'formik.values.budget');
    const campaignTotalDay = this.handleGetCampaignTotalDay();
    return this.props.model.getDailyBudgetState(
      +budget,
      +dailyBudget,
      campaignTotalDay
    );
  }

  render () {
    const {
      order,
      actionType,
      l1Object,
      priceModelOptions,
      campaignDeliverTypeOptions,
      currency,
      minDate,
      maxDate,
      canEditPriceModel,
      canEditBudgetPlan,
      canUseDailyBudget,
      campaignBasic: defaultCampaign,
      showOptimizeSection,
      showDeliverType
    } = this.props.model;

    const { dailyBudgetType } = this.props.model.state;
    const formikValue = _.get(this.props, 'formik.values');

    this.props.model.setFormikValue(formikValue);
    const dayPartEnable = _.get(
      formikValue,
      'dayPart.enabled',
      0
    );
    const priceModel = _.get(formikValue, 'priceModel');
    const optimize = _.get(formikValue, 'optimize');
    const dailyBudget = _.get(
      formikValue,
      'dailyTargetBudget'
    );
    const budget = _.get(formikValue, 'budget');

    const startDate = _.get(formikValue, 'startDate');
    const endDate = _.get(formikValue, 'endDate');
    const isNewCampaign = _.get(formikValue, 'id') === undefined;
    const isCampaignStart = !isNewCampaign && moment(defaultCampaign.startDate).isBefore(moment());
    const dailyBudgetState = this.handleGetDailyBudgetState();
    const remainBudget = this.handleGetRemainBudget();
    const showBudgetHint = !getFieldErrors(this.props.formik, 'budget');
    const bidPriceRange = this.props.model.getBidPriceRange(optimize);
    const modalData = this.props.model.state.modalData;
    return (
      <div>
        {modalData &&
          <Modal
            title={modalData.title}
            primaryButton={modalData.primaryButtonData}
          >
            {modalData.message}
          </Modal>
        }
        {this.props.model.state.loading && <LoadingIndicator />}
        <RtbCampaignInfo
          formType={actionType}
          editL1ObjectPath={`/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/edit`}
          errors={this.props.formik.errors}
          orderName={`${order.projectName} (${order.orderNumber})`}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          isCampaignStart={isCampaignStart}
          canEditPriceModel={canEditPriceModel}
          priceModelOptions={priceModelOptions}
          campaignDeliverTypeOptions={campaignDeliverTypeOptions}
          currency={currency}
          priceModel={priceModel}
          canUseDailyBudget={canUseDailyBudget}
          canEditBudgetPlan={canEditBudgetPlan}
          dailyBudgetType={dailyBudgetType}
          onChangeDailyBudgetPlan={this.handleChangeDailyBudgetPlan}
          onChangePriceModel={this.handleChangePriceModel}
          dailyBudget={dailyBudget}
          budget={budget}
          dailyBudgetState={dailyBudgetState}
          remainBudget={remainBudget}
          showBudgetHint={showBudgetHint}
          showDeliverType={showDeliverType}
          bidPriceRange={bidPriceRange}
        />
        {showOptimizeSection &&
          <RtbCampaignOptimize
            formType={actionType}
            currentL2Object={formikValue}
            dayPartEnable={dayPartEnable}
            onDaypartSwitchChanged={this.handleChangeDayPartSwitch}
          />
        }
      </div>
    );
  }
}

export default connect<{goLast: () => void, model: RtbCampaignBasicFormModel}>(RtbCampaignBasicForm);
