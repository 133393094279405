import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';

export interface SmartConfigFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractSmartConfigFormModel extends DefaultRtbCampaignBasicFormModel implements SmartConfigFormModel {

  async init () {}

  get campaignAdType (): AdType {
    return AdType.SMART_CONFIG;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [],
      notNeed: undefined,
      other: undefined
    };
  }
}

export class EditSmartConfigFormModel extends AbstractSmartConfigFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('edit', flowModel);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateSmartConfigFormModel extends AbstractSmartConfigFormModel {
  constructor (flowModel: RtbCampaignSetupFlowPageModel) {
    super('create', flowModel);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}
