import { RestfulStoredValueWebService, StoredValueWebService } from 'ws/StoredValueWebService';
import { StoredValueAgencyRecordsWithPagination, StoredValueLog, StoredValueSummary } from './StoredValueLog';
import { defaultTo } from 'lodash';
import { getPriceValue } from 'helper/CurrencyHelper';

export interface StoredValueManager {
  getStoredValueAgencyRecords (page: number, sortField: string, sortOrder: string, search: string): Promise<StoredValueAgencyRecordsWithPagination>;
  getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]>;
  getStoredValueSummary (storedValueLogs: StoredValueLog[]): StoredValueSummary[];
  editStoredValue (agencyId: number, amount: number, description: string, comment?: string): Promise<void>;
  getRemainingStoredValue (agencyId: number): Promise<number | undefined>;
}

export class DefaultStoredValueManager implements StoredValueManager {
  webService: StoredValueWebService;

  constructor (webService: StoredValueWebService = new RestfulStoredValueWebService()) {
    this.webService = webService;
  }

  async getStoredValueAgencyRecords (page: number, sortField: string, sortOrder: string, search: string): Promise<StoredValueAgencyRecordsWithPagination> {
    return this.webService.getStoredValueAgencyRecords(page, sortField, sortOrder, search);
  }

  async getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]> {
    const logs = await this.webService.getStoredValueLogsByAgencyId(agencyId);
    this.addRemianValueToLogs(logs);
    return logs;
  }

  getStoredValueSummary (storedValueLogs: StoredValueLog[]): StoredValueSummary[] {
    const results = storedValueLogs.reduce<{[id: string]: StoredValueSummary}>((acc, log) => {
      const { adAgencyId, adAgencyName, vendorNumber, currency, amount, description } = log;
      let summaryOfAgency = defaultTo(acc[adAgencyId], {
        adAgencyId,
        adAgencyName,
        vendorNumber,
        currency,
        stored: 0,
        used: 0,
        remain: 0,
        logs: []
      });
      const storedCalcExcludeActions = ['結算訂單', '編輯訂單'];
      if (!storedCalcExcludeActions.some(action => description.includes(action)) && amount > 0) {
        summaryOfAgency.stored += amount;
      }
      summaryOfAgency.remain += amount;
      acc[adAgencyId] = summaryOfAgency;
      acc[adAgencyId].logs.push(log);
      return acc;
    }, {});
    Object.values(results).forEach(summary => summary.used = summary.stored - getPriceValue(summary.currency, summary.remain));
    return Object.values(results);
  }

  async editStoredValue (agencyId: number, amount: number, description: string, comment?: string): Promise<void> {
    return this.webService.editStoredValue(agencyId, amount, description, comment);
  }

  async getRemainingStoredValue (agencyId: number) {
    const storedValueLogs = await this.getStoredValueLogsByAgencyId(agencyId);
    if (storedValueLogs.length === 0) return undefined;
    const storedValueSummary = this.getStoredValueSummary(storedValueLogs);
    return storedValueSummary[0].remain;
  }

  private addRemianValueToLogs (logs: StoredValueLog[]) {
    const sortedLogs = logs.sort((a, b) => a.storedValueId - b.storedValueId);
    let remainValueMap = {};
    sortedLogs.forEach(log => {
      const remainValue = defaultTo(remainValueMap[log.adAgencyId], 0);
      log.remain = remainValue + log.amount;
      remainValueMap[log.adAgencyId] = log.remain;
    });
  }
}
