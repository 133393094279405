export enum CampaignListColumns {
  NAME = 'nameColumn',
  STATE = 'stateColumn',
  OPTIMIZE = 'optimize',
  DELIVERY = 'deliveryColumn',
  CREATIVE = 'creativeColumn',
  SCHEDULE = 'scheduleColumn',
  PROGRESS = 'progressColumn',
  BUDGET = 'budgetColumn',
  PRICE = 'priceColumn',
  LIMITATION = 'limitationColumn',
  IMPRES = 'impresColumn',
  CLICKS = 'clicksColumn',
  CPC = 'cpcColumn',
  CTR = 'ctrColumn',
  SPENT = 'spents',
  EDITBTNS = 'editBtnsColumn'
}

export const basicColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.STATE,
  CampaignListColumns.DELIVERY,
  CampaignListColumns.CREATIVE,
  CampaignListColumns.SCHEDULE,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.PRICE,
  CampaignListColumns.OPTIMIZE,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.EDITBTNS
];

export const performanceColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.IMPRES,
  CampaignListColumns.CLICKS,
  CampaignListColumns.CPC,
  CampaignListColumns.CTR,
  // CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.EDITBTNS
];
