import _ from 'lodash';

import client from './RestClient';
import { SelectOptions } from 'components/commonType';
import { AxiosInstance } from 'axios';
import { SmartGoodsHotKeywrods, GoodsHotKeywords } from 'core/product/Product';
export interface AdRequestSourceWebService {
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getProductHotKeywords (goodsCodes: string[]): Promise<GoodsHotKeywords>;
  getSmartGoodsHotKeywords (goodsCodes: string[]): Promise<SmartGoodsHotKeywrods>;
  getMomoSegments (vendorNumber: string): Promise<SelectOptions[]>;
}

export class RestfulAdRequestSourceWebService
  implements AdRequestSourceWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  pickRecords (response: any) {
    return _.sortBy(_.get(response, 'records', []), ['label']).map(record => ({
      ...record,
      options: record.options ? _.sortBy(record.options, ['label']) : record.options
    }));
  }
  async getOS (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/os'
    );
    return this.pickRecords(response.data);
  }
  async getSpaceTypes (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/space-types'
    );
    return this.pickRecords(response.data);
  }

  async getDevice (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/device-types`
    );
    return this.pickRecords(response.data);
  }

  async getProductHotKeywords (goodsCodes: string[]): Promise<GoodsHotKeywords> {
    const response = await this.restClient.post(
      '/momo/smartGoodsHotKeywords',
      goodsCodes
    );
    return {
      ...response.data,
      suggestKeywords: response.data.suggestKeywords.map((record: any) => ({
        suggestKeyword: record.suggestKeyword,
        pr: +(record.pr)
      }))
    };
  }

  async getSmartGoodsHotKeywords (goodsCodes: string[]): Promise<SmartGoodsHotKeywrods> {
    const response = await this.restClient.post(
      '/momo/smartGoodsHotKeywords',
      goodsCodes
    );
    return response.data;
  }

  async getMomoSegments (vendorNumber: string): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/momo-segments?vendorNumber=${vendorNumber}`
    );
    return this.pickRecords(response.data);
  }
}
