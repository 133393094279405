import { PMaxCore } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface RetailMomoModel {
  readonly core: PMaxCore;
  readonly state: RetailMomoState;
  readonly event: UpdateEventListener<RetailMomoModel>;
}

export type RetailMomoProps = {
  readonly model: RetailMomoModel;
  readonly mainPagePath: string;
};

export type RetailMomoState = {

};

export class DefaultRetailMomoModel implements RetailMomoModel {

  core: PMaxCore;
  event: FireableUpdateEventListener<RetailMomoModel>;

  constructor (
    core: PMaxCore
  ) {
    this.core = core;
    this.event = new FireableUpdateEventListener<RetailMomoModel>();
  }

  get state () {
    return {

    };
  }
}
