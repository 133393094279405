import React, { FunctionComponent, useMemo } from 'react';
import i18next from 'i18n';
import styles from './agencyForm.module.scss';
import { connect, FieldArray } from 'formik';
import _ from 'lodash';
import { FormikField } from 'components/form/field/FormikField';
import { BidPriceSetting } from './BidPrice/BidPriceSetting';
import { CREATIVES } from 'core/agency/AddonFeature';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { CustomField } from 'components/form/field/CustomField';

const showcasingTypes = [AdType.DISPLAY, AdType.KEYWORD, AdType.SMART_KEYWORD];

const creativeAdTypesMapping = Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((mapping, adType) => {
  const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
  _.forEach(allowCreativeTypes, (creativeType) => {
    const creative = `option_${creativeType}`;
    mapping[creative] = mapping[creative] ? [
      ...mapping[creative],
      adType
    ] : [adType];
  });
  return mapping;
}, {} as {[key in CREATIVES]: string[]});

const AgencyAddonForm: FunctionComponent<any> = (props) => {
  const language = i18next.language;
  const creativeCampaignTypeInfoMap = useMemo(() => Object.keys(creativeAdTypesMapping).reduce((acc, creative) => {
    const adTypes = creativeAdTypesMapping[creative].filter(adType => showcasingTypes.includes(adType));
    const campaignTypes = adTypes.map(adType => i18next.t<string>(`agency.addonItem.creatives.adType_${adType.toLowerCase()}`));
    return {
      ...acc,
      [creative]: (
        <div key={language} className={styles.campaignTypesInfo}>
          {campaignTypes.join(', ')}
        </div>
      )
    };
  }, {} as {[key in CREATIVES]: any}), [language]);

  const renderBidPriceFieldArray = () => (
    <FieldArray
      name='addonProps.campaignBidPrice'
      render={renderBidPriceSettings}
    />
  );

  const renderBidPriceSettings = ({ form, name }) => {
    const currency = _.get(form.values, 'currency');
    const bidPriceSettings = _.get(form.values, name);
    const priceSettings = bidPriceSettings.map((bidPriceSetting, index) => {
      const type = bidPriceSetting.type;
      const defaultSetting = defaultCampaignBidPrice.find(setting => setting.type === type);
      if (!defaultSetting) {
        return <div key={type}/>;
      }
      const optimizes = Object.keys(defaultSetting.autoBidCap);
      return (
        <BidPriceSetting
          key={type}
          fieldName={`${name}.${index}`}
          type={type}
          currency={currency}
          optimizes={optimizes}
        />
      );
    });
    return (
      <div className={styles.bidPriceSettings}>
        {priceSettings}
      </div>
    );
  };

  const {
    addonFeatures
  } = props.formik.values;

  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.channel')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.openRTB')}
            name='addonFeatures.channel.openRTB'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.company')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.allowOrderSettlement')}
            name='addonFeatures.company.allowOrderSettlement'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.fixedExchangeRate')}
            name='addonFeatures.company.fixedExchangeRate'
          />
          <FormikField.Input
            label={i18next.t<string>('agency.addonItem.company.ExchangeRate')}
            name='addonProps.fixedExchangeRate'
            inputColSm={4}
            fieldContentWidth='auto'
            disabled={!_.get(addonFeatures, 'company.fixedExchangeRate')}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.storedValue')}
            name='addonFeatures.company.storedValue'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.advAdminInfoRestriction')}
            name='addonFeatures.company.advAdminInfoRestriction'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.campaign')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.smartKeyword')}
            name='addonFeatures.campaign.smartKeyword'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_cpc')}
            name='addonFeatures.campaign.fix_cpc'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_cpm')}
            name='addonFeatures.campaign.fix_cpm'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.revenueSharing')}
            name='addonFeatures.campaign.revenueSharing'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.budgetDominate')}
            name='addonFeatures.campaign.budgetDominate'
          />
          <CustomField
            label={i18next.t<string>('agency.addonItem.campaign.bidPriceConstraint')}
            name='campaignBidPrice'
            render={renderBidPriceFieldArray}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.creatives')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_image')}
            name='addonFeatures.creatives.option_1'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.IMAGE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_productNative')}
            name='addonFeatures.creatives.option_9'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.PRODUCT_NATIVE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_richProductNative')}
            name='addonFeatures.creatives.option_12'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.RICH_PRODUCT_NATIVE]}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.campaignLimitation')} </span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.spaceType')}
            name='addonFeatures.campaignLimitation.spaceType'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.device')}
            name='addonFeatures.campaignLimitation.device'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.report')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.campaignDashboard')}
            name='addonFeatures.report.campaignDashboard'
          />
        </div>
      </fieldset>
    </div>
  );
};

export default connect(AgencyAddonForm);
