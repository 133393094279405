import { LoadingIndicator } from 'components/LoadingIndicator';
import { LoginBackground } from 'containers/LoginBackground/LoginBackground';
import { useCallAPI } from 'hooks/useCallAPI';
import React, { useRef, useState } from 'react';
import loginLogo from 'assets/logo.png';
import loginLogo2x from 'assets/logo@2x.png';
import i18n from 'i18n';
import { AuthenticationManager } from 'core';
import classnames from 'classnames/bind';
import { validateEmail } from 'utils/ValidateUtils';
import styles from './forgotPasswordPage.module.scss';
import { Redirect } from 'react-router-dom';

const cx = classnames.bind(styles);

export const ForgotPasswordPage: React.FC<{
  authenticationManager: AuthenticationManager
}> = ({
  authenticationManager
}) => {

  const emailInputRef = useRef<any>(null);
  const { loading, callAPIs } = useCallAPI();
  const [ emailError, setEmailError ] = useState<string | undefined>(undefined);
  const [ showSentMessage, setShowSentMessage ] = useState<boolean>(false);
  const [ redirectPath, setRedirectPath ] = useState<string | undefined>();

  const sendResetPasswordMail = (event) => {
    event.preventDefault();
    const email = emailInputRef.current.value;
    callAPIs([authenticationManager.sendResetPasswordMail.bind(authenticationManager, email)], () => {
      setShowSentMessage(true);
    });
  };

  const toLoginPage = () => {
    authenticationManager.logout();
    setRedirectPath('/');
  };

  const onEmailChange = (event) => {
    const email = event.target.value;
    const error = validateEmail(email);
    setEmailError(error);
  };

  const emailInputGroupClass = cx(styles.inputGroup, {
    withError: !!emailError
  });

  return (
    <LoginBackground>
      <div className={styles.emailForm}>
        {loading && <LoadingIndicator/>}
        {redirectPath && <Redirect to={redirectPath} />}
        <form onSubmit={sendResetPasswordMail}>
          <div className={styles.iconGroup}>
            <div>
              <img
                className={styles.formTitle}
                src={loginLogo}
                srcSet={loginLogo2x}
                alt='tenmax icon'
              />
            </div>
            <div className={styles.subtitle}>momo Advertising Network</div>
            <div className={styles.subtitle}>Agency Portal</div>
          </div>
          {showSentMessage ?
            <>
              <div className={styles.sentMailTitle}>
                {i18n.t<string>('forgotPasswordPage.labels.sentMailTitle')}
              </div>
              <div className={styles.sentMailMessage}>
                {i18n.t<string>('forgotPasswordPage.labels.sentMailMessage')}
              </div>
            </> :
            <>
              <div className={styles.hints}>
                {i18n.t<string>('forgotPasswordPage.labels.hints')}
              </div>
              <div className={emailInputGroupClass}>
                <label>{i18n.t<string>('loginPage.labels.emailTitle')}</label>
                <input
                  type='email'
                  ref={emailInputRef}
                  placeholder={i18n.t<string>('login.form.placeholders.email')}
                  onChange={onEmailChange}
                />
                {emailError && <div className={styles.inputError}>{emailError}</div>}
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={emailError !== undefined}
              >
                {i18n.t<string>('common.buttons.confirm')}
              </button>
            </>
          }
          <div className={styles.underscoreText} onClick={toLoginPage}>
            {i18n.t<string>('forgotPasswordPage.labels.backToLogin')}
          </div>
        </form>
      </div>
    </LoginBackground>
  );
};
